import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelpers } from '../../../forms/classes/form-helpers.class';
import { Subject } from 'rxjs';
import { Revision } from '../../models/revision.model';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { RevisionsService } from '../../services/revisions.service';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-revisions-update-modal',
  templateUrl: './revisions-update-modal.component.html',
  styleUrls: ['./revisions-update-modal.component.css'],
})
export class RevisionsUpdateModalComponent implements OnInit {
  public revision: Revision;
  public revisionFormGroup: FormGroup;
  public postingRevision = false;
  public dateMask = FormHelpers.dateMask;
  public onModalHide: Subject<boolean> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private snotifyService: SnotifyService,
    private revisionsService: RevisionsService,
  ) { }

  ngOnInit() {
    this.initRevisionForm();
  }

  initRevisionForm() {
    this.revisionFormGroup = this.formBuilder.group({
      id: [this.revision.id, [Validators.required]],
      form_id: [this.revision.form_id, [Validators.required]],
      number: [this.revision.number, [Validators.required]],
      date: [this.revision.date, [Validators.required]],
      publisher: [this.revision.publisher, [Validators.required]],
      reason: [this.revision.reason, [Validators.required]],
    });

    this.revisionFormGroup.get('number').disable();
    this.revisionFormGroup.get('date').disable();
  }

  onSubmit() {
    this.postingRevision = true;
    const payload = this.revisionFormGroup.getRawValue();
    payload.date = FormHelpers.convertAPIDate(payload.date);

    this.revisionsService.updateRevision(payload).subscribe(
      () => {
        this.postingRevision = false;
        this.snotifyService.success('A new revision has been created.', 'Success!');
        this.bsModalRef.hide();
        this.onModalHide.next();
        AppHelper.chime();
      },
      () => {
        this.postingRevision = false;
        this.snotifyService.error('Unable to create revision. Please try again.', 'Error!');
      }
    );
  }

}
