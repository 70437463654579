import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../auth/models/user.model';
import { Role } from '../../../roles/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { UsersService } from '../../services/users.service';
import { AuthorizationService } from '../../../roles/services/authorization.service';
import {UserResponse, UsersResponse} from '../../../auth/responses/user.response';
import { forkJoin, Subscription } from 'rxjs';
import { AppHelper } from '../../../shared/classes/app.helper';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-users-update',
  templateUrl: './users-update.component.html',
  styleUrls: ['./users-update.component.css']
})
export class UsersUpdateComponent implements OnInit, OnDestroy {
  public subscriptions = new Subscription();
  public userFormGroup: FormGroup;
  public user: User;
  public user_id: string;
  public roles: Role[];
  public updatingUser = false;
  public loadingRequests = false;
  public codeUsers: User[] = [];

  private static passwordMatchValidator(formGroup: FormGroup) {
    return formGroup.get('password').value ===
    formGroup.get('password_confirmation').value
      ? null
      : { 'password-mismatch': true };
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private authorizationService: AuthorizationService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.user_id = params['user_id'];
        this.initialRequests();
      })
    );

    this.initUserForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initUserForm() {
    this.userFormGroup = this.formBuilder.group(
      {
        name: ['', [Validators.required]],
        code: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        job_title: [''],
        roles: ['', [Validators.required]],
        password: [null],
        password_confirmation: [null]
      },
      {
        validator: UsersUpdateComponent.passwordMatchValidator
      });

    this.subscriptions.add(
      this.userFormGroup.get('code').valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        this.codeUsers = [];

        if (value) {
          this.validateUserCode();
        }
      })
    );

    const passwordFormControl = this.userFormGroup.get('password');

    this.subscriptions.add(
      passwordFormControl.valueChanges.subscribe(
        (value) => {
          if (value.length > 0) {
            passwordFormControl.setValidators([Validators.minLength(8)]);
          } else {
            passwordFormControl.setValidators(null);
          }
        }
      )
    );
  }

  initialRequests() {
    const group = 'admin';

    const includes = ['roles', 'auth_attempts'];
    const findUserByIdRequest = this.usersService.findUserById(this.user_id, includes);
    const getAllRolesRequest = this.authorizationService.getAllRoles(group);

    this.loadingRequests = true;

    forkJoin([findUserByIdRequest, getAllRolesRequest]).subscribe(
      response => {
        this.loadingRequests = false;

        this.user = response[0].data;
        this.roles = response[1].data;

        if (this.user) {
          this.fillUserForm();
        }
      },
      () => {
        this.loadingRequests = false;
        this.snotifyService.error(
          'Unable to load resources. Please try again.',
          'Error!'
        );
      }
    );
  }

  validateUserCode() {
    const code = this.userFormGroup.get('code').value;
    const email = this.userFormGroup.get('email').value;

    this.usersService.getUsersByCode(code, email).subscribe(
      (response: UsersResponse) => {
        this.codeUsers = response.data;
      },
      () => {
        
      }
    );
  }

  fillUserForm() {
    this.userFormGroup.controls['name'].setValue(this.user.name);
    this.userFormGroup.controls['code'].setValue(this.user.code);
    this.userFormGroup.controls['email'].setValue(this.user.email);
    this.userFormGroup.controls['job_title'].setValue(this.user.job_title);
    if (this.user.roles.data.length > 0) {
      const roleIds = AppHelper.pluck(this.user.roles.data, 'id');
      this.userFormGroup.controls['roles'].setValue(roleIds);
    }
    if (this.user.is_admin) {
      this.userFormGroup.controls['roles'].disable();
    }
  }

  onSubmit() {
    this.updatingUser = true;
    const payload = this.userFormGroup.value;
    payload.id = this.user.id;

    this.usersService.updateUser(payload).subscribe(
      (response: UserResponse) => {
        this.updatingUser = false;
        this.snotifyService.success(
          'User has been successfully updated.',
          'Success!'
        );
        this.user = response.data;
        AppHelper.chime();
      },
      () => {
        this.updatingUser = false;
        this.snotifyService.error(
          'Unable to update user. Please try again.',
          'Error!'
        );
      }
    );
  }
}
