import { Component, OnInit } from '@angular/core';
import { SitesService } from '../../../sites/services/sites.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { SiteResponse } from '../../responses/site.response';
import { Site } from '../../models/site.model';
import { AppHelper } from '../../classes/app.helper';

@Component({
  selector: 'app-sites-create-modal',
  templateUrl: './sites-create-modal.component.html',
  styleUrls: ['./sites-create-modal.component.css'],
})
export class SitesCreateModalComponent implements OnInit {
  public clientId: string;
  public formGroup: FormGroup;
  public onHide: Subject<Site> = new Subject();
  public postingSite = false;

  constructor(
    public bsModalRef: BsModalRef,
    private sitesService: SitesService,
    private modalService: BsModalService,
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      client_id: [this.clientId, [Validators.required]],
      name: [null, [Validators.required]],
    });
  }

  onSubmit() {
    this.postingSite = true;
    const payload = this.formGroup.value;

    this.sitesService.createSite(payload).subscribe(
      (site: SiteResponse) => {
        this.postingSite = false;
        this.snotifyService.success('A new site has been created.', 'Success!');
        this.bsModalRef.hide();
        this.onHide.next(site.data);
        AppHelper.chime();
      },
      () => {
        this.postingSite = false;
        this.snotifyService.error('Unable to create site. Please try again.', 'Error!');
      }
    );
  }

}
