<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Copy Survey
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="formGroup" class="modal-body">
    <app-jobs-search
      (jobSelected)="jobSelected($event)"
      [jobNumber]="job.number"></app-jobs-search>

    <div class="form-group">
      <label>
        Surveyors
      </label>
      <ngx-select
        class="form-control"
        [ngClass]="{'is-invalid': !formGroup.controls['survey_users'].valid && formGroup.controls['survey_users'].touched}"
        [items]="job.users.data"
        [formControl]="formGroup.controls['survey_users']"
        [optionValueField]="optionValueField"
        [multiple]="true"
        optionTextField="name"></ngx-select>
      <div class="help-block text-danger"
           *ngIf="formGroup.controls['survey_users'].touched && formGroup.controls['survey_users'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="survey_type">
        Survey Type
      </label>
      <select
        [formControl]="formGroup.controls['survey_type']"
        class="form-control"
        id="survey_type"
        name="survey_type">
        <option [value]="surveyTypeEnum.Management">Management</option>
        <option [value]="surveyTypeEnum.Refurbishment">Refurbishment</option>
        <option [value]="surveyTypeEnum.Demolition">Demolition</option>
        <option [value]="surveyTypeEnum['Re-inspection']">Re-Inspection</option>
      </select>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid || copyingSurvey" (click)="onSubmit()">
      <span *ngIf="!copyingSurvey">
        Copy
      </span>
      <span *ngIf="copyingSurvey">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Copying
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
