import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { AreasService } from '../../services/areas.service';
import { YesNoEnum } from '../../../shared/enums/yes-no.enum';
import { ImageCompressService } from 'ng2-image-compress';
import { AreaAccessEnum } from '../../enums/area-access.enum';
import { AppHelper } from "../../../shared/classes/app.helper";
import { StateService } from '../../../shared/services/state.service';
import { SurveyTypeEnum } from '../../../shared/enums/survey-type.enum';

@Component({
  selector: 'app-areas-create',
  templateUrl: './areas-create.component.html',
  styleUrls: ['./areas-create.component.css'],
})
export class AreasCreateComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public postingArea = false;
  public numberValidation = false;
  public buildingId: string;
  public floorId: string;
  public onHide = new Subject();
  public yesNoEnum = YesNoEnum;
  public surveyTypeEnum = SurveyTypeEnum;
  public subscriptions = new Subscription();
  public areaAccessButtons = [
    {
      value: AreaAccessEnum.No_Access,
      label: AreaAccessEnum[AreaAccessEnum.No_Access]
    },
    {
      value: AreaAccessEnum.Limited_Access,
      label: AreaAccessEnum[AreaAccessEnum.Limited_Access]
    },
    {
      value: AreaAccessEnum.Accessible,
      label: AreaAccessEnum[AreaAccessEnum.Accessible]
    },
  ];
  public isDefaultButtons = [
    {
      value: YesNoEnum.Yes,
      label: 'Yes'
    },
    {
      value: YesNoEnum.No,
      label: 'No'
    },
  ];
  public nextAreaNumber: string;
  public areaNumbers: string[];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private areasService: AreasService,
    public stateService: StateService,
  ) {
    if (+this.stateService.surveyType === SurveyTypeEnum['Re-inspection']) {
      this.areaAccessButtons.push({
        value: AreaAccessEnum.No_Access_Previously_Accessed,
        label: 'No Access (Previously Accessed)',
      });
    }
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      building_id: [this.buildingId],
      floor_id: [this.floorId],
      number: [this.nextAreaNumber],
      description: [null, [Validators.required]],
      accessible: [AreaAccessEnum.Accessible, [Validators.required]],
      deviation_to_scope: [YesNoEnum.No, [Validators.required]],
      no_access_reason: [null],
      image: [null],
    });

    if (+this.stateService.surveyType === SurveyTypeEnum['Re-inspection']) {
      this.subscriptions.add(
        this.formGroup.get('number').valueChanges.subscribe(value => {
          this.numberValidation = this.areaNumbers.includes(value);
        })
      );
    }
  }

  onSubmit() {
    this.postingArea = true;
    const payload = this.formGroup.getRawValue();

    this.areasService.createArea(payload).subscribe(
      () => {
        this.bsModalRef.hide();
        this.onHide.next();
        this.postingArea = false;
        AppHelper.chime();
      },
      () => {
        this.postingArea = false;
        this.snotifyService.error('Unable to create Area. Please try again.', 'Error!');
      },
    );
  }

  onFileChange($event) {
    ImageCompressService.filesToCompressedImageSource($event.srcElement.files).then(observableImages => {
      observableImages.subscribe(
        image => {
          this.formGroup.get('image').setValue(image.compressedImage.imageDataUrl as string);
        },
        error => {
          this.snotifyService.error('Unable to load photo. Please try again.', 'Error!');
        },
        () => {
          // Done
        }
      );
    });
  }
}
