import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { SyncService } from '../../services/sync.service';
import { BusService } from '../../services/bus.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu-popover',
  templateUrl: './user-menu-popover.component.html',
  styleUrls: ['./user-menu-popover.component.css']
})
export class UserMenuPopoverComponent implements OnInit {
  public environment = environment;
  public subscriptions = new Subscription();
  public app = null;

  constructor(
    private authService: AuthService,
    private syncService: SyncService,
    private busService: BusService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(
        (queryParams) => {
          if (queryParams.app) {
            this.app = queryParams.app;
          }
        }
      )
    );
  }

  onLogout() {
    this.busService.dispatch({ type: 'dismissUserMenu', data: null });
    return this.authService.logout();
  }

  onSyncAPI() {
    this.busService.dispatch({ type: 'dismissUserMenu', data: null });
    return this.syncService.forceSyncAPI();
  }
}
