import { Component, OnInit } from '@angular/core';
import { Role } from '../../models/role.model';
import { AuthorizationService } from '../../services/authorization.service';
import { SnotifyService } from 'ng-snotify';
import { RolesResponse } from '../../responses/role.response';

@Component({
  selector: 'app-roles-index',
  templateUrl: './roles-index.component.html',
  styleUrls: ['./roles-index.component.css']
})
export class RolesIndexComponent implements OnInit {
  public activeRole: Role;
  public roles: Role[];
  public gettingAllRoles = false;

  constructor(private authorizationService: AuthorizationService, private snotifyService: SnotifyService) {}

  ngOnInit() {
    this.getAllRoles();
  }

  getAllRoles() {
    this.gettingAllRoles = true;

    this.authorizationService.getAllRoles().subscribe(
      (response: RolesResponse) => {
        this.gettingAllRoles = false;
        this.roles = response.data;
      },
      error => {
        this.gettingAllRoles = false;
        this.snotifyService.error('Unable to load roles. Please try again.', 'Error!');
      }
    );
  }

  onRoleDelete(role: Role) {
    this.snotifyService.confirm(`User role "${role.display_name}" is going to be deleted.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteRole(role),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteRole(role: Role) {
    role.deleting = true;

    this.authorizationService.deleteRole(role).subscribe(
      response => {
        role.deleting = false;
        this.snotifyService.success('The user role has been successfully deleted.', 'Success!');
        this.getAllRoles();
      },
      error => {
        role.deleting = false;
        this.snotifyService.error('Unable to delete user role. Please try again.', 'Error!');
      }
    );
  }
}
