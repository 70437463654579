import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { Survey } from '../../../surveys/models/survey.model';
import { SurveysService } from '../../../surveys/services/surveys.service';
import { Subject, Subscription } from 'rxjs';
import { SurveyResponse } from '../../../surveys/responses/survey.response';

@Component({
  selector: 'app-surveys-settings-modal',
  templateUrl: './surveys-settings-modal.component.html',
  styleUrls: ['./surveys-settings-modal.component.css'],
})
export class SurveysSettingsModalComponent implements OnInit, OnDestroy {
  public survey: Survey;
  public formGroup: FormGroup;
  public updating = false;
  public subscriptions = new Subscription();
  public onHide: Subject<Survey> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private surveysService: SurveysService,
  ) {
  }

  ngOnInit() {
    this.initFormGroup();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      survey_id: [this.survey.id, [Validators.required]],
      custom_number: [this.survey.custom_number, [Validators.required]],
      number: [this.survey.number],
    });

    this.subscriptions.add(
      this.formGroup.get('custom_number').valueChanges.subscribe(value => {
        if (value) {
          this.formGroup.get('number').setValidators([Validators.required]);
        } else {
          this.formGroup.get('number').setValue(null);
          this.formGroup.get('number').clearValidators();
        }

        this.formGroup.get('number').updateValueAndValidity();
      })
    );
  }

  update() {
    this.updating = true;
    const payload = this.formGroup.value;

    this.surveysService.settings(this.survey.id, payload).subscribe(
      (response: SurveyResponse) => {
        this.updating = false;
        this.onHide.next(response.data);
        this.bsModalRef.hide();
      },
      () => {
        this.updating = false;
      },
    );
  }
}
