<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Download Survey
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!downloading && !downloadFinished" class="alert alert-info">
      Click Download button to start!
    </div>

    <div *ngIf="!downloading && downloadFinished" class="alert alert-info">
      Download has been completed. Close this window to view the Survey.
    </div>

    <div *ngIf="downloading && activeSurvey" class="alert alert-info">
      <h6 class="font-weight-bold">Syncing details</h6>
      <ul class="mb-0">
        <li *ngIf="activeSurvey"><span class="font-weight-bold">Survey:</span> {{ surveyTypeEnum[activeSurvey.survey_type] | replaceUnderscoreWithSpace }}</li>
        <li *ngIf="activeBuilding"><span class="font-weight-bold">Building:</span> {{ activeBuilding.name }}</li>
        <li *ngIf="activeFloor"><span class="font-weight-bold">Floor:</span> {{ getFloorName(activeFloor) }}</li>
        <li *ngIf="activeArea"><span class="font-weight-bold">Area:</span> {{ activeArea.description }}</li>
        <li *ngIf="activeComponent"><span class="font-weight-bold">Component:</span> {{ activeComponent.text }}</li>
        <li *ngIf="activeSurveySample"><span class="font-weight-bold">Survey sample:</span> {{ activeSurveySample.number }}</li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="downloading || downloadFinished" (click)="download()">
      <span *ngIf="!downloading">
        Download
      </span>
      <span *ngIf="downloading">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Downloading
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
