import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../auth/models/user.model';
import { ClientsService } from '../../services/clients.service';
import { SnotifyService } from 'ng-snotify';
import { ClientResponse } from '../../responses/client.response';
import { Client } from '../../models/client.model';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../users/services/users.service';
import { User as AuthUser } from '../../../auth/classes/user.class';
import { AuthService } from '../../../auth/services/auth.service';
import { AppHelper } from '../../../shared/classes/app.helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-index',
  templateUrl: './users-index.component.html',
  styleUrls: ['./users-index.component.css']
})
export class UsersIndexComponent implements OnInit, OnDestroy {
  public client: Client;
  public client_id: string;
  public users: User[];
  public activeUser: User;
  public gettingAllClientUsers = false;
  private subscription: any;
  public authenticatedUser: AuthUser;
  public environment = environment;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private authService: AuthService,
    private clientsService: ClientsService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      this.client_id = params.client_id;
      this.getAllClientUsers();
    });

    this.authenticatedUser = this.authService.user;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAllClientUsers() {
    this.gettingAllClientUsers = true;

    this.clientsService.getAllClientUsers(this.client_id).subscribe(
      (response: ClientResponse) => {
        this.gettingAllClientUsers = false;
        this.client = response.data;
        this.users = this.client.users.data;
      },
      error => {
        this.gettingAllClientUsers = false;
        this.snotifyService.error(
          'Unable to load client users. Please try again.',
          'Error!'
        );
      }
    );
  }

  onUserDelete(user: User) {
    this.snotifyService.confirm(
      `User "${user.name}" is going to be erased.`,
      'Are you sure?',
      {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            action: () => this.deleteUser(user),
            bold: false
          },
          { text: 'No', action: () => {} }
        ]
      }
    );
  }

  deleteUser(user: User) {
    user.deleting = true;

    this.usersService.deleteUser(user).subscribe(
      response => {
        user.deleting = false;
        this.snotifyService.success(
          `User "${user.name}" has been successfully deleted.`,
          'Success!'
        );
        this.getAllClientUsers();
        AppHelper.chime();
      },
      error => {
        user.deleting = false;
        this.snotifyService.error(
          `Unable to delete user "${user.name}". Please try again.`,
          'Error!'
        );
      }
    );
  }
}
