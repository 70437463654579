import { Component, OnDestroy, OnInit } from '@angular/core';
import { Client } from '../../models/client.model';
import { ClientsService } from '../../services/clients.service';
import { SnotifyService } from 'ng-snotify';
import { ClientsResponse } from '../../responses/client.response';
import { ClientStatusEnum } from '../../enums/status.enum';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-clients-index',
  templateUrl: './clients-index.component.html',
  styleUrls: ['./clients-index.component.css']
})
export class ClientsIndexComponent implements OnInit, OnDestroy {
  public clients: Client[];
  public activeClient: Client;
  public gettingAllClients = false;
  public meta: Meta;
  public links: Links;
  public statuses = ClientStatusEnum;
  public subscriptions: Subscription = new Subscription();
  public searchTerm: string;

  constructor(private clientsService: ClientsService, private snotifyService: SnotifyService, private busService: BusService) {}

  ngOnInit() {
    this.getAllClients();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllClients = event.data;
      })
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateClientsList(event.data);
      })
    );
  }

  getAllClients() {
    this.gettingAllClients = true;
    const limit = 15;

    this.clientsService.getAllClients(limit, this.searchTerm).subscribe(
      (response: ClientsResponse) => {
        this.gettingAllClients = false;
        this.updateClientsList(response);
      },
      () => {
        this.gettingAllClients = false;
        this.snotifyService.error('Unable to load clients. Please try again.', 'Error!');
      }
    );
  }

  onClientDelete(client: Client) {
    this.snotifyService.confirm(`Client "${client.name}" is going to be erased.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteClient(client),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteClient(client: Client) {
    client.deleting = true;

    this.clientsService.deleteClient(client).subscribe(
      () => {
        client.deleting = false;
        this.snotifyService.success(`Client "${client.name}" has been successfully deleted.`, 'Success!');
        this.getAllClients();
        AppHelper.chime();
      },
      () => {
        client.deleting = false;
        this.snotifyService.error(`Unable to delete client "${client.name}". Please try again.`, 'Error!');
      }
    );
  }

  updateClientsList(response: ClientsResponse) {
    this.clients = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }

  clearSearch() {
    this.searchTerm = null;
    this.getAllClients();
  }
}
