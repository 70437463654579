import { Injectable } from '@angular/core';
import { Survey } from '../../surveys/models/survey.model';
import { Building } from '../../buildings/models/building.model';
import { ComponentModel } from '../../components/models/component.model';
import { SurveySample } from '../../survey-samples/models/survey-sample.model';
import { SurveyQueryBuilder } from '../classes/survey.query-builder.class';
import { AppHelper } from '../classes/app.helper';
import { BuildingQueryBuilder } from '../classes/building.query-builder.class';
import { FloorQueryBuilder } from '../classes/floor.query-builder.class';
import { Floor } from '../../floors/models/floor.model';
import { Area } from '../../areas/models/area.model';
import { AreaQueryBuilder } from '../classes/area.query-builder.class';
import { ComponentQueryBuilder } from '../classes/component.query-builder.class';
import { SurveySampleQueryBuilder } from '../classes/survey_sample.query-builder.class';
import { Job } from '../../jobs/models/job.model';
import { AssignmentQueryBuilder } from '../classes/assignment.query-builder.class';
import { Form } from '../../forms/models/form.model';
import { FormQueryBuilder } from '../classes/form.query-builder.class';

@Injectable()
export class CascadeService {
  constructor() {}

  async job(job: Job) {
    const queryBuilder = new AssignmentQueryBuilder();

    const surveys = await queryBuilder.loadItemRelationship('surveys', 'assignment_id', job.id);
    await this.surveys(surveys);

    const forms = await queryBuilder.loadItemRelationship('forms', 'assignment_id', job.id);
    await this.forms(forms);

    const jobQuery = this.deleteItemQuery('assignments', 'id', job.id);
    queryBuilder._query(jobQuery);
  }

  async forms(forms: Form[]) {
    if (!forms || forms.length === 0) {
      return;
    }

    const formQueryBuilder = new FormQueryBuilder();
    const formsIds = AppHelper.pluck(forms, 'id');
    const formsQuery = this.deleteCollectionQuery('forms', 'id', formsIds);
    formQueryBuilder._query(formsQuery);
  }

  async surveys(surveys: Survey[]) {
    if (!surveys || surveys.length === 0) {
      return;
    }

    for (const survey of surveys) {
      await this.survey(survey);
    }

    const surveyQueryBuilder = new SurveyQueryBuilder();
    const surveysIds = AppHelper.pluck(surveys, 'id');
    const surveysQuery = this.deleteCollectionQuery('surveys', 'id', surveysIds);
    surveyQueryBuilder._query(surveysQuery);
  }

  async survey(survey: Survey) {
    const queryBuilder = new SurveyQueryBuilder();
    const buildings = await queryBuilder.loadItemRelationship('buildings', 'survey_id', survey.id);
    await this.buildings(buildings);
  }

  async buildings(buildings: Building[]) {
    if (!buildings || buildings.length === 0) {
      return;
    }

    const buildingQueryBuilder = new BuildingQueryBuilder();

    // Floors
    const floors = await buildingQueryBuilder.loadCollectionRelationships('floors', 'building_id', 'id', buildings);
    await this.floors(floors);

    // Buildings
    const buildingsIds = AppHelper.pluck(buildings, 'id');
    const buildingsQuery = this.deleteCollectionQuery('buildings', 'id', buildingsIds);
    buildingQueryBuilder._query(buildingsQuery);
  }

  async building(building: Building) {
    const buildingQueryBuilder = new BuildingQueryBuilder();

    // Floors
    const floors = await buildingQueryBuilder.loadItemRelationship('floors', 'building_id', building.id);
    await this.floors(floors);

    // Building
    const buildingQuery = this.deleteItemQuery('buildings', 'id', building.id);
    buildingQueryBuilder._query(buildingQuery);
  }

  async floors(floors: Floor[]) {
    if (!floors || floors.length === 0) {
      return;
    }

    const floorQueryBuilder = new FloorQueryBuilder();

    // Areas
    const areas = await floorQueryBuilder.loadCollectionRelationships('areas', 'floor_id', 'id', floors);
    await this.areas(areas);

    // Floors
    const floorsIds = AppHelper.pluck(floors, 'id');
    const floorsQuery = this.deleteCollectionQuery('floors', 'id', floorsIds);
    floorQueryBuilder._query(floorsQuery);
  }

  async floor(floor: Floor) {
    const floorQueryBuilder = new FloorQueryBuilder();

    // Areas
    const areas = await floorQueryBuilder.loadItemRelationship('areas', 'floor_id', floor.id);
    await this.areas(areas);

    // Floor
    const floorQuery = this.deleteItemQuery('floors', 'id', floor.id);
    floorQueryBuilder._query(floorQuery);
  }

  async areas(areas: Area[]) {
    if (!areas || areas.length === 0) {
      return;
    }

    const areaQueryBuilder = new AreaQueryBuilder();

    // Components
    const components = await areaQueryBuilder.loadCollectionRelationships('components', 'area_id', 'id', areas);
    await this.components(components);

    // Areas
    const areasIds = AppHelper.pluck(areas, 'id');
    const areasQuery = this.deleteCollectionQuery('areas', 'id', areasIds);
    areaQueryBuilder._query(areasQuery);
  }

  async area(area: Area) {
    const areaQueryBuilder = new AreaQueryBuilder();

    // Components
    const components = await areaQueryBuilder.loadItemRelationship('components', 'area_id', area.id);
    await this.components(components);

    // Area
    const areaQuery = this.deleteItemQuery('areas', 'id', area.id);
    areaQueryBuilder._query(areaQuery);
  }

  async components(components: ComponentModel[]) {
    if (!components || components.length === 0) {
      return;
    }

    const componentQueryBuilder = new ComponentQueryBuilder();

    // Survey Samples
    const surveySamples = await componentQueryBuilder.loadCollectionRelationships('survey_samples', 'component_id', 'id', components);
    await this.surveySamples(surveySamples);

    // Components
    const componentsIds = AppHelper.pluck(components, 'id');
    const componentsQuery = this.deleteCollectionQuery('components', 'id', componentsIds);
    componentQueryBuilder._query(componentsQuery);
  }

  async component(component: ComponentModel) {
    const componentQueryBuilder = new ComponentQueryBuilder();

    // Survey Samples
    const surveySamples = await componentQueryBuilder.loadItemRelationship('survey_samples', 'component_id', component.id);
    await this.surveySamples(surveySamples);

    // Component
    const componentQuery = this.deleteItemQuery('components', 'id', component.id);
    componentQueryBuilder._query(componentQuery);
  }

  async surveySamples(surveySamples: SurveySample[]) {
    if (!surveySamples || surveySamples.length === 0) {
      return;
    }

    const surveySamplesQueryBuilder = new SurveySampleQueryBuilder();
    const surveySamplesIds = AppHelper.pluck(surveySamples, 'id');
    const surveySamplesQuery = this.deleteCollectionQuery('survey_samples', 'id', surveySamplesIds);
    surveySamplesQueryBuilder._query(surveySamplesQuery);
  }

  private deleteItemQuery(table: string, column: string, id: any) {
    return `DELETE FROM ${table} WHERE ${column} = "${id}"`;
  }

  private deleteCollectionQuery(table: string, column: string, ids: any[]) {
    return `DELETE FROM ${table} WHERE ${column} IN (${ids.join(', ')})`;
  }
}
