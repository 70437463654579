import { BaseModel } from '../classes/query-builder.class';

export class Building extends BaseModel {
  public table = 'buildings';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    uuid: 'VARCHAR',
    hashed_id: 'VARCHAR',
    survey_id: 'VARCHAR',
    default_components: 'VARCHAR',
    name: 'VARCHAR',
    synced_at: 'DATETIME',
    updated_at: 'DATETIME',
  };
}
