import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../auth/models/user.model';
import { Role } from '../../../roles/models/role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthorizationService } from '../../../roles/services/authorization.service';
import { UserResponse } from '../../../auth/responses/user.response';
import { RolesResponse } from '../../../roles/responses/role.response';
import { ClientsService } from '../../services/clients.service';
import { AppHelper } from '../../../shared/classes/app.helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.css']
})
export class UsersCreateComponent implements OnInit, OnDestroy {
  public userFormGroup: FormGroup;
  private user: User;
  public roles: Role[];
  public postingUser = false;
  public gettingRoles = false;
  public syncingUserRoles = false;
  public client_id: string;
  public projectId: string;
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private clientsService: ClientsService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.client_id = params['client_id'];
      })
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe(queryParams => {
        if (queryParams.projectId) {
          this.projectId = queryParams.projectId;
        }
      })
    );

    this.initUserForm();
    this.getAllRoles();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initUserForm() {
    this.userFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      mobile: [null]
    });
  }

  onSubmit() {
    this.postingUser = true;
    const payload = this.userFormGroup.value;

    if (this.projectId) {
      payload.project_id = this.projectId;
    }

    this.clientsService.createClientUser(payload, this.client_id).subscribe(
      (response: UserResponse) => {
        this.postingUser = false;
        this.snotifyService.success('A new user has been created.', 'Success!');
        this.user = response.data;
        AppHelper.chime();

        if (this.projectId) {
          return this.router.navigate(['/projects/update', this.projectId]);
        }

        return this.router.navigate(['/clients', this.client_id, 'users']);
      },
      error => {
        this.postingUser = false;
        this.snotifyService.error(
          'Unable to create user. Please try again.',
          'Error!'
        );
      }
    );
  }

  getAllRoles() {
    this.gettingRoles = true;
    const group = 'client';

    this.authorizationService.getAllRoles(group).subscribe(
      (response: RolesResponse) => {
        this.gettingRoles = false;
        this.roles = response.data;
      },
      error => {
        this.gettingRoles = false;
        this.snotifyService.error(
          'Unable to load user roles. Please try again.',
          'Error!'
        );
      }
    );
  }

}
