import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthorizationService } from '../../services/authorization.service';
import { PermissionsResponse } from '../../responses/permission.response';

@Component({
  selector: 'app-roles-create',
  templateUrl: './roles-create.component.html',
  styleUrls: ['./roles-create.component.css']
})
export class RolesCreateComponent implements OnInit {
  roleFormGroup: FormGroup;
  creatingRole = false;
  gettingAllPermissions = false;
  syncingRolePermissions = false;
  permissions: Permission[];
  role: Role;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.initRoleForm();
    this.getPermissions();
  }

  initRoleForm() {
    this.roleFormGroup = this.formBuilder.group({
      display_name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.creatingRole = true;
    const payload = {
      name: this.roleFormGroup.controls['display_name'].value.toLowerCase().split(' ').join('_'),
      display_name: this.roleFormGroup.controls['display_name'].value,
      description: this.roleFormGroup.controls['description'].value
    };

    this.authorizationService.createRole(payload).subscribe(
      response => {
        this.role = response.data;
        this.creatingRole = false;
        this.snotifyService.success(
          'A new user role has been created.',
          'Success!'
        );
        return this.syncRolePermissions();
      },
      error => {
        this.creatingRole = false;
        this.snotifyService.error(
          'Unable to create new user role. Please try again.',
          'Error!'
        );
      }
    );
  }

  getPermissions() {
    this.gettingAllPermissions = true;

    this.authorizationService.getAllPermissions().subscribe(
      (response: PermissionsResponse) => {
        this.gettingAllPermissions = false;
        this.permissions = response.data;
      },
      error => {
        this.gettingAllPermissions = false;
        this.snotifyService.error(
          'Unable to load permissions. Please try again.',
          'Error!'
        );
      }
    );
  }

  syncRolePermissions() {
    const permissions_ids = this.getEnabledPermissionsIds();

    if (permissions_ids.length === 0) {
      return this.router.navigate(['/roles/index']);
    }

    this.syncingRolePermissions = true;

    this.authorizationService.syncRolePermissions(this.role.id, permissions_ids).subscribe(
      response => {
        this.syncingRolePermissions = false;
        this.snotifyService.success(
          'User role permissions have been synced.',
          'Success!'
        );
        return this.router.navigate(['/roles/index']);
      },
      error => {
        this.syncingRolePermissions = false;
        this.snotifyService.success('Unable to sync user roles.', 'Error!');
      }
    );
  }

  getEnabledPermissionsIds() {
    const ids = [];

    this.permissions.forEach(permission => {
      if (permission.selected) {
        ids.push(permission.id);
      }
    });

    return ids;
  }

}
