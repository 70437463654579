import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { SqlDatabase, SqlResultSet } from 'ionix-sqlite/dist';
import { AppHelper } from './app.helper';

export class BaseModel {
  public database = 'hfs-env-v016';
}

/**
 * General Query Builder
 */
export class QueryBuilder {
  public loadedRelationships: string[] = [];
  public _database: Promise<SqlDatabase>;
  public _table: string;
  public _where: Array<any> = [];
  public _limit: number = null;
  public _skip: number = null;
  public _order: Array<any> = [];
  public _includes: Array<any> = [];
  public _resource: string;
  public _showQuery = false;

  /**
   * Query Builder Constructor.
   */
  constructor(public baseModel: BaseModel, migrate = false) {
    this._initDataBase(baseModel, migrate);
  }

  /**
   * Create entity.
   */
  create(items: object): Observable<any> {
    const itemsMark = [];
    const itemsKey = [];
    const itemsValue = [];

    for (const item in items) {
      if (items.hasOwnProperty(item)) {
        itemsMark.push('?');
        itemsKey.push(item);
        itemsValue.push(items[item]);
      }
    }

    const query = `INSERT INTO ${this._table} (${itemsKey.join(', ')}) VALUES (${itemsMark.join(', ')})`;
    return from(
      new Promise((resolve, reject) => {
        this._query(query, itemsValue).then(
          (response: any) => {
            resolve(JsonSerializer.item(Object.assign(items, { id: response.insertId })));
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Create entity.
   */
  createMultipleRows(items: object[]): Observable<any> {
    const itemsMark = [];
    const itemsKey = [];
    const values = [];

    for (const key in items[0]) {
      if (items[0].hasOwnProperty(key)) {
        itemsMark.push('?');
        itemsKey.push(key);
      }
    }

    items.forEach(element => {
      const value = [];

      for (const key in element) {
        if (element.hasOwnProperty(key)) {
          value.push(element[key]);
        }
      }

      values.push(value);
    });

    const query = `INSERT INTO ${this._table} (${itemsKey.join(', ')}) VALUES (${itemsMark.join(', ')})`;
    return from(
      new Promise((resolve, reject) => {
        this._queryMultiple(query, values).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Create multiple entities.
   */
  createMultiple(items: any[]): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        await Promise.all(
          items.map(async item => {
            await this.updateOrCreate(item).toPromise();
            return item;
          })
        );

        return resolve(items);
      })
    );
  }

  /**
   * Update entity.
   */
  update(items: object): Observable<any> {
    const itemsKey = [];
    const itemsValue = [];

    for (const item in items) {
      // Don't try to update id column
      if (item === 'id') {
        continue;
      }

      if (items.hasOwnProperty(item)) {
        itemsKey.push(`${item}=?`);
        itemsValue.push(items[item]);
      }
    }

    this._where = [`id = '${items['id']}'`];

    const query = `UPDATE ${this._table} SET ${itemsKey.join(',')} ${this._buildWhereQuery()}`;
    return from(
      new Promise((resolve, reject) => {
        this._query(query, itemsValue).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Update entity.
   */
  updateByField(items: object, field: string): Observable<any> {
    const itemsKey = [];
    const itemsValue = [];

    for (const item in items) {
      // Don't try to update id column
      if (item === 'id') {
        continue;
      }

      if (items.hasOwnProperty(item)) {
        itemsKey.push(`${item}=?`);
        itemsValue.push(items[item]);
      }
    }

    this._where = [`${field} = '${items[field]}'`];

    const query = `UPDATE ${this._table} SET ${itemsKey.join(',')} ${this._buildWhereQuery()}`;
    return from(
      new Promise((resolve, reject) => {
        this._query(query, itemsValue).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Update or create entity.
   */
  updateOrCreate(items: object): Observable<any> {
    this._where = [`id = '${items['id']}'`];
    const query = `SELECT * FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then((response: any) => {
          const result = this._filterQueryToArray(response);

          if (result.length) {
            this.update(items).subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          }

          if (!result.length) {
            this.create(items).subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          }
        });
      })
    );
  }

  /**
   * Update or create entity.
   */
  updateOrCreateByField(items: object, field: string): Observable<any> {
    this._where = [`${field} = '${items[field]}'`];
    const query = `SELECT * FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then((response: any) => {
          const result = this._filterQueryToArray(response);

          if (result.length) {
            this.updateByField(items, field).subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          }

          if (!result.length) {
            this.create(items).subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          }
        });
      })
    );
  }

  find(tableName: string, id: number|string, key: string = 'id') {
    return new Promise(async (resolve, reject) => {
      const query = `SELECT * FROM ${tableName} WHERE ${key} = "${id}" LIMIT 1`;
      const response: SqlResultSet = await this._query(query);
      const data = this._filterQueryToArray(response);

      resolve(data[0]);
    });
  }

  /**
   * Get the first resource element.
   */
  first(items: string = '*'): Observable<any> {
    const resourcePromise = this._query(
      `SELECT ${items} FROM ${this._table} ${this._buildWhereQuery()} ${this._buildOrderByQuery()} LIMIT 1`
    );
    const promises = [resourcePromise];

    this._includes.forEach(include => {
      promises.push(this._query(`SELECT * FROM ${include}`));
    });

    return from(
      Promise.all(promises)
        .then((responses: any[]) => {
          const resourceResponse = responses.shift();
          const includesResponses = [];

          this._includes.forEach((include, index) => {
            includesResponses.push({
              resource: include,
              data: this._filterQueryToArray(responses[index])
            });
          });

          return resourceResponse.rows.length
            ? JsonSerializer.item(resourceResponse.rows.item(0), includesResponses, this._resource)
            : null;
        })
        .catch(error => console.log(error))
    );
  }

  /**
   * Get all resource elements.
   */
  get(items: string = '*'): Observable<any> {
    const query = `SELECT ${items} FROM ${this._table} ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            const data = this._filterQueryToArray(response);
            resolve(JsonSerializer.collection(data));
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Count resource elements.
   */
  count(items: string = '*'): Observable<any> {
    const query = `SELECT COUNT(${items}) FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            const data = this._filterQueryToArray(response);
            resolve(JsonSerializer.count(data));
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Remove entity.
   */
  remove(): Observable<any> {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Delete resource elements.
   */
  truncate(): Observable<any> {
    const query = `DELETE FROM ${this._table}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }

  /**
   * Include resource relationships.
   */
  resourceIncludes(resource, includes) {
    this._includes = includes;
    this._resource = resource;

    return this;
  }

  /**
   * Limit elements.
   */
  limit(limit: number, skip: number = 0): any {
    this._limit = limit;
    this._skip = skip;

    return this;
  }

  /**
   * Order elements by attribute.
   */
  orderBy(key: any, value: any): any {
    if (key && value) {
      this._order.push(key + ' ' + value);
    }

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  where(key: string, separator: string, value: any): any {
    const type = this._where.length ? ' AND' : '';

    const condition = `${type} ${key} ${separator} '${value}'`;
    this._where.push(condition);

    return this;
  }

  resetWhere() {
    this._where = [];
  }

  /**
   * Filter elements by attribute int value.
   */
  whereInt(key: string, separator: string, value: any): any {
    const type = this._where.length ? ' AND' : '';

    if (!isNaN(value)) {
      const condition = `${type} ${key} ${separator} ${value}`;
      this._where.push(condition);
    }

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  whereNull(key: string): any {
    const type = this._where.length ? ' AND' : '';

    const condition = `${type} (${key} IS NULL OR ${key} = '')`;
    this._where.push(condition);

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  whereNotNull(key: string): any {
    const type = this._where.length ? ' AND' : '';

    const condition = `${type} (${key} IS NOT NULL AND ${key} IS NOT '')`;
    this._where.push(condition);

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  orWhere(key: string, separator: string, value: any): any {
    const type = this._where.length ? ' OR' : '';

    const condition = `${type} ${key} ${separator} '${value}'`;
    this._where.push(condition);

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  orWhereNull(key: string): any {
    const type = this._where.length ? ' OR' : '';

    const condition = `${type} (${key} IS NULL OR ${key} = '')`;
    this._where.push(condition);

    return this;
  }

  /**
   * Filter elements by attribute value.
   */
  orWhereInt(key: string, separator: string, value: any): any {
    const type = this._where.length ? ' OR' : '';

    if (!isNaN(value)) {
      const condition = `${type} ${key} ${separator} ${value}`;
      this._where.push(condition);
    }

    return this;
  }

  public loadCollectionRelationships(tableName: string, localKey: string = 'hashed_id', foreignKey: string, collectionData: any[]): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const keys = AppHelper.pluck(collectionData, foreignKey).map(id => `"${id}"`);

      const query =  `SELECT * FROM ${tableName} WHERE ${localKey} IN (${keys.join(',')})`;
      const response = await this._query(query);

      const data = this._filterQueryToArray(response);

      return resolve(data);
    });
  }

  public loadItemRelationship(tableName: string, foreignKey: string, key: string|number): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const query =  `SELECT * FROM ${tableName} WHERE ${foreignKey} = "${key}"`;
      const response = await this._query(query);

      const data = this._filterQueryToArray(response);

      return resolve(data);
    });
  }

  public loadTable(tableName: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const query =  `SELECT * FROM ${tableName}`;
      const response = await this._query(query);

      const data = this._filterQueryToArray(response);

      return resolve(data);
    });
  }

  /**
   * Execute sqlite query.
   */
  public _query(query, options: Array<any> = null): any {
    const newQuery = query.replace(/  +/g, ' ');

    if (this._showQuery) {
      console.log(newQuery);
    }

    return new Promise((resolve, reject) => {
      this._database.then(db => {
        db.execute(newQuery, options).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
      });
    });
  }

  /**
   * Execute sqlite query.
   */
  public _queryMultiple(query: string, options: Array<any> = null): any {
    const newQuery = query.replace(/  +/g, ' ');

    if (this._showQuery) {
      console.log(newQuery);
    }

    return new Promise((resolve, reject) => {
      this._database.then(db => {
        db.executeBulk(newQuery, options).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    });
  }

  /**
   * Convert query result to array.
   */
  public _filterQueryToArray(response) {
    const data = [];
    for (let i = 0; i < response.rows.length; i++) {
      data.push(response.rows.item(i));
    }

    return data;
  }

  /**
   * Initialize database.
   */
  public _initDataBase(baseModel, migrate = false) {
    if (baseModel == null) {
      return;
    }

    const migration = [];
    const schemaQuery = [];
    for (const i in baseModel.schema) {
      if (baseModel.schema.hasOwnProperty(i)) {
        migration.push(`${i} ${baseModel.schema[i]}`);
      }
    }
    schemaQuery.push(`CREATE TABLE IF NOT EXISTS ${baseModel.table} (${migration.join(', ')})`);

    this._table = baseModel.table;
    this._database = SqlDatabase.open(baseModel.database, schemaQuery);

    if (!migrate) {
      return this;
    }

    this._database.then(db => {
      for (const i in baseModel.schema) {
        if (baseModel.schema.hasOwnProperty(i)) {
          db.execute(`ALTER TABLE ${baseModel.table} ADD COLUMN ${i} ${baseModel.schema[i]}`).catch(() => {});
        }
      }

      return db;
    });

    return this;
  }

  /**
   * Build order by partial query.
   */
  public _buildOrderByQuery() {
    let order = '';

    if (this._order.length) {
      this._order.forEach((value, index) => {
        order = order + ` ORDER BY ${value}`;

        if (this._order.length > index + 1) {
          order = order + ` , `;
        }
      });
    }

    return order;
  }

  /**
   * Build where partial query.
   */
  public _buildWhereQuery() {
    let wcs = '';
    if (this._where.length) {
      wcs = 'WHERE ';
      this._where.forEach((value, index) => {
        if (index > 0) {
          wcs = wcs;
        }
        wcs = wcs + value;
      });
    }

    return wcs;
  }

  /**
   * Build take partial query.
   */
  public _buildTakeQuery() {
    let q = '';

    if (this._limit && this._skip) {
      q = ` LIMIT ${this._limit} OFFSET ${this._skip}`;
    }

    if (this._limit && !this._skip) {
      q = ` LIMIT ${this._limit}`;
    }

    return q;
  }
}
