<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>Forms List</ion-title>
    <ion-buttons slot="end">
      <app-header></app-header>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dashboard']" routerDirection="root">Dashboard</a>
    </li>

    <li class="breadcrumb-item active">
      <span>Forms</span>
    </li>
  </ol>

  <app-loading *ngIf="loadingInitial || loadingForms"></app-loading>

  <div *ngIf="!loadingInitial && !loadingForms" class="row">
    <div class="col">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>

          <input
              (change)="getAllForms()"
              [(ngModel)]="search"
              type="text"
              class="form-control"
              placeholder="Search..."
              title="Search">

          <div
              *ngIf="search"
              class="input-group-append">
            <div
                (click)="resetSearch()"
                class="btn btn-primary">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="animated fadeIn text-right top-page-nav">
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'all'}" (click)="switchFilter('all')">All</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'open'}" (click)="switchFilter('open')">Open</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'closed'}" (click)="switchFilter('closed')">Closed</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'in_progress'}" (click)="switchFilter('in_progress')">In Progress</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'pending'}" (click)="switchFilter('pending')">Pending</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'completed'}" (click)="switchFilter('completed')">Completed</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="revisions?.length > 0" class="card">
    <div class="card-header">
      <strong>Erroneous</strong>
      Air-Test Revisions
    </div>

    <div class="card-body">
      <p class="alert alert-warning">
        The Air-Tests listed below have revisions that require regeneration. Please delete previous revisions and generate a new one.
      </p>

      <a *ngFor="let revision of revisions" [routerLink]="['/forms/view', revision.form.data.id]" class="badge font-sm badge-pill badge-danger">
        {{ revision.form.data.certificate_number }}
      </a>
    </div>
  </div>

  <div *ngIf="!loadingInitial && !loadingForms" class="card animated fadeIn">
    <div class="card-header">
      <strong>Forms</strong>
      list
    </div>

    <div *ngIf="forms?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No forms found.
    </div>

    <div style="overflow: inherit !important;" class="table-responsive">
      <table *ngIf="forms?.length > 0" class="table table-striped mb-0">
        <tr>
          <th class="d-none d-sm-none d-md-table-cell">
            Project Reference
          </th>

          <th>
            <a href="javascript:" (click)="setSorting('assignment_id')">
              Job Number
              <i *ngIf="sorting && sorting === 'assignment_id'" class="fa fa-sort-alpha-desc mr-1"></i>
              <i *ngIf="sorting && sorting === '-assignment_id'" class="fa fa-sort-alpha-asc mr-1"></i>
            </a>
          </th>

          <th class="d-none d-sm-none d-md-table-cell">
            Client Job Number
          </th>

          <th>
            Client
          </th>

          <th>
            <a href="javascript:" (click)="setSorting('site_id')">
              Address
              <i *ngIf="sorting && sorting === 'site_id'" class="fa fa-sort-alpha-desc mr-1"></i>
              <i *ngIf="sorting && sorting === '-site_id'" class="fa fa-sort-alpha-asc mr-1"></i>
            </a>
          </th>

          <th>
            Certificate Number
          </th>

          <th>
            <a href="javascript:" (click)="setSorting('form_template_id')">
              Cert Type
              <i *ngIf="sorting && sorting === 'form_template_id'" class="fa fa-sort-alpha-desc mr-1"></i>
              <i *ngIf="sorting && sorting === '-form_template_id'" class="fa fa-sort-alpha-asc mr-1"></i>
            </a>
          </th>

          <th class="d-none d-sm-none d-md-table-cell">
            <a href="javascript:" (click)="setSorting('created_at')">
              Date
              <i *ngIf="sorting && sorting === 'created_at'" class="fa fa-sort-amount-desc mr-1"></i>
              <i *ngIf="sorting && sorting === '-created_at'" class="fa fa-sort-amount-asc mr-1"></i>
            </a>
          </th>

          <th class="text-right">Actions</th>
        </tr>

        <tr *ngFor="let form of forms">
          <td class="d-none d-sm-none d-md-table-cell">{{ form.assignment.data?.project.data?.title }}</td>
          <td>{{ form.assignment.data.number }}</td>
          <td class="d-none d-sm-none d-md-table-cell">{{ form.assignment.data?.client_job_number }}</td>
          <td>{{ form.assignment.data?.client.data?.name }}</td>
          <td>{{ form.site.data?.name }}</td>
          <td>{{ form.certificate_number }}</td>
          <td>{{ form.form_template_name }}</td>
          <td class="d-none d-sm-none d-md-table-cell">{{ form.created_at_formatted }}</td>
          <td class="text-right">
            <div class="btn-group" dropdown placement="bottom right">
              <button
                  dropdownToggle
                  type="button"
                  class="btn btn-secondary btn-sm"
                  aria-controls="dropdown-basic">
                <i class="fa fa-bars"></i>
              </button>
              <ul
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-right"
                  role="menu">
                <li role="menuitem" class="pointer">
                  <button
                      *ngIf="!form.published_at && form.completed_at"
                      (click)="publishForm(form)"
                      class="btn btn-sm btn-info btn-block">
                    <span *ngIf="!form.publishing"><i class="fa fa-rocket mr-1"></i> Publish</span>
                    <span *ngIf="form.publishing"><i class="fa fa-spin fa-spinner mr-1"></i> Publishing</span>
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                    *ngIf="form.published_at && form.completed_at"
                    (click)="onFormSubmit(form)"
                    class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-envelope mr-1"></i> Email
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      *ngIf="form.published_at && form.completed_at"
                      (click)="addRevision(form)"
                      class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-refresh mr-1"></i> Revision
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      *ngIf="form.published_at && form.completed_at"
                      (click)="unpublishForm(form)"
                      class="btn btn-sm btn-info btn-block">
                    <span *ngIf="!form.unpublishing"><i class="fa fa-rocket mr-1"></i> Unpublish</span>
                    <span *ngIf="form.unpublishing"><i class="fa fa-spin fa-spinner mr-1"></i> Unpublishing</span>
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      (click)="activeForm = null"
                      [routerLink]="['/forms/update', form.id]"
                      class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-edit mr-1"></i>
                    Edit
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      (click)="onUpdateJob(form.assignment.data)"
                      class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-edit mr-1"></i>
                    Edit Job
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      (click)="activeForm = null"
                      [routerLink]="['/forms/fill', form.id]"
                      class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-edit mr-1"></i>
                    Fill
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      (click)="activeForm = null"
                      [routerLink]="['/forms/view', form.id]"
                      class="btn btn-sm btn-primary btn-block">
                    <i class="fa fa-eye mr-1"></i>
                    View
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <a
                      *ngIf="form.completed_at"
                      [href]="base_server_url + '/forms/' + form.id + '/certificate'"
                      target="_blank"
                      class="btn btn-sm btn-success btn-block">
                    <i class="fa fa-certificate mr-1"></i>
                    Certificate
                  </a>
                </li>
                <li role="menuitem" class="pointer">
                  <a
                      *ngIf="form.completed_at"
                      [href]="base_server_url + '/forms/' + form.id + '/print'"
                      target="_blank"
                      class="btn btn-sm btn-success btn-block">
                    <i class="fa fa-print mr-1"></i>
                    Print
                  </a>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                      (click)="activeForm = null"
                      [routerLink]="['/forms/logs', form.id]"
                      class="btn btn-sm btn-info btn-block">
                    <i class="fa fa-list mr-1"></i>
                    Logs
                  </button>
                </li>
                <li role="menuitem" class="pointer">
                  <button
                          (click)="onFormDelete(form)"
                          class="btn btn-sm btn-danger btn-block">
                    <span *ngIf="!form.deleting"><i class="fa fa-trash mr-1"></i> Delete</span>
                    <span *ngIf="form.deleting"><i class="fa fa-spin fa-spinner mr-1"></i> Deleting</span>
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !loadingForms && !loadingInitial && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateFormsList($event)"></app-pagination>
    </div>
  </div>
</ion-content>
