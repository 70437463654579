<ion-content>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/home']" routerDirection="root">Home</a>
    </li>
  </ol>
  <app-loading *ngIf="gettingAllAuthenticatedUserJobs"></app-loading>

  <div *ngIf="!gettingAllAuthenticatedUserJobs" class="animated fadeIn text-right top-page-nav">
    <div class="btn-group">
      <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'all'}" (click)="switchFilter('all')">All</button>
      <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'open'}" (click)="switchFilter('open')">Open</button>
      <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'closed'}" (click)="switchFilter('closed')">Closed</button>
    </div>
  </div>

  <div *ngIf="creatingJob" class="alert alert-info">
    <i class="fa fa-spin fa-spinner mr-1"></i>
    Creating new Job! Please wait...
  </div>

  <div *ngIf="!gettingAllAuthenticatedUserJobs" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Jobs</strong> list
      <div class="float-right">
        <button (click)="onNewJob()" class="btn btn-sm btn-primary">
          New job
        </button>

        <button
          (click)="downloadJobs()"
          [disabled]="downloading"
          class="btn btn-sm btn-secondary">
          <i *ngIf="downloading" class="fa fa-spin fa-spinner mr-1"></i>
          <span *ngIf="!downloading">Download data</span>
          <span *ngIf="downloading">Downloading data</span>
        </button>
      </div>
    </div>

    <div *ngIf="jobs?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No jobs found.
    </div>

    <div *ngIf="downloading" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      Downloading needed data please wait.
    </div>

    <div class="table-responsive">
      <table *ngIf="jobs?.length > 0" class="table table-striped mb-0">
        <tr>
          <th>
            Project Reference
          </th>

          <th>
            <a href="javascript:" (click)="sortJobsByNumber()">
              Job Number
              <i *ngIf="jobsNumberSortingDirection === 1 && jobsSortedBy === 'number'" class="fa fa-sort-numeric-desc mr-1"></i>
              <i *ngIf="jobsNumberSortingDirection === -1 && jobsSortedBy === 'number'" class="fa fa-sort-numeric-asc mr-1"></i>
            </a>
          </th>

          <th>
            Client
          </th>

          <th>
            <a (click)="sortJobsByDate()" href="javascript:">
              Start Date
              <i *ngIf="jobsDateSortingDirection === 1 && jobsSortedBy === 'start_date'" class="fa fa-sort-numeric-desc mr-1"></i>
              <i *ngIf="jobsDateSortingDirection === -1 && jobsSortedBy === 'start_date'" class="fa fa-sort-numeric-asc mr-1"></i>
            </a>
          </th>

          <th class="text-right">
            Actions
          </th>
        </tr>

        <tr
            class="pointer"
            *ngFor="let job of jobs">
          <td [routerLink]="['/jobs/view/' + job.id]">{{ job.project?.data?.title }}</td>
          <td [routerLink]="['/jobs/view/' + job.id]">{{ job.number }}</td>
          <td [routerLink]="['/jobs/view/' + job.id]">{{ job.client?.data?.name }}</td>
          <td [routerLink]="['/jobs/view/' + job.id]">{{ job.start_date | convertDateTimeDatesFormat }}</td>
          <td class="text-right">
            <button
              (click)="onDelete(job)"
              tooltipPlacement="left"
              container="body"
              tooltip="Delete from device"
              class="btn btn-sm btn-danger">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllAuthenticatedUserJobs && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateJobsList($event)"></app-pagination>
    </div>
  </div>
</ion-content>
