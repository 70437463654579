import { BaseModel } from '../classes/query-builder.class';

export class Component extends BaseModel {
  public table = 'components';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    uuid: 'VARCHAR',
    hashed_id: 'VARCHAR',
    area_id: 'INTEGER',
    component_template_id: 'VARCHAR',
    text: 'LONGTEXT',
    accessible: 'INTEGER',
    no_access_reason: 'LONGTEXT',
    synced_at: 'DATETIME',
    updated_at: 'DATETIME',
  };
}
