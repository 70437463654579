import { BaseModel } from '../classes/query-builder.class';

export class Site extends BaseModel {
  public table = 'sites';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    client_id: 'VARCHAR',
    name: 'VARCHAR'
  };
}
