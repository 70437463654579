import { Component, Input, OnInit } from '@angular/core';
import { Form } from '../../../forms/models/form.model';
import { RevisionsService } from '../../services/revisions.service';
import { RevisionsResponse } from '../../responses/revision.response';
import { SnotifyService } from 'ng-snotify';
import { Revision } from '../../models/revision.model';
import { RevisionsCreateModalComponent } from '../revisions-create-modal/revisions-create-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RevisionsUpdateModalComponent } from '../revisions-update-modal/revisions-update-modal.component';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { Survey } from '../../../surveys/models/survey.model';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-revisions-index',
  templateUrl: './revisions-index.component.html',
  styleUrls: ['./revisions-index.component.css'],
})
export class RevisionsIndexComponent implements OnInit {
  @Input() form: Form;
  @Input() survey: Survey;
  public revisions: Revision[];
  public activeRevision: Revision;
  public gettingAllFormRevisions = false;
  private bsModalRef: BsModalRef;
  public base_server_url = environment.base_server_url;

  constructor(
    private revisionsService: RevisionsService,
    private snotifyService: SnotifyService,
    private modalService: BsModalService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.getAllFormRevisions();
  }

  getAllFormRevisions() {
    this.gettingAllFormRevisions = true;
    const limit = 0;
    const formId = this.form ? this.form.id : null;
    const surveyId = this.survey ? this.survey.id : null;

    this.revisionsService.getAllRevisions(limit, formId, surveyId).subscribe(
      (response: RevisionsResponse) => {
        this.revisions = response.data;
        this.gettingAllFormRevisions = false;
      },
      () => {
        this.gettingAllFormRevisions = false;
        this.snotifyService.error('Unable to load revisions. Please try again.', 'Error!');
      }
    );
  }

  onUpdateRevision(revision: Revision) {
    const initialState = {
      revision,
    };

    this.bsModalRef = this.modalService.show(RevisionsUpdateModalComponent, { initialState });
    this.bsModalRef.content.onModalHide.subscribe(() => {
      this.getAllFormRevisions();
    });
  }

  onRevisionDelete(revision: Revision) {
    this.snotifyService.confirm(`Revision "${revision.number}" is going to be erased.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteRevision(revision),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteRevision(revision: Revision) {
    revision.deleting = true;

    this.revisionsService.deleteRevision(revision).subscribe(
      () => {
        revision.deleting = false;
        this.getAllFormRevisions();
        AppHelper.chime();
      },
      () => {
        revision.deleting = false;
        this.snotifyService.error('Unable to delete Revision. Please try again!', 'Error!');
      }
    );
  }

  addRevision() {
    const initialState = {
      formId: null,
      surveyId: null,
    };

    if (this.form) {
      initialState.formId = this.form.id;
    }

    if (this.survey) {
      initialState.surveyId = this.survey.id;
    }

    this.bsModalRef = this.modalService.show(RevisionsCreateModalComponent, { initialState });
    this.bsModalRef.content.onModalHide.subscribe(() => {
      this.getAllFormRevisions();
    });
  }
}
