import { Component, OnInit } from '@angular/core';
import { FormsService } from '../../../forms/services/forms.service';
import { FormResponse, FormsResponse } from '../../../forms/responses/form.response';
import { Form } from '../../../forms/models/form.model';
import { FormQueryBuilder } from '../../../shared/classes/form.query-builder.class';
import { HttpClient } from '@angular/common/http';
import { SyncService } from '../../../shared/services/sync.service';
import { SnotifyService } from 'ng-snotify';
import { FormSectionQueryBuilder } from '../../../shared/classes/form_section.query-builder.class';
import { SampleQueryBuilder } from '../../../shared/classes/sample.query-builder.class';
import { FormSectionsService } from '../../../forms/services/form-sections.service';
import { SamplesService } from '../../../forms/services/samples.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  public gettingAllForms = false;
  public forms: Form[] = [];
  public counter = 0;
  public max = 0;
  public syncingFormId: any;

  constructor(
    private formsService: FormsService,
    private formSectionsService: FormSectionsService,
    private samplesService: SamplesService,
    private http: HttpClient,
    private syncService: SyncService,
    private snotifyService: SnotifyService
  ) {
    this.getAllUnSyncedForms();
  }

  ngOnInit() {}

  getAllUnSyncedForms() {
    this.gettingAllForms = true;

    this.formsService.getAllUnSyncedForms().subscribe((response: FormsResponse) => {
      this.forms = response.data;
      this.gettingAllForms = false;
    });
  }

  async syncForm(form: Form) {
    this.syncingFormId = form.id;

    const forms_QueryBuilder = new FormQueryBuilder();
    const formResponse: FormResponse = await forms_QueryBuilder
      .where('id', '=', form.id)
      .first()
      .toPromise();
    this.max = formResponse.data.form_sections.data.length + formResponse.data.samples.data.length;

    await this.formsService.syncForm(formResponse.data).subscribe(
      async (syncedFormResponse: any) => {
        form.hashed_id = syncedFormResponse.data.form_id;
        const forms_QueryBuilder = new FormQueryBuilder();
        forms_QueryBuilder.update(SyncService.trimForeignAttributes(forms_QueryBuilder, form));

        try {
          await this.syncFormSections(formResponse, syncedFormResponse.data.form_id);
          await this.syncSamples(formResponse, syncedFormResponse.data.form_id);

          this.syncingFormId = null;
          this.counter = 0;

          this.syncService.markFormAsSynced(form, syncedFormResponse, formResponse);
        } catch (error) {
          this.syncingFormId = null;
          this.snotifyService.error('Unable to sync resources', 'Error!');
        }
      },
      () => {
        this.syncingFormId = null;
        this.snotifyService.error('Unable to sync resources', 'Error!');
      }
    );
  }

  async syncFormSections(formResponse, form_id) {
    for (const form_section of formResponse.data.form_sections.data) {
      const payload = {
        form_id,
        form_section
      };

      await this.formSectionsService
        .syncFormSection(payload)
        .toPromise()
        .then((syncedFormSectionResponse: any) => {
          this.counter++;

          const formSection_QueryBuilder = new FormSectionQueryBuilder();
          form_section.hashed_id = syncedFormSectionResponse.data.form_section_id;
          formSection_QueryBuilder.update(SyncService.trimForeignAttributes(formSection_QueryBuilder, form_section));
        })
        .catch(error => {
          throw error;
        });
    }
  }

  async syncSamples(formResponse, form_id) {
    for (const sample of formResponse.data.samples.data) {
      const payload = {
        form_id,
        sample
      };

      await this.samplesService
        .syncSample(payload)
        .toPromise()
        .then((syncedSampleResponse: any) => {
          this.counter++;

          const sample_QueryBuilder = new SampleQueryBuilder();
          sample.hashed_id = syncedSampleResponse.data.sample_id;
          sample_QueryBuilder.update(SyncService.trimForeignAttributes(sample_QueryBuilder, sample));
        })
        .catch(error => {
          throw error;
        });
    }
  }

  calculateProgressBarWidth(form: Form) {
    return form.id === this.syncingFormId
      ? (this.counter / this.max) * 100
      : 0;
  }
}
