import { Component, OnDestroy, OnInit } from '@angular/core';
import { Form } from '../../models/form.model';
import { FormsService } from '../../services/forms.service';
import { FormsResponse } from '../../responses/form.response';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { PTRComponent } from 'ngx-weui';
import { Links, Meta } from '../../../shared/models/pagination.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Job } from '../../../jobs/models/job.model';
import { JobsUpdateComponent } from '../../../jobs/components/jobs-update/jobs-update.component';
import { Router } from '@angular/router';
import { RevisionsCreateModalComponent } from '../../../revisions/components/revisions-create-modal/revisions-create-modal.component';
import { FormsSubmitModalComponent } from '../../../shared/components/forms-submit-modal/forms-submit-modal.component';
import { RevisionsService } from '../../../revisions/services/revisions.service';
import { finalize } from 'rxjs/operators';
import { Revision } from '../../../revisions/models/revision.model';
import { RevisionsResponse } from '../../../revisions/responses/revision.response';

@Component({
  selector: 'app-forms-index',
  templateUrl: './forms-index.component.html',
  styleUrls: ['./forms-index.component.css'],
})
export class FormsIndexComponent implements OnInit, OnDestroy {
  public forms: Form[];
  public loadingInitial = true;
  public loadingForms = false;
  public loadingRevisions = false;
  public meta: Meta;
  public links: Links;
  public base_server_url = environment.base_server_url;
  public subscriptions: Subscription = new Subscription();
  public filter = 'all';
  public activeForm: Form;
  public revisions: Revision[] = [];
  public sorting = '-created_at';
  public search: string;
  private bsModalRef: BsModalRef;
  public page = 1;

  constructor(
    private modalService: BsModalService,
    private formsService: FormsService,
    private snotifyService: SnotifyService,
    private busService: BusService,
    private router: Router,
    private revisionsService: RevisionsService,
  ) {
  }

  ngOnInit() {
    this.getAllForms();
    this.getAllRevisions();
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.loadingForms = event.data;
      }),
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateFormsList(event.data);
      }),
    );
  }

  setSorting(sortBy: string) {
    if (this.sorting && this.sorting === sortBy) {
      this.sorting = `-${sortBy}`;
    } else if (this.sorting && this.sorting === `-${sortBy}`) {
      this.sorting = null;
    } else {
      this.sorting = sortBy;
    }

    this.getAllForms();
  }

  getAllForms() {
    this.loadingForms = true;
    const includes = ['user', 'site', 'assignment', 'assignment.client', 'assignment.project', 'assignment.users', 'assignment.client'];
    const appends = null;

    this.formsService.getAllForms(includes, this.filter, this.sorting, appends, this.search, this.page).subscribe(
      (response: FormsResponse) => {
        this.loadingForms = false;
        this.loadingInitial = false;
        this.updateFormsList(response);
      },
      () => {
        this.loadingForms = false;
        this.loadingInitial = false;
        this.snotifyService.error('Unable to load forms. Please try again.', 'Error!');
      },
    );
  }

  getAllRevisions() {
    this.loadingRevisions = true;
    const includes = ['form'];

    this.revisionsService
      .erroneousForms(includes)
      .pipe(
        finalize(() => (this.loadingRevisions = false))
      )
      .subscribe(
      (response: RevisionsResponse) => {
        this.revisions = response.data;
      },
      () => {
        this.snotifyService.error('Unable to load form revisions. Please try again.', 'Error!');
      },
    );
  }

  resetSearch() {
    this.search = null;

    this.getAllForms();
  }

  onRefresh(ptr: PTRComponent) {
    this.getAllForms();
    ptr.setFinished();
  }

  updateFormsList(response: FormsResponse) {
    this.forms = response.data;
    this.meta = response.meta;
    this.links = response.links;
    this.page = response.meta.current_page;
  }

  switchFilter(filter) {
    this.filter = filter;
    this.getAllForms();
  }

  onFormDelete(form: Form) {
    this.snotifyService.confirm('The selected form and all recorded answers will be erased.', 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteForm(form),
          bold: false,
        },
        {
          text: 'No',
          action: () => {
          },
        },
      ],
    });
  }

  deleteForm(form: Form) {
    form.deleting = true;

    this.formsService.deleteForm(form).subscribe(
      () => {
        form.deleting = false;
        this.snotifyService.success('The selected form has been successfully deleted.', 'Success!');
        this.getAllForms();
      },
      () => {
        form.deleting = false;
        this.snotifyService.error('Unable to delete form. Please try again.', 'Error!');
      },
    );
  }

  publishForm(form: Form) {
    form.publishing = true;
    const payload = {
      id: form.id,
      published_at: moment().format('YYYY/MM/DD'),
    };

    this.formsService.publishForm(payload).subscribe(
      () => {
        form.publishing = false;
        this.snotifyService.success('The selected form has been successfully published.', 'Success!');
        this.getAllForms();
      },
      () => {
        form.publishing = false;
        this.snotifyService.error('Unable to publish form. Please try again.', 'Error!');
      },
    );
  }

  unpublishForm(form: Form) {
    form.unpublishing = true;

    this.formsService.unpublishForm(form).subscribe(
      () => {
        form.unpublishing = false;
        this.snotifyService.success('The selected form has been successfully unpublished.', 'Success!');
        this.getAllForms();
      },
      () => {
        form.unpublishing = false;
        this.snotifyService.error('Unable to unpublish form. Please try again.', 'Error!');
      },
    );
  }

  onUpdateJob(job: Job) {
    const initialState = {
      job,
      redirect: false
    };

    this.bsModalRef = this.modalService.show(JobsUpdateComponent, { initialState });
    this.bsModalRef.content.onModalHide.subscribe(() => {
      this.getAllForms();
    });
  }

  addRevision(form: Form) {
    const initialState = {
      formId: form.id,
    };

    this.bsModalRef = this.modalService.show(RevisionsCreateModalComponent, { initialState });
    this.bsModalRef.content.onModalHide.subscribe(() => {
      return this.router.navigate([`/forms/view/${form.id}`]);
    });
  }

  onFormSubmit(form: Form) {
    const initialState = {
      form,
    };

    this.modalService.show(FormsSubmitModalComponent, { initialState });
  }
}
