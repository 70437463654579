<div class="form-group">
  <label for="number">Job</label>

  <div class="input-group">
    <input
      (change)="findJob()"
      [(ngModel)]="jobNumber"
      name="number"
      id="number"
      placeholder="Search for job..."
      type="text"
      class="form-control">

    <div class="input-group-append">
      <button
        [disabled]="searching"
        (click)="findJob()"
        class="btn btn-primary">
        <i *ngIf="!searching" class="fa fa-search"></i>
        <i *ngIf="searching" class="fa fa-spin fa-spinner"></i>
      </button>
    </div>
  </div>

  <ul *ngIf="jobs.length > 0" class="list-group mt-1">
    <li
      *ngFor="let job of jobs"
      (click)="selectJob(job)"
      class="list-group-item pointer list-group-item-action">
      {{ job.number }}
    </li>
  </ul>
</div>
