import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { Parameter } from '../../shared/models/parameter.model';
import {
  NextSurveySampleResponse,
  SurveySampleResponse,
  SurveySamplesResponse
} from '../responses/survey-sample.response';
import { SurveySample } from '../models/survey-sample.model';
import { environment } from '../../../../environments/environment';
import { SurveySampleQueryBuilder } from '../../shared/classes/survey_sample.query-builder.class';
import * as moment from 'moment';
import { AppHelper } from '../../shared/classes/app.helper';
import { ActionQueryBuilder } from '../../shared/classes/action.query-builder.class';

@Injectable()
export class SurveySamplesService {
  private resource = 'survey_samples';
  private queryBuilder: SurveySampleQueryBuilder;

  constructor(private http: HttpClient) {
    if (environment.cordova) {
      this.queryBuilder = new SurveySampleQueryBuilder();
    }
  }

  /**
   * Get all survey Samples.
   */
  getAllSurveySamples(
    limit: number = 15,
    includes: string[] = null,
    sorting: string = null,
    appends: string[] = null,
    parameters: Parameter[] = [],
  ): Observable<SurveySamplesResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    parameters.forEach(parameter => {
      if (parameter.value) {
        params = params.set(`filter[${parameter.field}]`, `${parameter.value}`);
      }
    });

    return this.http.get<SurveySamplesResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find survey Sample by id.
   */
  findSurveySampleById(surveySampleId: string, includes: string[] = null, appends: string[] = null): Observable<SurveySampleResponse> {
    if (environment.cordova) {
      this.queryBuilder = new SurveySampleQueryBuilder();
    }

    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    return !environment.cordova
      ? this.http.get<SurveySampleResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveySampleId}`, { params })
      : this.queryBuilder.findWithIncludes(+surveySampleId, includes);
  }

  /**
   * Create new user survey Sample.
   */
  createSurveySample(surveySample: SurveySample): Observable<SurveySampleResponse> {
    if (environment.cordova && !surveySample.uuid) {
      surveySample.uuid = AppHelper.uuid();
    }

    if (environment.cordova) {
      this.queryBuilder = new SurveySampleQueryBuilder();
    }

    return !environment.cordova
      ? this.http.post<SurveySampleResponse>(`${ApiService.ApiUrl}/${this.resource}`, surveySample)
      : this.queryBuilder.create(surveySample);
  }

  /**
   * Update user survey Sample.
   */
  updateSurveySample(surveySample: SurveySample): Observable<SurveySampleResponse> {
    if (environment.cordova) {
      this.queryBuilder = new SurveySampleQueryBuilder();
      surveySample.updated_at = moment().format('YYYY/MM/DD HH:mm:ss');
    }

    return !environment.cordova
      ? this.http.patch<SurveySampleResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveySample.id}`, surveySample)
      : this.queryBuilder.update(surveySample);
  }

  /**
   * Delete user survey Sample.
   */
  deleteSurveySample(surveySample: SurveySample, createAction = false): Observable<any> {
    if (environment.cordova) {
      this.queryBuilder = new SurveySampleQueryBuilder();

      if (createAction) {
        const actionQueryBuilder = new ActionQueryBuilder();
        actionQueryBuilder.create({
          type: 'delete',
          uuid: surveySample.uuid,
          resource: 'survey_samples',
        });
      }
    }

    return !environment.cordova
      ? this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${surveySample.id}`)
      : this.queryBuilder.where('id', '=', surveySample.id).delete(surveySample);
  }

  /**
   * Sync survey sample.
   */
  syncSurveySample(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }

  getNext(surveyId: string, surveySampleId: string): Observable<NextSurveySampleResponse> {
    return this.http.get<NextSurveySampleResponse>(`${ApiService.ApiUrl}/surveys/${surveyId}/scores/next-sample?survey_sample_id=${surveySampleId}`);
  }
}
