import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { BuildingResponse, BuildingsResponse } from '../responses/building.response';
import { Building } from '../models/building.model';
import { Parameter } from '../../shared/models/parameter.model';
import { BuildingQueryBuilder } from '../../shared/classes/building.query-builder.class';
import { environment } from '../../../../environments/environment';
import { CascadeService } from '../../shared/services/cascade.service';
import * as moment from 'moment';
import { AppHelper } from '../../shared/classes/app.helper';
import { ActionQueryBuilder } from '../../shared/classes/action.query-builder.class';

@Injectable()
export class BuildingsService {
  private resource = 'buildings';
  private queryBuilder: BuildingQueryBuilder;

  constructor(private http: HttpClient, private cascadeService: CascadeService) {
    if (environment.cordova) {
      this.queryBuilder = new BuildingQueryBuilder();
    }
  }

  /**
   * Get all buildings.
   */
  getAllBuildings(
    limit: number = 15,
    includes: string[] = null,
    sorting: string = null,
    appends: string[] = null,
    parameters: Parameter[] = [],
  ): Observable<BuildingsResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    parameters.forEach(parameter => {
      if (parameter.value) {
        params = params.set(`filter[${parameter.field}]`, `${parameter.value}`);
      }
    });

    return this.http.get<BuildingsResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find building by id.
   */
  findBuildingById(buildingId: string, includes: string[] = null): Observable<BuildingResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.get<BuildingResponse>(`${ApiService.ApiUrl}/${this.resource}/${buildingId}`, { params })
      : this.queryBuilder.findWithIncludes(+buildingId, includes);
  }

  /**
   * Create new  building.
   */
  createBuilding(building: Building): Observable<BuildingResponse> {
    if (environment.cordova && !building.uuid) {
      building.uuid = AppHelper.uuid();
    }

    return !environment.cordova
      ? this.http.post<BuildingResponse>(`${ApiService.ApiUrl}/${this.resource}`, building)
      : this.queryBuilder.create(building);
  }

  /**
   * Update building.
   */
  updateBuilding(building: Building): Observable<BuildingResponse> {
    if (environment.cordova) {
      building.updated_at = moment().format('YYYY/MM/DD HH:mm:ss');
    }

    return !environment.cordova
      ? this.http.patch<BuildingResponse>(`${ApiService.ApiUrl}/${this.resource}/${building.id}`, building)
      : this.queryBuilder.update(building);
  }

  /**
   * Delete building.
   */
  deleteBuilding(building: Building, createAction = false): Observable<any> {
    if (environment.cordova) {
      this.queryBuilder = new BuildingQueryBuilder();
      this.cascadeService.building(building);

      if (createAction) {
        const actionQueryBuilder = new ActionQueryBuilder();
        actionQueryBuilder.create({
          type: 'delete',
          uuid: building.uuid,
          resource: 'buildings',
        });
      }
    }

    return !environment.cordova
      ? this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${building.id}`)
      : this.queryBuilder.where('id', '=', building.id).delete(building);
  }

  /**
   * Sync building.
   */
  syncBuilding(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
