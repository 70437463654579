import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormAnswersResponse } from '../responses/form-answer.response';
import { FormAnswerQueryBuilder } from '../../shared/classes/form_answer.query-builder.class';
import { ApiService } from '../../shared/services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FormAnswersService {
  private resource = 'form_answers';
  private queryBuilder: FormAnswerQueryBuilder;

  constructor(private http: HttpClient) {
    this.queryBuilder = new FormAnswerQueryBuilder();
  }

  updateTimestamp(formAnswerId: string, timestamp: string): Observable<FormAnswersResponse> {
    return this.http.post<FormAnswersResponse>(`${ApiService.ApiUrl}/${this.resource}/${formAnswerId}/timestamp`, { created_at: timestamp });
  }

  /**
   * Create new form.
   * @param form_answers
   * @returns {Observable<Object>}
   */
  createMultipleFormAnswers(form_answers): Observable<FormAnswersResponse> {
    return !environment.cordova
      ? this.http.post<FormAnswersResponse>(`${ApiService.ApiUrl}/${this.resource}/multiple`, form_answers)
      : this.queryBuilder.createMultiple(form_answers);
  }
}
