import { Component, OnDestroy, OnInit } from '@angular/core';
import { Client } from '../../models/client.model';
import { Site } from '../../../shared/models/site.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from '../../services/clients.service';
import { SnotifyService } from 'ng-snotify';
import { ClientResponse } from '../../responses/client.response';
import { SitesService } from '../../../sites/services/sites.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SitesCreateModalComponent } from '../../../shared/components/sites-create-modal/sites-create-modal.component';
import { SitesUpdateModalComponent } from '../../../shared/components/sites-update-modal/sites-update-modal.component';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-sites-index',
  templateUrl: './sites-index.component.html',
  styleUrls: ['./sites-index.component.css'],
})
export class SitesIndexComponent implements OnInit, OnDestroy {
  public client: Client;
  public clientId: string;
  public sites: Site[];
  public activeSite: Site;
  public findingClientById = false;
  public subscriptions = new Subscription();
  private bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private snotifyService: SnotifyService,
    private sitesService: SitesService
  ) {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        if (params.client_id) {
          this.clientId = params.client_id;
          this.findClientById();
        }
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  findClientById() {
    this.findingClientById = true;
    const includes = ['sites'];

    this.clientsService.findClientById(this.clientId, includes).subscribe(
      (response: ClientResponse) => {
        this.findingClientById = false;
        this.client = response.data;
        this.sites = this.client.sites.data;
      },
      error => {
        this.findingClientById = false;
        this.snotifyService.error(
          'Unable to load client. Please try again.',
          'Error!'
        );
      }
    );
  }

  onSiteDelete(site: Site) {
    this.snotifyService.confirm(
      `Site "${site.name}" is going to be erased.`,
      'Are you sure?',
      {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            action: () => this.deleteSite(site),
            bold: false
          },
          { text: 'No', action: () => {} }
        ]
      }
    );
  }

  deleteSite(site: Site) {
    site.deleting = true;

    this.sitesService.deleteSite(site).subscribe(
      response => {
        site.deleting = false;
        this.snotifyService.success(
          `Site "${site.name}" has been successfully deleted.`,
          'Success!'
        );
        this.sites = this.sites.filter(entry => entry.id !== site.id);
        AppHelper.chime();
      },
      error => {
        site.deleting = false;
        this.snotifyService.error(
          `Unable to delete site "${site.name}". Please try again.`,
          'Error!'
        );
      }
    );
  }

  onSiteCreate() {
    const initialState = {
      clientId: this.clientId,
    };

    this.bsModalRef = this.modalService.show(SitesCreateModalComponent, { initialState });
    this.subscriptions.add(
      this.bsModalRef.content.onHide.subscribe(site => this.sites.push(site))
    );
  }

  onSiteUpdate(site: Site) {
    const initialState = {
      site,
    };

    this.bsModalRef = this.modalService.show(SitesUpdateModalComponent, { initialState });
    this.subscriptions.add(
      this.bsModalRef.content.onHide.subscribe(updatedSite => {
        this.sites = this.sites.map(entry => {
          return updatedSite.id === entry.id
            ? updatedSite
            : entry;
        });
      })
    );
  }
}
