import { Component, OnInit } from '@angular/core';
import { Job } from '../../../jobs/models/job.model';
import { Survey } from '../../../surveys/models/survey.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveyTypeEnum } from '../../enums/survey-type.enum';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { SurveysService } from '../../../surveys/services/surveys.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { SurveyResponse } from '../../../surveys/responses/survey.response';

@Component({
  selector: 'app-surveys-copy-modal',
  templateUrl: './surveys-copy-modal.component.html',
  styleUrls: ['./surveys-copy-modal.component.css'],
})
export class SurveysCopyModalComponent implements OnInit {
  public job: Job;
  public survey: Survey;
  public formGroup: FormGroup;
  public copyingSurvey = false;
  public surveyTypeEnum = SurveyTypeEnum;
  public onHide = new Subject();
  public optionValueField: string;
  public environment = environment;
  public clientId: string|number;

  constructor(
    public bsModalRef: BsModalRef,
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
    private surveysService: SurveysService,
    public authService: AuthService,
  ) {
    this.optionValueField = environment.cordova ? 'hashed_id' : 'id';
  }

  ngOnInit() {
    this.initForm();
    this.clientId = this.job.client_id;
  }

  initForm() {
    const userIds = [];

    this.survey.users.data.forEach(user => userIds.push(user.id));

    this.formGroup = this.formBuilder.group({
      survey_id: [this.survey.id, [Validators.required]],
      assignment_id: [this.survey.assignment_id, [Validators.required]],
      survey_users: [userIds, [Validators.required]],
      survey_type: [this.survey.survey_type],
    });
  }

  onSubmit() {
    this.copyingSurvey = true;
    const payload = this.formGroup.getRawValue();

    this.surveysService.copySurvey(payload).subscribe(
      (response: SurveyResponse) => {
        this.copyingSurvey = false;
        this.bsModalRef.hide();
        this.onHide.next(response.data);
      },
      () => {
        this.copyingSurvey = false;
        this.snotifyService.error('Unable to copy Survey. Please try again.', 'Error!');
      },
    );
  }

  jobSelected(job: Job) {
    this.job = job;
    this.formGroup.get('assignment_id').setValue(job.id);
  }
}
