import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from '../../models/form.model';
import { SnotifyService } from 'ng-snotify';
import { SamplesService } from '../../services/samples.service';
import { Sample } from '../../models/sample.model';
import { SampleResponse } from '../../responses/sample.response';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormAnswer } from '../../models/form-answer.model';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-forms-samples',
  templateUrl: './forms-samples.component.html',
  styleUrls: ['./forms-samples.component.css']
})
export class FormsSamplesComponent implements OnInit {
  @Input() form: Form;
  @Output() jumpToSection: EventEmitter<{js_rule: string, sample: Sample}> = new EventEmitter();
  public creatingSample = false;
  public scrollToTop = FormHelpers.scrollToTop;

  constructor(private snotifyService: SnotifyService, private samplesService: SamplesService) { }

  ngOnInit() {
    this.scrollToTop();
  }

  hop(js_rule: string, sample: Sample = null) {
    this.jumpToSection.emit({js_rule: `${this.form.form_template_slug}_${js_rule}`, sample});
  }

  onAddSample() {
    this.creatingSample = true;
    const includes = [
      'form_sections',
      'form_sections.form_template_section',
      'form_sections.form_template_section.form_template_questions',
      'form_sections.form_template_section.form_template_questions.form_template_question_options',
      'form_sections.form_answers',
    ];

    this.samplesService.createSample(this.form, includes).subscribe(
      (response: SampleResponse) => {
        this.creatingSample = false;
        this.form.samples.data.push(response.data);
        AppHelper.chime();
      },
      () => {
        this.creatingSample = false;
        this.snotifyService.error('Unable to create Sample. Please try again!', 'Error!');
      }
    );
  }

  onSampleDelete(sample: Sample) {
    this.snotifyService.confirm(`Sample is going to be deleted.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteSample(sample),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteSample(sample: Sample) {
    sample.deleting = true;

    this.samplesService.deleteSample(sample).subscribe(
      () => {
        sample.deleting = false;
        this.form.samples.data = this.form.samples.data.filter((item: Sample) => item.id !== sample.id);
        AppHelper.chime();
      },
      () => {
        sample.deleting = false;
        this.snotifyService.error('Unable to delete Sample. Please try again!', 'Error!');
      }
    );
  }

  allSamplesDone() {
    if (!this.form.samples || this.form.samples.data.length === 0) {
      return true;
    }

    let done = true;

    this.form.samples.data.forEach((sample: Sample) => {
      if (!sample.form_sections || !sample.form_sections.data || sample.form_sections.data.length !== 3) {
        done = false;
      } else if (sample.form_sections.data.length === 3) {
        done = this.isLastSectionDone(sample);
      }
    });

    return done;
  }

  isLastSectionDone(sample: Sample) {
    let done = true;
    const lastSection = sample.form_sections.data[sample.form_sections.data.length - 1];

    const occluded: FormAnswer = FormHelpers.findFormSectionQuestionByJsId(lastSection, 'filter_occluded');

    if (+occluded.value == 1) {
      return true;
    }

    lastSection.form_answers.data.forEach((formAnswer: FormAnswer) => {
      if (formAnswer.value === null && formAnswer.comment === null) {
        done = false;
      }
    });

    return done;
  }

}
