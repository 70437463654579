import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../shared/guards/role.guard';
import { FormsIndexComponent } from './components/forms-index/forms-index.component';
import { FormsViewComponent } from './components/forms-view/forms-view.component';
import { FormsUpdateComponent } from './components/forms-update/forms-update.component';
import { FormsAuditLogComponent } from './components/forms-audit-log/forms-audit-log.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'index'
  },
  {
    path: 'index',
    component: FormsIndexComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'reviewer', 'view_all', 'admin_assistant']
    }
  },
  {
    path: 'view/:form_id',
    component: FormsViewComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'reviewer', 'view_all', 'admin_assistant']
    }
  },
  {
    path: 'update/:form_id',
    component: FormsUpdateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'reviewer', 'view_all', 'admin_assistant']
    }
  },
  {
    path: 'logs/:form_id',
    component: FormsAuditLogComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'reviewer', 'view_all', 'admin_assistant']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HFSFormsRoutingModule {}
