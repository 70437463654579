import { QueryBuilder } from './query-builder.class';
import { SurveySample } from '../entities/survey_sample.entity';
import { SurveySample as SurveySampleModel } from '../../survey-samples/models/survey-sample.model';
import { from, Observable } from 'rxjs';
import { Survey } from '../../surveys/models/survey.model';
import { ComponentModel } from '../../components/models/component.model';
import { Area } from '../../areas/models/area.model';
import { AppHelper } from './app.helper';
import { JsonSerializer } from './serializer.class';

/**
 * Client Entity Query Builder.
 */
export class SurveySampleQueryBuilder extends QueryBuilder {
  constructor() {
    super(new SurveySample());
  }

  findWithIncludes(id: number, includes: string[] = []): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const surveySample: SurveySampleModel = await this.find(this._table, id);

        if (surveySample && includes.includes('survey')) {
          const survey: Survey = await this.find('surveys', surveySample.survey_id);

          if (survey) {
            const surveyIncludes = AppHelper.getSubIncludes(includes, 'survey.');

            if (surveyIncludes.includes('survey_samples')) {
              let surveySamples: SurveySampleModel[] = await this.loadItemRelationship('survey_samples', 'survey_id', survey.id);

              if (surveySamples) {
                const surveySamplesIncludes = AppHelper.getSubIncludes(surveyIncludes, 'survey_samples.');

                if (surveySamplesIncludes.includes('component')) {
                  const components: ComponentModel[] = await this.loadCollectionRelationships('components', 'id', 'component_id', surveySamples);
                  const areas: Area[] = await this.loadCollectionRelationships('areas', 'id', 'area_id', components);

                  surveySamples = surveySamples.map(entry => {
                    const childComponent: ComponentModel = components.find(component => +entry.component_id === +component.id);

                    if (childComponent) {
                      const componentIncludes = AppHelper.getSubIncludes(surveySamplesIncludes, 'component.');

                      if (componentIncludes.includes('area')) {
                        const childArea: Area = areas.find(area => +childComponent.area_id === +area.id);

                        if (childArea) {
                          childComponent.area = {
                            data: childArea,
                          };
                        }
                      }

                      entry.component = {
                        data: childComponent,
                      };
                    }

                    return entry;
                  });
                }

                survey.survey_samples = {
                  data: surveySamples
                };
              }
            }

            surveySample.survey = {
              data: survey
            };
          }
        }

        surveySample.asbestos_type = surveySample.asbestos_type
          ? surveySample.asbestos_type.toString().split(',').map(value => +value)
          : [];
        surveySample.product_type = surveySample.product_type
          ? surveySample.product_type.toString().split(',').map(value => +value)
          : [];
        surveySample.additional_recommendations = surveySample.additional_recommendations
          ? surveySample.additional_recommendations.toString().split(',').map(value => +value)
          : [];

        resolve(JsonSerializer.item(surveySample));
      })
    );
  }

  delete(surveySample: SurveySampleModel) {
    const query = `DELETE FROM ${this._table} ${this._buildWhereQuery()}`;

    return from(
      new Promise((resolve, reject) => {
        this._query(query, []).then(
          (response: any) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      })
    );
  }
}
