<ion-content>
  <app-loading *ngIf="gettingAllJobs"></app-loading>

  <div *ngIf="!gettingAllJobs" class="row mt-15rem">
    <div class="col">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>

          <input
              (change)="getAllJobs()"
              [(ngModel)]="search"
              type="text"
              class="form-control"
              placeholder="Search..."
              title="Search">

          <div
              *ngIf="search"
              class="input-group-append">
            <button
                (click)="resetSearch()"
                class="btn btn-primary">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="animated fadeIn text-right top-page-nav">
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'all'}" (click)="switchFilter('all')">All</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'open'}" (click)="switchFilter('open')">Open</button>
          <button type="button" class="btn btn-outline-secondary" [ngClass]="{'active btn-secondary': filter == 'closed'}" (click)="switchFilter('closed')">Closed</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!gettingAllJobs" class="animated fadeIn card card-accent-primary">
    <div class="card-header">
      <strong>Jobs</strong> list

      <div class="float-right">
        <button (click)="onNewJob()" class="btn btn-sm btn-primary">
          New job
        </button>
      </div>
    </div>

    <div *ngIf="jobs?.length === 0" class="alert alert-info mb-0">
      <i class="fa fa-info mr-1"></i>
      No jobs found.
    </div>

    <div style="overflow: inherit !important;" class="table-responsive">
      <table *ngIf="jobs?.length > 0" class="table table-striped mb-0">
        <thead>
          <tr>
            <th class="d-none d-sm-none d-md-table-cell">Project Reference</th>
            <th class="d-none d-sm-none d-md-table-cell">Job Number</th>
            <th class="d-none d-sm-none d-md-table-cell">Client Job Number</th>
            <th class="d-none d-sm-none d-md-table-cell">Client</th>
            <th>Address</th>
            <th>Date</th>
            <th>Status</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let job of jobs; let i = index;">
            <td class="d-none d-sm-none d-md-table-cell">{{ job.project?.data?.title }}</td>
            <td class="d-none d-sm-none d-md-table-cell">{{ job.number }}</td>
            <td class="d-none d-sm-none d-md-table-cell">{{ job.client_job_number }}</td>
            <td class="d-none d-sm-none d-md-table-cell">{{ job.client?.data?.name }}</td>
            <td>{{ job.address }}</td>
            <td>{{ job.created_at_formatted }}</td>
            <td>
              <div style="display: inline-block">
                <label class="switch switch-text switch-info-outline-alt mb-0">
                  <input
                      (click)="toggleOpenStatus(job)"
                      [checked]="+job.open === 1 || job.open === true"
                      class="switch-input" type="checkbox" name="{{ job.id }}">
                  <span class="switch-label" data-off="Off" data-on="On"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </td>
            <td class="text-right">
              <div class="btn-group" dropdown placement="bottom right">
                <button
                    id="button-basic"
                    dropdownToggle
                    type="button"
                    class="btn btn-secondary btn-sm"
                    aria-controls="dropdown-basic">
                  <i class="fa fa-bars"></i>
                </button>
                <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu">
                  <li role="menuitem" class="pointer">
                    <button
                        [routerLink]="['/jobs/info', job.id]"
                        class="btn btn-sm btn-info btn-block">
                      <i class="fa fa-info-circle mr-1"></i>
                      Info
                    </button>
                  </li>
                  <li role="menuitem" class="pointer">
                    <button
                        [routerLink]="['/jobs/view', job.id]"
                        class="btn btn-sm btn-primary btn-block">
                      <i class="fa fa-eye mr-1"></i>
                      View
                    </button>
                  </li>
                  <li role="menuitem" class="pointer">
                    <button
                        (click)="onUpdateJob(job)"
                        class="btn btn-sm btn-primary btn-block">
                      <i class="fa fa-edit mr-1"></i>
                      Edit
                    </button>
                  </li>
                  <li role="menuitem" class="pointer">
                    <button
                        (click)="onJobDelete(job)"
                        class="btn btn-sm btn-danger btn-block">
                      <i class="fa fa-trash mr-1"></i>
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="meta && !gettingAllJobs && meta.last_page > 1" class="card-footer">
      <app-pagination
          [meta]="meta"
          [links]="links"
          (updated)="updateJobsList($event)"></app-pagination>
    </div>
  </div>
</ion-content>
