import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormConfig } from '../../classes/form-config.class';
import { BusService } from '../../../shared/services/bus.service';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-time',
  templateUrl: './input-type-time.component.html',
  styleUrls: ['./input-type-time.component.css']
})
export class InputTypeTimeComponent extends InputTypeBaseFieldComponent implements OnInit {
  public timeMask = FormHelpers.timeMask;
  public setCurrentTimeToQuestion = FormHelpers.setCurrentTimeToQuestion;

  constructor(private busService: BusService) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  valueChange() {
    this.fieldValidation(this.question);

    if (FormConfig.calculatable.includes(this.question.number)) {
      const relations: string[] = FormConfig.calculatableRelations[this.question.number];

      if (!relations) {
        return;
      }

      relations.forEach(relationNumber => this.busService.dispatch({ type: relationNumber }));
    }
  }
}
