import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DocumentTypeEnum } from '../../enums/document-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploaderOptions, UploadFile, UploadInput, humanizeBytes, UploadOutput } from 'ngx-uploader';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-documents-create',
  templateUrl: './documents-create.component.html',
  styleUrls: ['./documents-create.component.css']
})
export class DocumentsCreateComponent implements OnInit {
  public document_types = DocumentTypeEnum;
  public documentFormGroup: FormGroup;
  public options: UploaderOptions;
  public files: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes;
  public creatingDocument = false;
  public documentable_id: string;
  public documentable_type: string;

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private modalService: BsModalService) { }

  ngOnInit() {
    this.initDocumentForm();
    this.initNgxUploader();
  }

  initDocumentForm() {
    this.documentFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [DocumentTypeEnum.Document],
      file: ['', [Validators.required]],
      documentable_id: [this.documentable_id],
      documentable_type: [this.documentable_type],
      public: [true]
    });
  }

  initNgxUploader() {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      // this.startUpload();
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.creatingDocument = true;
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'done') {
      this.creatingDocument = false;
      this.removeFile(output.file.id);
      this.modalService.setDismissReason('created');
      this.bsModalRef.hide();
    }
  }

  startUpload(): void {
    const token = AuthService.getToken();
    const payload = this.documentFormGroup.value;
    payload.public = payload.public === 'true' || payload.public === true ? 1 : 0;

    const event: UploadInput = {
      type: 'uploadAll',
      url: `${environment.api_url}/documents`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: payload
    };

    this.uploadInput.emit(event);
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id });
  }

  onFileChange($event) {
    let file = $event.target.files[0];
    this.documentFormGroup.controls['file'].setValue(file ? file.name : '');
  }

}
