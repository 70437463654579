import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from '../../models/form.model';
import { FormHelpers } from '../../classes/form-helpers.class';

@Component({
  selector: 'app-forms-stages',
  templateUrl: './forms-stages.component.html',
  styleUrls: ['./forms-stages.component.css'],
})
export class FormsStagesComponent implements OnInit {
  @Input()
  form: Form;
  @Output()
  jumpToSection: EventEmitter<{ js_rule: string }> = new EventEmitter();
  public scrollToTop = FormHelpers.scrollToTop;

  constructor() {
  }

  ngOnInit() {
    this.scrollToTop();
  }

  hop(js_rule: string) {
    this.jumpToSection.emit({ js_rule: `${this.form.form_template_slug}_${js_rule}` });
  }
}
