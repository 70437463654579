import { Component, OnDestroy, OnInit } from '@angular/core';
import { Floor } from '../../../floors/models/floor.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { YesNoEnum } from '../../../shared/enums/yes-no.enum';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { AreasService } from '../../services/areas.service';
import { ImageCompressService } from 'ng2-image-compress';
import { Area } from '../../models/area.model';
import { SurveysService } from '../../../surveys/services/surveys.service';
import { SurveyResponse } from '../../../surveys/responses/survey.response';
import { Survey } from '../../../surveys/models/survey.model';
import { FloorTypeEnum } from '../../../shared/enums/floor-type.enum';
import { Building } from '../../../buildings/models/building.model';
import { AreaAccessEnum } from '../../enums/area-access.enum';
import { ComponentModel } from '../../../components/models/component.model';
import { ComponentsService } from '../../../components/services/components.service';
import { AreaResponse } from '../../responses/area.response';
import { environment } from '../../../../../environments/environment';
import { AppHelper } from '../../../shared/classes/app.helper';
import { SurveyTypeEnum } from '../../../shared/enums/survey-type.enum';
import { StateService } from '../../../shared/services/state.service';

@Component({
  selector: 'app-areas-copy',
  templateUrl: './areas-copy.component.html',
  styleUrls: ['./areas-copy.component.css'],
})
export class AreasCopyComponent implements OnInit, OnDestroy {
  public surveyId: string;
  public survey: Survey;
  public floor: Floor;
  public selectedArea: Area;
  public activeFloor: Floor;
  public activeFloorId: string;
  public activeBuilding: Building;
  public activeBuildingId: string;
  public formGroup: FormGroup;
  public postingArea = false;
  public findingSurveyById = false;
  public onHide = new Subject();
  public yesNoEnum = YesNoEnum;
  public source = 'current';
  public floorTypeEnum = FloorTypeEnum;
  public surveyTypeEnum = SurveyTypeEnum;
  public numberValidation = false;
  public subscriptions = new Subscription();
  public areaAccessButtons = [
    {
      value: AreaAccessEnum.No_Access,
      label: AreaAccessEnum[AreaAccessEnum.No_Access]
    },
    {
      value: AreaAccessEnum.Limited_Access,
      label: AreaAccessEnum[AreaAccessEnum.Limited_Access]
    },
    {
      value: AreaAccessEnum.Accessible,
      label: AreaAccessEnum[AreaAccessEnum.Accessible]
    },
  ];
  public nextAreaNumber: string;
  public floorCode: string;
  public areaNumbers: string[];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private areasService: AreasService,
    private surveysService: SurveysService,
    private componentsService: ComponentsService,
    public stateService: StateService,
  ) {
    if (+this.stateService.surveyType === SurveyTypeEnum['Re-inspection']) {
      this.areaAccessButtons.push({
        value: AreaAccessEnum.No_Access_Previously_Accessed,
        label: 'No Access (Previously Accessed)',
      });
    }
  }

  ngOnInit() {
    if (this.floor) {
      this.floorCode = AppHelper.getFloorCode(this.floor);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      floor_id: [this.floor.id],
      building_id: [null],
      description: [null, [Validators.required]],
      number: [this.nextAreaNumber],
      accessible: [AreaAccessEnum.Accessible, [Validators.required]],
      deviation_to_scope: [null, [Validators.required]],
      no_access_reason: [null],
      image: [null],
    });

    if (+this.stateService.surveyType === SurveyTypeEnum['Re-inspection']) {
      this.subscriptions.add(
        this.formGroup.get('number').valueChanges.subscribe(value => {
          this.numberValidation = this.areaNumbers.includes(value);
        })
      );
    }
  }

  onSubmit() {
    this.postingArea = true;
    const payload = this.formGroup.getRawValue();

    if (environment.cordova) {
      payload.floor_id = payload.floor_id.toString();
      payload.building_id = payload.building_id.toString();
    }

    this.areasService.createArea(payload).subscribe(
      (response: AreaResponse) => {
        const area = response.data;

        if (this.selectedArea.components.data && this.selectedArea.components.data.length > 0) {
          this.selectedArea.components.data.forEach(component => {
            const componentPayload = Object.assign({}, component);
            componentPayload.area_id = area.id;
            delete componentPayload.id;
            delete componentPayload.hashed_id;
            delete componentPayload.synced_at;
            delete componentPayload.uuid;

            this.submitComponent(componentPayload);
          });
        }

        this.bsModalRef.hide();
        this.onHide.next();
        this.postingArea = false;
        AppHelper.chime();
      },
      () => {
        this.postingArea = false;
        this.snotifyService.error('Unable to create Area. Please try again.', 'Error!');
      },
    );
  }

  submitComponent(component: ComponentModel) {
    this.componentsService.createComponent(component).subscribe(
      () => {
        AppHelper.chime();
      },
      () => {

      }
    );
  }

  onFileChange($event) {
    ImageCompressService.filesToCompressedImageSource($event.srcElement.files).then(observableImages => {
      observableImages.subscribe(
        image => {
          this.formGroup.get('image').setValue(image.compressedImage.imageDataUrl as string);
        },
        error => {
          this.snotifyService.error('Unable to load photo. Please try again.', 'Error!');
        },
        () => {
          // Done
        }
      );
    });
  }

  onCopy(area: Area) {
    this.selectedArea = area;
    this.formGroup.get('floor_id').setValue(this.floor.id);
    this.formGroup.get('building_id').setValue(this.floor.building_id);
    this.formGroup.get('accessible').setValue(area.accessible);
    this.formGroup.get('deviation_to_scope').setValue(area.deviation_to_scope ? area.deviation_to_scope : YesNoEnum.No);
    this.formGroup.get('no_access_reason').setValue(area.no_access_reason);
  }

  checkSource() {
    if (this.source === 'current') {
      return;
    }

    this.findingSurveyById = true;
    const includes = ['buildings', 'buildings.floors', 'buildings.floors.areas', 'buildings.floors.areas.components'];

    this.surveysService.findSurveyById(this.surveyId, includes).subscribe(
      (response: SurveyResponse) => {
        this.survey = response.data;
        this.findingSurveyById = false;
      },
      () => {
        this.findingSurveyById = false;
        this.snotifyService.error('Unable to load Survey buildings and floors. Please try again!', 'Error!');
      },
    );
  }

  setActiveBuilding() {
    this.activeFloor = null;

    if (environment.cordova) {
      return this.activeBuilding = this.survey.buildings.data.find(item => +item.id === +this.activeBuildingId);
    }

    this.activeBuilding = this.survey.buildings.data.find(item => item.id === this.activeBuildingId);
  }

  setActiveFloor() {
    if (environment.cordova) {
      this.activeFloor = this.activeBuilding.floors.data.find(item => +item.id === +this.activeFloorId);
      this.areaNumbers = AppHelper.pluck(this.activeFloor.areas.data, 'number');
      this.floorCode = AppHelper.getFloorCode(this.activeFloor);
      return;
    }

    this.activeFloor = this.activeBuilding.floors.data.find(item => item.id === this.activeFloorId);
    this.areaNumbers = AppHelper.pluck(this.activeFloor.areas.data, 'number');
    this.floorCode = AppHelper.getFloorCode(this.activeFloor);
  }
}
