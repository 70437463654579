<div class="modal-header">
  <h4 class="modal-title pull-left">
    Send Certificate
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group">
    <label for="email" class="d-block">
      Email address
    </label>

    <input
      [(ngModel)]="email"
      id="email"
      name="email"
      type="email"
      class="form-control">
  </div>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" [disabled]="!email || sending" (click)="send()">
    <span *ngIf="!sending">
      Send
    </span>
    <span *ngIf="sending">
      <i class="fa fa-spin fa-spinner mr-1"></i>
      Sending
    </span>
  </button>
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
