import { Component, OnInit } from '@angular/core';
import { Survey } from '../../../surveys/models/survey.model';
import { SurveysService } from '../../../surveys/services/surveys.service';
import { BsModalRef } from 'ngx-bootstrap';
import { SurveyResponse } from '../../../surveys/responses/survey.response';
import { ApiService } from '../../services/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Building } from '../../../buildings/models/building.model';
import { Subject } from 'rxjs';
import { BuildingQueryBuilder } from '../../classes/building.query-builder.class';
import { BuildingResponse } from '../../../buildings/responses/building.response';
import { Floor } from '../../../floors/models/floor.model';
import { FloorQueryBuilder } from '../../classes/floor.query-builder.class';
import { FloorResponse } from '../../../floors/responses/floor.response';
import { Area } from '../../../areas/models/area.model';
import { AreaQueryBuilder } from '../../classes/area.query-builder.class';
import { AreaResponse } from '../../../areas/responses/area.response';
import { ComponentModel } from '../../../components/models/component.model';
import { ComponentQueryBuilder } from '../../classes/component.query-builder.class';
import { ComponentResponse } from '../../../components/responses/component.response';
import { SurveySample } from '../../../survey-samples/models/survey-sample.model';
import { SurveySampleQueryBuilder } from '../../classes/survey_sample.query-builder.class';
import { SurveySampleResponse } from '../../../survey-samples/responses/survey-sample.response';
import * as moment from 'moment';
import { SiteQueryBuilder } from '../../classes/site.query-builder.class';
import { SurveyQueryBuilder } from '../../classes/survey.query-builder.class';
import { UserQueryBuilder } from '../../classes/user.query-builder.class';
import { SurveyTypeEnum } from '../../enums/survey-type.enum';
import { AppHelper } from '../../classes/app.helper';
import { Site } from '../../models/site.model';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-surveys-download-modal',
  templateUrl: './surveys-download-modal.component.html',
  styleUrls: ['./surveys-download-modal.component.css'],
})
export class SurveysDownloadModalComponent implements OnInit {
  public survey: Survey;
  public activeSurvey: Survey;
  public activeBuilding: Building;
  public activeFloor: Floor;
  public activeArea: Area;
  public activeComponent: ComponentModel;
  public activeSurveySample: SurveySample;
  public downloading = false;
  public downloadFinished = false;
  public onHide = new Subject();
  public activeBuildingName = null;
  public surveyTypeEnum = SurveyTypeEnum;
  public getFloorName = AppHelper.getFloorName;

  constructor(
    public surveysService: SurveysService,
    public bsModalRef: BsModalRef,
    private http: HttpClient,
  ) { }

  ngOnInit() {}

  async download() {
    this.downloading = true;
    const includes = ['buildings'];
    const params = new HttpParams().set('include', includes.join(',')).set('append', 'report_number');

    const surveyResponse: SurveyResponse = await this.http
      .get<SurveyResponse>(`${ApiService.ApiUrl}/surveys/${this.survey.hashed_id}`, { params })
      .toPromise();
    this.activeSurvey = surveyResponse.data;

    const siteQueryBuilder = new SiteQueryBuilder();
    const site = await siteQueryBuilder.find('sites', surveyResponse.data.site_id, 'hashed_id') as Site;
    const surveyQueryBuilder = new SurveyQueryBuilder();

    surveyQueryBuilder.update({
      id: this.survey.id,
      report_number: surveyResponse.data.report_number,
    });

    if (site) {
      surveyQueryBuilder.update({
        id: this.survey.id,
        site_id: parseInt(site.id).toString(),
      });
    }

    const userQueryBuilder = new UserQueryBuilder();
    const user = await userQueryBuilder.find('users', surveyResponse.data.user_id, 'hashed_id') as User;

    if (user) {
      surveyQueryBuilder.update({
        id: this.survey.id,
        user_id: parseInt(user.id).toString(),
      });
    }

    for (const building of surveyResponse.data.buildings.data) {
      this.activeBuilding = building;
      const localBuilding = await this.updateOrCreateBuilding(building);
      await this.downloadFloors(localBuilding);
      this.activeBuilding = null;
    }

    this.activeSurvey = null;
    this.downloading = false;
    this.downloadFinished = true;
  }

  async updateOrCreateBuilding(building: Building) {
    const buildingQueryBuilder = new BuildingQueryBuilder();
    const defaultComponents = Array.isArray(building.default_components)
      ? building.default_components.join(',')
      : building.default_components;
    const payload = {
      uuid: building.uuid,
      hashed_id: building.id,
      survey_id: parseInt(this.survey.id).toString(),
      name: building.name,
      default_components: defaultComponents,
      synced_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      updated_at: moment().subtract(1, 'minute').format('YYYY/MM/DD HH:mm:ss'),
    };

    let localBuilding = await buildingQueryBuilder.find('buildings', building.uuid, 'uuid') as Building;

    if (localBuilding) {
      await buildingQueryBuilder.updateByField(payload, 'uuid').toPromise();

      return localBuilding;
    }

    const localBuildingResponse = await buildingQueryBuilder.create(payload).toPromise() as BuildingResponse;
    localBuilding = localBuildingResponse.data;

    return localBuilding;
  }

  private async downloadFloors(localBuilding: Building) {
    const includes = ['floors'];
    const params = new HttpParams().set('include', includes.join(','));

    const buildingResponse: BuildingResponse = await this.http
      .get<BuildingResponse>(`${ApiService.ApiUrl}/buildings/${localBuilding.hashed_id}`, { params })
      .toPromise();

    for (const floor of buildingResponse.data.floors.data) {
      this.activeFloor = floor;
      const localFloor = await this.updateOrCreateFloor(localBuilding, floor);

      await this.downloadAreas(localFloor);
      this.activeFloor = null;
    }
  }

  async updateOrCreateFloor(localBuilding: Building, floor: Floor) {
    const floorQueryBuilder = new FloorQueryBuilder();
    const payload = {
      uuid: floor.uuid,
      hashed_id: floor.id,
      building_id: parseInt(localBuilding.id).toString(),
      type: floor.type,
      number: floor.number,
      synced_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      updated_at: moment().subtract(1, 'minute').format('YYYY/MM/DD HH:mm:ss'),
    };

    let localFloor = await floorQueryBuilder.find('floors', floor.uuid, 'uuid') as Floor;

    if (localFloor) {
      await floorQueryBuilder.updateByField(payload, 'uuid').toPromise();

      return localFloor;
    }

    const localFloorResponse = await floorQueryBuilder.create(payload).toPromise() as FloorResponse;
    localFloor = localFloorResponse.data;

    return localFloor;
  }

  private async downloadAreas(localFloor: Floor) {
    const includes = ['areas'];
    const params = new HttpParams().set('include', includes.join(','));

    const floorResponse: FloorResponse = await this.http
      .get<FloorResponse>(`${ApiService.ApiUrl}/floors/${localFloor.hashed_id}`, { params })
      .toPromise();

    for (const area of floorResponse.data.areas.data) {
      this.activeArea = area;
      const localArea = await this.updateOrCreateArea(localFloor, area);

      await this.downloadComponents(localArea);
      this.activeArea = null;
    }
  }

  async updateOrCreateArea(localFloor: Floor, area: Area) {
    const areaQueryBuilder = new AreaQueryBuilder();
    const payload = {
      uuid: area.uuid,
      hashed_id: area.id,
      building_id: parseInt(localFloor.building_id).toString(),
      floor_id: parseInt(localFloor.id).toString(),
      description: area.description,
      accessible: area.accessible,
      deviation_to_scope: area.deviation_to_scope,
      no_access_reason: area.no_access_reason,
      image: area.image,
      code: area.code,
      number: area.number,
      synced_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      updated_at: moment().subtract(1, 'minute').format('YYYY/MM/DD HH:mm:ss'),
    };

    let localArea = await areaQueryBuilder.find('areas', area.uuid, 'uuid') as Area;

    if (localArea) {
      await areaQueryBuilder.updateByField(payload, 'uuid').toPromise();

      return localArea;
    }

    const localAreaResponse = await areaQueryBuilder.create(payload).toPromise() as AreaResponse;
    localArea = localAreaResponse.data;

    return localArea;
  }

  private async downloadComponents(localArea: Area) {
    const includes = ['components'];
    const params = new HttpParams().set('include', includes.join(','));

    const areaResponse = await this.http
      .get<AreaResponse>(`${ApiService.ApiUrl}/areas/${localArea.hashed_id}`, { params })
      .toPromise();

    for (const component of areaResponse.data.components.data) {
      this.activeComponent = component;
      const localComponent = await this.updateOrCreateComponent(localArea, component);

      await this.downloadSurveySamples(localComponent);
      this.activeComponent = null;
    }
  }

  async updateOrCreateComponent(localArea: Area, component: ComponentModel) {
    const componentQueryBuilder = new ComponentQueryBuilder();
    const payload = {
      uuid: component.uuid,
      hashed_id: component.id,
      area_id: parseInt(localArea.id).toString(),
      component_template_id: component.component_template_id,
      text: component.text,
      accessible: component.accessible,
      no_access_reason: component.no_access_reason,
      synced_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      updated_at: moment().subtract(1, 'minute').format('YYYY/MM/DD HH:mm:ss'),
    };

    let localComponent = await componentQueryBuilder.find('components', component.uuid, 'uuid') as ComponentModel;

    if (localComponent) {
      await componentQueryBuilder.updateByField(payload, 'uuid').toPromise();

      return localComponent;
    }

    const localComponentResponse = await componentQueryBuilder.create(payload).toPromise() as ComponentResponse;
    localComponent = localComponentResponse.data;

    return localComponent;
  }

  private async downloadSurveySamples(localComponent: ComponentModel) {
    const includes = ['survey_samples'];
    const params = new HttpParams()
      .set('include', includes.join(','));

    const componentResponse: ComponentResponse = await this.http
      .get<ComponentResponse>(`${ApiService.ApiUrl}/components/${localComponent.hashed_id}`, { params })
      .toPromise();

    for (const surveySample of componentResponse.data.survey_samples.data) {
      this.activeSurveySample = surveySample;
      await this.downloadSurveySample(surveySample.id, localComponent);
      this.activeSurveySample = null;
    }
  }

  private async downloadSurveySample(surveySampleHashedId: string, localComponent: ComponentModel) {
    const appends = [
      'image',
      'establishing_image',
    ];
    const params = new HttpParams().set('append', appends.join(','));

    const surveySampleResponse: SurveySampleResponse = await this.http
      .get<SurveySampleResponse>(`${ApiService.ApiUrl}/survey_samples/${surveySampleHashedId}`, { params })
      .toPromise();

    const surveySample = surveySampleResponse.data;
    await this.updateOrCreateSurveySample(localComponent, surveySample);
  }

  async updateOrCreateSurveySample(localComponent: ComponentModel, surveySample: SurveySample) {
    const surveySampleQueryBuilder = new SurveySampleQueryBuilder();
    const productType = Array.isArray(surveySample.product_type) ? surveySample.product_type.join(',') : surveySample.product_type;
    const asbestosType = Array.isArray(surveySample.asbestos_type) ? surveySample.asbestos_type.join(',') : surveySample.asbestos_type;
    const additionalRecommendations = Array.isArray(surveySample.additional_recommendations) ? surveySample.additional_recommendations.join(',') : surveySample.additional_recommendations;
    const payload = {
      uuid: surveySample.uuid,
      hashed_id: surveySample.id,
      survey_id: parseInt(this.survey.id).toString(),
      component_id: parseInt(localComponent.id).toString(),
      associated_physical_sample_number: surveySample.associated_physical_sample_number,
      number: surveySample.number,
      lab_number: surveySample.lab_number,
      assessment_type: surveySample.assessment_type,
      intrusive_assessment: surveySample.intrusive_assessment,
      item_description: surveySample.item_description,
      product_type: productType,
      extend_of_damage_deterioration: surveySample.extend_of_damage_deterioration,
      surface_type_treatment: surveySample.surface_type_treatment,
      asbestos_type: asbestosType,
      presumed_asbestos_type: surveySample.presumed_asbestos_type,
      presumption: surveySample.presumption,
      exposed_population: surveySample.exposed_population,
      extent: surveySample.extent,
      amount_type: surveySample.amount_type,
      accessibility: surveySample.accessibility,
      risk_rating: surveySample.risk_rating,
      risk_category: surveySample.risk_category,
      recommendations: surveySample.recommendations ? surveySample.recommendations.toString() : null,
      additional_recommendations: additionalRecommendations,
      comments: surveySample.comments,
      gps: surveySample.gps,
      photo: surveySample.photo,
      image: surveySample.image,
      establishing_image: surveySample.establishing_image,
      video: surveySample.video,
      is_copied: surveySample.is_copied,
      removed: surveySample.removed,
      synced_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      updated_at: moment().subtract(1, 'minute').format('YYYY/MM/DD HH:mm:ss'),
      created_at: surveySample.created_at,
    };

    const localSurveySample = await surveySampleQueryBuilder.find('survey_samples', surveySample.uuid, 'uuid') as SurveySample;

    if (localSurveySample) {
      await surveySampleQueryBuilder.updateByField(payload, 'uuid').toPromise();

      return;
    }

    await surveySampleQueryBuilder.create(payload).toPromise();
  }
}
