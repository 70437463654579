import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { BusService } from '../../services/bus.service';
import { Links, Meta } from '../../models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input('hash')
  hash: string;
  @Input()
  meta: Meta;
  @Input()
  links: Links;
  @Output()
  updated: EventEmitter<any> = new EventEmitter();
  public baseUrl: string;
  public pages: number[] = [];
  public loadingType = 'loading';
  public paginationType = 'pagination';

  constructor(private http: HttpClient, private snotifyService: SnotifyService, private busService: BusService) {}

  ngOnInit() {
    this.baseUrl = this.links.first.split('&page=1')[0];
    this.setHashedTypes();
  }

  setHashedTypes() {
    if (this.hash) {
      this.loadingType = `${this.loadingType}_${this.hash}`;
      this.paginationType = `${this.paginationType}_${this.hash}`;
    }
  }

  goToInputPage() {
    if (this.meta.current_page > this.meta.last_page || this.meta.current_page < 1) {
      this.meta.current_page = 1;
    }

    this.getPage(this.meta.current_page);
  }

  getPage(page) {
    const params = new HttpParams().set('page', page);
    this.busService.dispatch({type: this.loadingType, data: true});

    this.http.get(this.baseUrl, { params }).subscribe(
      response => {
        this.busService.dispatch({type: this.paginationType, data: response});
        this.busService.dispatch({type: this.loadingType, data: false});
      },
      () => {
        this.snotifyService.error('Unable to load resources. Please try again.', 'Error!');
        this.busService.dispatch({type: this.loadingType, data: false});
      }
    );
  }
}
