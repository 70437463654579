import { BaseModel } from '../classes/query-builder.class';

export class Action extends BaseModel {
  public table = 'actions';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    type: 'VARCHAR',
    uuid: 'VARCHAR',
    resource: 'VARCHAR',
  };
}
