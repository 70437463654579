<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Update Survey
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="formGroup" class="modal-body">
    <div *ngIf="authService.user.is('admin') && !environment.cordova" class="form-group">
      <label>
        Surveyors
      </label>
      <ngx-select
          class="form-control"
          [ngClass]="{'is-invalid': !formGroup.controls['survey_users'].valid && formGroup.controls['survey_users'].touched}"
          [items]="job.users.data"
          [formControl]="formGroup.controls['survey_users']"
          [optionValueField]="optionValueField"
          [multiple]="true"
          optionTextField="name"></ngx-select>
      <div class="help-block text-danger"
           *ngIf="formGroup.controls['survey_users'].touched && formGroup.controls['survey_users'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label for="survey_type">
        Survey Type
      </label>
      <select
          [formControl]="formGroup.controls['survey_type']"
          class="form-control"
          id="survey_type"
          name="survey_type">
        <option [value]="surveyTypeEnum.Management">Management</option>
        <option [value]="surveyTypeEnum.Refurbishment">Refurbishment</option>
        <option [value]="surveyTypeEnum.Demolition">Demolition</option>
        <option [value]="surveyTypeEnum['Re-inspection']">Re-Inspection</option>
      </select>
    </div>

    <div class="form-group">
      <label for="start_date">
        Start Date
      </label>
      <input
        [textMask]="{mask: dateMask}"
        [formControl]="formGroup.controls['start_date']"
        class="form-control"
        id="start_date"
        name="start_date"
        placeholder="Enter job start date"
        type="text">
    </div>
  </div>

  <div class="modal-footer">
    <button *ngIf="!environment.cordova" type="button" class="btn btn-primary" [disabled]="!formGroup.valid || postingSurvey" (click)="onReallocate()">
      <span *ngIf="!reallocating">
        Re-allocate
      </span>
      <span *ngIf="reallocating">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Re-allocating
      </span>
    </button>

    <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid || postingSurvey" (click)="onSubmit()">
      <span *ngIf="!postingSurvey">
        Update
      </span>
      <span *ngIf="postingSurvey">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Updating
      </span>
    </button>

    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
