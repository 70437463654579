import { QueryBuilder } from './query-builder.class';
import { FormSection } from '../entities/form_section.entity';
import { from, Observable } from 'rxjs';
import { JsonSerializer } from './serializer.class';
import { FormSectionResponse, FormSectionsResponse } from '../../forms/responses/form-section.response';
import { FormTemplateSectionQueryBuilder } from './form_template_section.query-builder.class';
import { FormSection as FormSectionModel } from '../../forms/models/form-section.model';
import { FormAnswerQueryBuilder } from './form_answer.query-builder.class';
import { FormTemplateSectionResponse } from '../../forms/responses/form-template-section.response';
import { FormTemplateQuestionQueryBuilder } from './form_template_question.query-builder.class';
import { FormTemplateQuestionsResponse } from '../../forms/responses/form-template-question.response';
import { FormTemplateQuestion } from '../../forms/models/form-template-question.model';
import * as moment from 'moment';

/**
 * Form Section Entity Query Builder.
 */
export class FormSectionQueryBuilder extends QueryBuilder {
  constructor() {
    super(new FormSection());
  }

  /**
   * Get all form sections.
   * Includes: FormTemplateSection, FormAnswers
   */
  first(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()} LIMIT 1`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const formSectionResponse: FormSectionResponse = JsonSerializer.item(data[0]);

        const formTemplateSectionQueryBuilder = new FormTemplateSectionQueryBuilder();
        formSectionResponse.data.form_template_section = await formTemplateSectionQueryBuilder
          .where('hashed_id', '=', formSectionResponse.data.form_template_section_id)
          .first()
          .toPromise();

        const formSectionIdField = formSectionResponse.data.hashed_id ? 'form_hashed_id' : 'form_section_id';
        const formSectionIdValue = formSectionResponse.data.hashed_id ? formSectionResponse.data.hashed_id : +formSectionResponse.data.id;

        const formAnswerQueryBuilder = new FormAnswerQueryBuilder();
        formSectionResponse.data.form_answers = await formAnswerQueryBuilder
          .where(formSectionIdField, '=', formSectionIdValue)
          .get()
          .toPromise();

        return resolve(formSectionResponse);
      })
    );
  }

  /**
   * Get all form sections.
   * Includes: FormTemplateSection, FormAnswers
   */
  get(items: string = '*'): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const query = `SELECT ${items} FROM ${
          this._table
        } ${this._buildWhereQuery()} ${this._buildOrderByQuery()} ${this._buildTakeQuery()}`;
        const response = await this._query(query);
        const data = this._filterQueryToArray(response);
        const formSectionResponse: FormSectionsResponse = JsonSerializer.collection(data);

        await Promise.all(
          formSectionResponse.data.map(async (formSection: FormSectionModel) => {
            const formTemplateSectionQueryBuilder = new FormTemplateSectionQueryBuilder();
            formSection.form_template_section = await formTemplateSectionQueryBuilder
              .where('hashed_id', '=', formSection.form_template_section_id)
              .first()
              .toPromise();

            const formAnswerQueryBuilder = new FormAnswerQueryBuilder();
            formSection.form_answers = await formAnswerQueryBuilder
              .where('form_section_id', '=', +formSection.id)
              .get()
              .toPromise();

            return formSection;
          })
        );

        return resolve(formSectionResponse);
      })
    );
  }

  /**
   * Find and create the next form section.
   */
  createNextFormSection(data): Observable<any> {
    return from(
      new Promise(async (resolve, reject) => {
        const formTemplateSectionBuilder = new FormTemplateSectionQueryBuilder();
        const formTemplateSectionResponse: FormTemplateSectionResponse = await formTemplateSectionBuilder
          .where('js_rule', '=', data.js_rule)
          .first()
          .toPromise();

        const formSectionBuilder = new FormSectionQueryBuilder();
        let formSectionResponse: FormSectionResponse = await formSectionBuilder
          .create({
            form_id: data.form_id.toString(),
            form_template_section_id: formTemplateSectionResponse.data.hashed_id,
            position: data.position,
            sample_id: data.sample_id ? data.sample_id.toString() : null
          })
          .toPromise();

        const formTemplateQuestionsQueryBuilder = new FormTemplateQuestionQueryBuilder();
        const formTemplateQuestionsResponse: FormTemplateQuestionsResponse = await formTemplateQuestionsQueryBuilder
          .where('form_template_section_id', '=', formTemplateSectionResponse.data.hashed_id)
          .get()
          .toPromise();
        formTemplateQuestionsResponse.data.forEach(async (formTemplateQuestion: FormTemplateQuestion) => {
          const formAnswerQueryBuilder = new FormAnswerQueryBuilder();
          const formAnswer = await formAnswerQueryBuilder.create({
            form_id: data.form_id.toString(),
            form_section_id: formSectionResponse.data.id.toString(),
            form_template_question_id: formTemplateQuestion.hashed_id,
            created_at: moment().format('YYYY-MM-DD HH:mm:ss')
          });
        });

        formSectionResponse = await formSectionBuilder
          .where('id', '=', formSectionResponse.data.id)
          .first()
          .toPromise();

        return resolve(formSectionResponse);
      })
    );
  }
}
