import { QueryBuilder } from './query-builder.class';
import { Setting } from '../entities/setting.entity';

/**
 * Setting Entity Query Builder.
 */
export class SettingQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Setting(), migrate);
  }
}
