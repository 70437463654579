import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-gps',
  templateUrl: './input-type-gps.component.html',
  styleUrls: ['./input-type-gps.component.css']
})
export class InputTypeGpsComponent extends InputTypeBaseFieldComponent implements OnInit {
  constructor(private geolocation: Geolocation) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  getGeolocation() {
    this.geolocation.getCurrentPosition({ timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 }).then(position => {
      this.question.answer.value = `${position.coords.latitude}|${position.coords.longitude}`;
    });
  }
}
