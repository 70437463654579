import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { FormsFillComponent } from './modules/forms/components/forms-fill/forms-fill.component';
import { RoleGuard } from './modules/shared/guards/role.guard';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'uploader',
        loadChildren: './modules/uploader/uploader.module#UploaderModule'
      },
      {
        path: 'users',
        loadChildren: './modules/users/users.module#UsersModule'
      },
      {
        path: 'roles',
        loadChildren: './modules/roles/roles.module#RolesModule'
      },
      {
        path: 'clients',
        loadChildren: './modules/clients/clients.module#ClientsModule'
      },
      {
        path: 'inventory',
        loadChildren: './modules/inventory/inventory.module#InventoryModule'
      },
      {
        path: 'jobs',
        loadChildren: './modules/jobs/jobs.module#JobsModule'
      },
      {
        path: 'projects',
        loadChildren: './modules/projects/projects.module#ProjectsModule'
      },
      {
        path: 'survey-jobs',
        loadChildren: './modules/survey-jobs/survey-jobs.module#SurveyJobsModule'
      },
      {
        path: 'surveys',
        loadChildren: './modules/surveys/surveys.module#SurveysModule'
      },
      {
        path: 'survey-reviews',
        loadChildren: './modules/survey-reviews/survey-reviews.module#SurveyReviewsModule'
      },
      {
        path: 'buildings',
        loadChildren: './modules/buildings/buildings.module#BuildingsModule'
      },
      {
        path: 'floors',
        loadChildren: './modules/floors/floors.module#FloorsModule'
      },
      {
        path: 'areas',
        loadChildren: './modules/areas/areas.module#AreasModule'
      },
      {
        path: 'forms',
        loadChildren: './modules/forms/hfs-forms.module#HFSFormsModule'
      },
      {
        path: 'component-templates',
        loadChildren: './modules/component-templates/component-templates.module#ComponentTemplatesModule'
      },
      {
        path: 'components',
        loadChildren: './modules/components/components.module#ComponentsModule'
      },
      {
        path: 'survey-samples',
        loadChildren: './modules/survey-samples/survey-samples.module#SurveySamplesModule'
      },
      {
        path: 'forms/fill/:form_id',
        component: FormsFillComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['technician', 'admin', 'admin_assistant']
        }
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: 'password-reset',
    component: ResetPasswordComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
