import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SnotifyService } from 'ng-snotify';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public excluded: string[] = [
    `POST@${environment.api_url}/oauth/token`,
    `POST@${environment.api_url}/oauth/token`
  ];

  constructor(private authService: AuthService, protected router: Router, private snotifyService: SnotifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = AuthService.getToken();

    if (this.excluded.includes(`${request.method}@${request.url}`)) {
      return next.handle(request);
    }

    const clonedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    return next.handle(clonedRequest).pipe(
      catchError((response: HttpErrorResponse) => {
        const error = response.error;

        if (response.status === 403) {
          this.snotifyService.warning('You do not have the needed permissions for this action.', 'Unauthorized')
        }

        if (response.status === 401) {
          this.authService.logout();
        }

        return throwError(error);
      })
    );
  }
}
