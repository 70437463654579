import { BaseModel } from '../classes/query-builder.class';

export class Survey extends BaseModel {
  public table = 'surveys';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    uuid: 'VARCHAR',
    hashed_id: 'VARCHAR',
    assignment_id: 'VARCHAR',
    site_id: 'VARCHAR',
    user_id: 'VARCHAR',
    number: 'VARCHAR',
    report_number: 'VARCHAR',
    archived: 'INTEGER',
    status: 'INTEGER',
    survey_type: 'INTEGER',
    corresponding_zampa: 'INTEGER',
    corresponding_third_party: 'INTEGER',
    corresponding_survey_id: 'VARCHAR',
    corresponding_survey_number: 'VARCHAR',
    corresponding_third_party_numbers: 'VARCHAR',
    access_given: 'INTEGER',
    commissioner: 'VARCHAR',
    date_from: 'VARCHAR',
    date_to: 'VARCHAR',
    start_date: 'VARCHAR',
    site_photo: 'VARCHAR',
    site_plan: 'VARCHAR',
    certificate: 'VARCHAR',
    purpose: 'VARCHAR',
    address: 'VARCHAR',
    building_type: 'INTEGER',
    building_usage: 'VARCHAR',
    construction_date: 'VARCHAR',
    construction_type: 'VARCHAR',
    scopes_agreed: 'INTEGER',
    scope: 'VARCHAR',
    areas_description: 'LONGTEXT',
    exclusions: 'VARCHAR',
    deviations: 'INTEGER',
    deviations_description: 'LONGTEXT',
    reported_to_client: 'INTEGER',
    completed_at: 'DATETIME',
    synced_at: 'DATETIME',
    updated_at: 'DATETIME',
  };
}
