import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SitesService } from '../../../sites/services/sites.service';
import { SitesResponse } from '../../responses/site.response';
import { Site } from '../../models/site.model';
import { SitesCreateModalComponent } from '../sites-create-modal/sites-create-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sites-search',
  templateUrl: './sites-search.component.html',
  styleUrls: ['./sites-search.component.css'],
})
export class SitesSearchComponent implements OnInit, OnDestroy {
  @Input() clientId: string|number;
  @Input() hint: string;
  @Input() siteName: string;
  @Output() siteSelected: EventEmitter<Site> = new EventEmitter();
  public findingSiteByName = false;
  public sites: Site[] = [];
  private bsModalRef: BsModalRef;
  public subscriptions = new Subscription();

  constructor(
    private modalService: BsModalService,
    private sitesService: SitesService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  findSiteByName() {
    this.findingSiteByName = true;

    this.sitesService.findSiteByName(this.clientId, this.siteName).subscribe(
      (response: SitesResponse) => {
        this.sites = response.data;
        this.findingSiteByName = false;
      },
      () => {
        this.findingSiteByName = false;
      },
    );
  }

  selectSite(site: Site) {
    this.sites = [];
    this.siteName = site.name;
    this.siteSelected.emit(site);
  }

  onSiteCreate() {
    const initialState = {
      clientId: this.clientId,
    };

    this.bsModalRef = this.modalService.show(SitesCreateModalComponent, { initialState });
    this.subscriptions.add(
      this.bsModalRef.content.onHide.subscribe(site => {
        this.siteName = site.name;
        this.siteSelected.emit(site);
      })
    );
  }
}
