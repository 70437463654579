import { BaseModel } from '../classes/query-builder.class';

export class SurveySample extends BaseModel {
  public table = 'survey_samples';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    uuid: 'VARCHAR',
    hashed_id: 'VARCHAR',
    assignment_id: 'VARCHAR',
    survey_id: 'VARCHAR',
    component_id: 'VARCHAR',
    associated_physical_sample_number: 'VARCHAR',
    number: 'VARCHAR',
    lab_number: 'VARCHAR',
    assessment_type: 'INTEGER',
    intrusive_assessment: 'INTEGER',
    item_description: 'VARCHAR',
    product_type: 'VARCHAR',
    extend_of_damage_deterioration: 'INTEGER',
    surface_type_treatment: 'INTEGER',
    asbestos_type: 'VARCHAR',
    presumed_asbestos_type: 'INTEGER',
    presumption: 'INTEGER',
    presumption_reason: 'LONGTEXT',
    exposed_population: 'INTEGER',
    risk_rating: 'INTEGER',
    risk_category: 'INTEGER',
    extent: 'VARCHAR',
    amount_type: 'INTEGER',
    accessibility: 'INTEGER',
    is_copied: 'INTEGER',
    removed: 'INTEGER',
    reference_report: 'VARCHAR',
    reference_sample_number: 'VARCHAR',
    comments: 'LONGTEXT',
    recommendations: 'LONGTEXT',
    additional_recommendations: 'LONGTEXT',
    gps: 'VARCHAR',
    photo: 'LONGTEXT',
    image: 'LONGTEXT',
    establishing_shot: 'LONGTEXT',
    establishing_image: 'LONGTEXT',
    video: 'VARCHAR',
    created_at: 'VARCHAR',
    synced_at: 'DATETIME',
    updated_at: 'DATETIME',
  };
}
