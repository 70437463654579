import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { RevisionsService } from '../../services/revisions.service';
import { FormHelpers } from '../../../forms/classes/form-helpers.class';
import { Subject } from 'rxjs';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-revisions-create-modal',
  templateUrl: './revisions-create-modal.component.html',
  styleUrls: ['./revisions-create-modal.component.css'],
})
export class RevisionsCreateModalComponent implements OnInit {
  public formId: string;
  public surveyId: string;
  public revisionFormGroup: FormGroup;
  public postingRevision = false;
  public dateMask = FormHelpers.dateMask;
  public onModalHide: Subject<boolean> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private snotifyService: SnotifyService,
    private revisionsService: RevisionsService,
  ) { }

  ngOnInit() {
    this.initRevisionForm();
  }

  initRevisionForm() {
    this.revisionFormGroup = this.formBuilder.group({
      form_id: [this.formId],
      survey_id: [this.surveyId],
      number: ['', [Validators.required]],
      date: ['', [Validators.required]],
      publisher: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.postingRevision = true;
    const payload = this.revisionFormGroup.value;
    payload.date = FormHelpers.convertAPIDate(payload.date);

    this.revisionsService.createRevision(payload).subscribe(
      () => {
        this.postingRevision = false;
        this.snotifyService.success('A new revision has been created.', 'Success!');
        this.bsModalRef.hide();
        this.onModalHide.next();
        AppHelper.chime();
      },
      () => {
        this.postingRevision = false;
        this.snotifyService.error('Unable to create revision. Please try again.', 'Error!');
      }
    );
  }

}
