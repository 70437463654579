import { Component, OnInit } from '@angular/core';
import { DocumentTypeEnum } from '../../enums/document-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Document } from '../../models/document.model';
import { DocumentsService } from '../../services/documents.service';
import { DocumentResponse } from '../../responses/document.response';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-documents-update',
  templateUrl: './documents-update.component.html',
  styleUrls: ['./documents-update.component.css'],
})
export class DocumentsUpdateComponent implements OnInit {
  public document: Document;
  public document_types = DocumentTypeEnum;
  public documentFormGroup: FormGroup;
  public onHide: Subject<Document> = new Subject();
  public updating = false;

  constructor(
    private documentsService: DocumentsService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.initDocumentForm();
  }

  initDocumentForm() {
    this.documentFormGroup = this.formBuilder.group({
      id: [this.document.id, [Validators.required]],
      name: [this.document.name, [Validators.required]],
      type: [this.document.type],
      documentable_id: [this.document.documentable_id],
      documentable_type: [this.document.documentable_type],
      public: [this.document.public === 1]
    });
  }

  update() {
    this.updating = true;
    const payload = this.documentFormGroup.value;
    payload.public = payload.public === 'true' || payload.public === true ? 1 : 0;

    this.documentsService.updateDocument(payload).subscribe(
      (response: DocumentResponse) => {
        this.bsModalRef.hide();
        this.onHide.next(response.data);
        this.updating = false;
      },
      () => {

        this.updating = false;
      },
    );
  }

}
