import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentsService } from '../../services/comments.service';
import { CommentResponse } from '../../responses/comment.response';
import { SnotifyService } from 'ng-snotify';
import { Comment } from '../../models/comment.model';
import { User } from '../../../auth/classes/user.class';
import { AuthService } from '../../../auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-comments-create',
  templateUrl: './comments-create.component.html',
  styleUrls: ['./comments-create.component.css']
})
export class CommentsCreateComponent implements OnInit {
  @Input('comments') comments: Comment[] = [];
  @Input('parentResource') parentResource: { id: string; name: string };
  public user: User;
  public commentFormGroup: FormGroup;
  public postingMessage = false;
  public moment = moment;

  constructor(
    private formBuilder: FormBuilder,
    private commentsService: CommentsService,
    private snotifyService: SnotifyService,
    private authService: AuthService
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.initService();
    this.initCommentForm();
  }

  initService() {
    this.commentsService.parentResource = this.parentResource;
  }

  initCommentForm() {
    this.commentFormGroup = this.formBuilder.group({
      message: ['', [Validators.required]]
    });
  }

  createComment() {
    this.postingMessage = true;
    const payload = this.commentFormGroup.controls['message'].value;
    this.commentFormGroup.disable();

    this.commentsService.createComment(payload).subscribe(
      (response: CommentResponse) => {
        this.postingMessage = false;
        this.comments.push(response.data);
        this.commentFormGroup.reset();
        this.commentFormGroup.enable();
      },
      error => {
        this.postingMessage = false;
        this.commentFormGroup.enable();
        this.snotifyService.error('Unable to create comment. Please try again.', 'Error!');
      }
    );
  }

  onCommentDelete(comment: Comment) {
    this.snotifyService.confirm('The specific comment is going to be deleted.', 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteComment(comment),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteComment(comment: Comment) {
    comment.deleting = true;

    this.commentsService.deleteComment(comment).subscribe(
      response => {
        this.comments.splice(this.comments.findIndex(element => element.id === comment.id), 1);
      },
      error => {
        comment.deleting = false;
        this.snotifyService.error('Unable to delete comment. Please try again.', 'Error!');
      }
    );
  }

  onCommentEdit(comment: Comment) {
    comment.initialMessage = comment.message;
    comment.editing = true;
  }

  onCommentEditCancel(comment: Comment) {
    comment.message = comment.initialMessage;
    comment.editing = false;
  }

  onCommentUpdate(comment: Comment) {
    comment.updating = true;

    this.commentsService.updateComment(comment).subscribe(
      (response: CommentResponse) => {
        comment.updating = false;
        comment.editing = false;
      },
      error => {
        comment.updating = false;
        comment.editing = false;
        this.snotifyService.error('Unable to update comment. Please try again.', 'Error!');
      }
    );
  }
}
