import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { isNullOrUndefined } from 'util';
import { SnotifyService } from 'ng-snotify';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { FormConfig } from '../../classes/form-config.class';
import { Form } from '../../models/form.model';
import { ImageCompressService } from 'ng2-image-compress';
import * as moment from 'moment';
import { FormAnswersService } from '../../services/form-answers.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-input-type-photo',
  templateUrl: './input-type-photo.component.html',
  styleUrls: ['./input-type-photo.component.css']
})
export class InputTypePhotoComponent extends InputTypeBaseFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  form: Form;
  @Input()
  timestamped = false;
  public editTimestamp = false;
  public dateMask = FormHelpers.dateMask;
  public timeMask = FormHelpers.timeMask;
  public date: string;
  public time: string;
  public deletingFile: number;
  public savingTimestamps = false;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private snotifyService: SnotifyService,
    private ng2ImgMax: Ng2ImgMaxService,
    private busService: BusService,
    private formAnswersService: FormAnswersService
  ) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');
    if (this.timestamped) {
      this.date = moment(this.question.answer.created_at.split(' ')[0], 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.time = this.question.answer.created_at.split(' ')[1];
    }

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  ngAfterViewInit() {
    if (FormConfig.hidden.includes(this.question.number)) {
      this.subscriptions.add(this.busService.listen(this.question.number).subscribe(event => (this.question.isVisible = event.data)));

      setTimeout(() => {
        this.checkVisibility();
      }, 2);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onFileChange($event) {
    ImageCompressService.filesToCompressedImageSource($event.srcElement.files).then(observableImages => {
      observableImages.subscribe(
        image => {
          this.pushBlobToQuestion(image.compressedImage.imageDataUrl);
        },
        error => {
          this.snotifyService.error('Unable to save form files. Please try again. (new system)', 'Error!');
        },
        () => {
          // Done
        }
      );
    });
  }

  pushBlobToQuestion(result) {
    if (!Array.isArray(this.question.answer.value)) {
      this.question.answer.value = [];
    }

    this.question.answer.value.push(result as string);
    this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    this.fieldValidation(this.question);
  }

  deleteFile(index: number) {
    this.deletingFile = index;
    this.question.answer.value.splice(index, 1);
    this.deletingFile = null;
  }

  checkVisibility() {
    if (isNullOrUndefined(FormConfig.hiddenRelation[this.question.number])) {
      return;
    }

    const relationNumber = FormConfig.hiddenRelation[this.question.number];

    const relationValue = FormHelpers.findSectionFormAnswerByQuestionNumber(this.form, relationNumber);

    return (this.question.isVisible = relationValue.value !== 'N/A');
  }

  saveTimestamp() {
    this.savingTimestamps = true;
    const date = moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const time = this.time.split(':').length === 3 ? this.time : `${this.time}:00`;
    const timestamp = `${date} ${time}`;

    this.formAnswersService.updateTimestamp(this.question.answer.id, timestamp)
      .pipe(finalize(() => this.savingTimestamps = false))
      .subscribe(
      () => {
        this.editTimestamp = false;
        this.snotifyService.success('The timestamp has been successfully updated.', 'Success!');
      },
      () => {
        this.snotifyService.error('Unable to update timestamp, please try again.', 'Error!');
      },
    );
  }
}
