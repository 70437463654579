<div *ngIf="question.isVisible !== false">
  <label *ngIf="question.js_id !== 'sample_number'">
    {{ question.text }}
  </label>

  <div *ngIf="question.answer.value?.length > 0" class="row">
    <div
        *ngFor="let file of question.answer.value; let i = index"
        [class.col-md-4]="question.js_id !== 'photo_of_work_area'"
        [class.col-md-12]="question.js_id === 'photo_of_work_area'">
      <div class="card">
        <img
            *ngIf="file.includes('image')"
            [class.card-img-top-minimal]="question.js_id !== 'photo_of_work_area'"
            [class.card-img-top]="question.js_id === 'photo_of_work_area'"
            [src]="file"
            alt="Form photo">
        <i *ngIf="!file.includes('image')" class="fa fa-file-pdf-o fa-4x text-info text-center p-4"></i>
        <div class="card-body">
          <button
              (click)="deleteFile(i)"
              class="btn btn-sm btn-danger d-block">
            <span *ngIf="deletingFile === i; else delete_msg">
              <i class="fa fa-spin fa-spinner mr-1"></i>
              Deleting
            </span>
            <ng-template #delete_msg>
              Delete
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-group d-flex">
    <input
        [ngClass]="{'is-invalid': question.validation === false && !question.skipped}"
        [id]="id"
        [name]="name"
        class="inputfile"
        type="file"
        (change)="onFileChange($event)"
        accept=".jpg, .jpeg, .png, .pdf"
        multiple>

    <label [for]="id" class="btn btn-info btn-block w-80">
      Choose Photos
    </label>

    <button
      *ngIf="timestamped"
      (click)="editTimestamp = !editTimestamp"
      tooltip="Edit timestamp"
      class="btn btn-info">
      <i class="fa fa-calendar-check-o"></i>
    </button>
    <app-input-value-skip-button [question]="question" class="w-20"></app-input-value-skip-button>
  </div>

  <div
    *ngIf="editTimestamp"
    class="input-group mt-2">
    <input
      [(ngModel)]="time"
      [textMask]="{mask: timeMask}"
      type="text"
      class="form-control">

    <input
      [(ngModel)]="date"
      [textMask]="{mask: dateMask}"
      type="text"
      class="form-control">

    <button
      (click)="saveTimestamp()"
      [disabled]="savingTimestamps"
      tooltip="Save"
      class="btn btn-success">
      <i *ngIf="!savingTimestamps" class="fa fa-save"></i>
      <i *ngIf="savingTimestamps" class="fa fa-spin fa-spinner"></i>
    </button>
  </div>

  <div *ngIf="question.validation === false && !question.skipped" style="font-size: 80%;" class="text-danger mt-1">
    This field is required. Please either fill a value or press <i>Skip</i> button and enter a comment.
  </div>

  <app-input-comment [question]="question"></app-input-comment>
</div>
