export enum SurveyStatusEnum {
  Created = 1,
  Started,
  Completed,
  Completed_without_preliminaries,
  Lab_Completed,
  Published,
  Re_allocated,
}

export enum SurveyStatusGlossaryEnum {
  'Survey underway' = SurveyStatusEnum.Created,
  'Survey started but not finished' = SurveyStatusEnum.Started,
  'Survey completed waiting for preliminaries' = SurveyStatusEnum.Completed_without_preliminaries,
  'Survey completed waiting lab results' = SurveyStatusEnum.Completed,
  'Lab results completed waiting for Quality Control' = SurveyStatusEnum.Lab_Completed,
  Published = SurveyStatusEnum.Published,
  'Re-allocated' = SurveyStatusEnum.Re_allocated,
}
