import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { JobResponse } from '../../jobs/responses/job.response';
import { SurveyResponse, SurveysResponse } from '../responses/survey.response';
import { Survey } from '../models/survey.model';
import { Parameter } from '../../shared/models/parameter.model';
import { AuditsResponse } from '../../shared/responses/audit.response';
import { environment } from '../../../../environments/environment';
import { SurveyQueryBuilder } from '../../shared/classes/survey.query-builder.class';
import { CascadeService } from '../../shared/services/cascade.service';
import * as moment from 'moment';
import { AppHelper } from '../../shared/classes/app.helper';
import { ActionQueryBuilder } from '../../shared/classes/action.query-builder.class';

@Injectable()
export class SurveysService {
  private resource = 'surveys';
  private queryBuilder: SurveyQueryBuilder;

  constructor(private http: HttpClient, private cascadeService: CascadeService) {
    if (environment.cordova) {
      this.queryBuilder = new SurveyQueryBuilder();
    }
  }

  /**
   * Get all surveys.
   */
  getAllSurveys(
    limit: number = 15,
    includes: string[] = null,
    sorting: string,
    appends: string[] = null,
    parameters: Parameter[] = [],
  ): Observable<SurveysResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    parameters.forEach(parameter => {
      if (parameter.value) {
        params = params.set(`filter[${parameter.field}]`, `${parameter.value}`);
      }
    });

    return this.http.get<SurveysResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Get all surveys.
   */
  searchSurveys(search: string, types: string): Observable<SurveysResponse> {
    const params = new HttpParams()
      .set('limit', '10')
      .set('append', 'report_number')
      .set('filter[type]', types)
      .set('filter[search]', search);

    return this.http.get<SurveysResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find survey by id.
   */
  findSurveyById(surveyId: string, includes: string[] = null, appends: string[] = null): Observable<SurveyResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    return !environment.cordova
      ? this.http.get<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyId}`, { params })
      : this.queryBuilder.findWithIncludes(+surveyId, includes);
  }

  /**
   * Find job by number.
   */
  findSurveyByNumber(num: string, includes: string[] = null, excludes: string[] = null): Observable<JobResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (excludes) {
      params = params.set('exclude', excludes.join(','));
    }

    return this.http.get<JobResponse>(`${ApiService.ApiUrl}/${this.resource}/number/${num}`, { params });
  }

  /**
   * Create new user survey.
   */
  createSurvey(survey: Survey): Observable<SurveyResponse> {
    if (environment.cordova && !survey.uuid) {
      survey.uuid = AppHelper.uuid();
    }

    if (survey.start_date) {
      survey.start_date = moment(survey.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return !environment.cordova
      ? this.http.post<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}`, survey)
      : this.queryBuilder.create(survey);
  }

  /**
   * Update user survey.
   */
  updateSurvey(survey: Survey): Observable<SurveyResponse> {
    if (environment.cordova) {
      survey.updated_at = moment().format('YYYY/MM/DD HH:mm:ss');
    }

    if (survey.start_date) {
      survey.start_date = moment(survey.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    if (environment.cordova) {
      survey.updated_at = moment().format('YYYY/MM/DD HH:mm:ss');
    }

    return !environment.cordova
      ? this.http.patch<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}/${survey.id}`, survey)
      : this.queryBuilder.update(survey);
  }

  /**
   * Update user survey.
   */
  reallocateSurvey(survey: Survey): Observable<SurveyResponse> {
    if (survey.start_date) {
      survey.start_date = moment(survey.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return this.http.patch<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}/${survey.id}/reallocate`, survey);
  }

  /**
   * Delete user survey.
   */
  deleteSurvey(survey: Survey, createAction = false): Observable<any> {
    if (environment.cordova) {
      this.queryBuilder = new SurveyQueryBuilder();
      this.cascadeService.survey(survey);

      if (createAction) {
        const actionQueryBuilder = new ActionQueryBuilder();
        actionQueryBuilder.create({
          type: 'delete',
          uuid: survey.uuid,
          resource: 'surveys',
        });
      }
    }

    return !environment.cordova
      ? this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${survey.id}`)
      : this.queryBuilder.where('id', '=', survey.id).delete(survey);
  }

  /**
   * Delete survey media.
   */
  deleteSurveyMedia(survey: Survey, target: string, index: number): Observable<any> {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${survey.id}/media/${target}/${index}`);
  }

  /**
   * Get all Survey audit logs.
   */
  getAllSurveyAudits(surveyId: string): Observable<AuditsResponse> {
    return this.http.get<AuditsResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyId}/audits`);
  }

  /**
   * Get all un-synced surveys
   */
  getAllUnSyncedForms() {
    this.queryBuilder = new SurveyQueryBuilder();

    return this.queryBuilder
      .whereNull('synced_at')
      .whereNotNull('completed_at')
      .get();
  }

  /**
   * Sync survey.
   */
  syncSurvey(payload, appends: string[] = null): Observable<any> {
    let params = new HttpParams();

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload, { params });
  }

  settings(surveyId: string, payload: any): Observable<SurveyResponse> {
    return this.http.post<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}/${surveyId}/settings`, payload);
  }

  send(id: string, email: string) {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/${id}/send`, { email });
  }

  /**
   * Copy survey.
   */
  copySurvey(payload: any): Observable<SurveyResponse> {
    return this.http.post<SurveyResponse>(`${ApiService.ApiUrl}/${this.resource}/${payload.survey_id}/copy`, payload);
  }

  deleteResourceByUuid(resource: string, uuid: string) {
    return this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${resource}/${uuid}`);
  }
}
