import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs';
import { AreaResponse, AreasResponse } from '../responses/area.response';
import { Area } from '../models/area.model';
import { Parameter } from '../../shared/models/parameter.model';
import { AreaQueryBuilder } from '../../shared/classes/area.query-builder.class';
import { environment } from '../../../../environments/environment';
import { CascadeService } from '../../shared/services/cascade.service';
import * as moment from 'moment';
import { AppHelper } from '../../shared/classes/app.helper';
import { ActionQueryBuilder } from '../../shared/classes/action.query-builder.class';

@Injectable()
export class AreasService {
  private resource = 'areas';
  private queryBuilder: AreaQueryBuilder;

  constructor(private http: HttpClient, private cascadeService: CascadeService) {
    if (environment.cordova) {
      this.queryBuilder = new AreaQueryBuilder();
    }
  }

  /**
   * Get all areas.
   */
  getAllAreas(
    limit: number = 15,
    includes: string[] = null,
    sorting: string = null,
    appends: string[] = null,
    parameters: Parameter[] = [],
  ): Observable<AreasResponse> {
    let params = new HttpParams();

    params = params.set('limit', limit.toFixed(0));

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    if (appends) {
      params = params.set('append', appends.join(','));
    }

    if (sorting) {
      params = params.set('sort', sorting);
    }

    parameters.forEach(parameter => {
      if (parameter.value) {
        params = params.set(`filter[${parameter.field}]`, `${parameter.value}`);
      }
    });

    return this.http.get<AreasResponse>(`${ApiService.ApiUrl}/${this.resource}`, { params });
  }

  /**
   * Find area by id.
   */
  findAreaById(areaId: string, includes: string[] = null): Observable<AreaResponse> {
    let params = new HttpParams();

    if (includes) {
      params = params.set('include', includes.join(','));
    }

    return !environment.cordova
      ? this.http.get<AreaResponse>(`${ApiService.ApiUrl}/${this.resource}/${areaId}`, { params })
      : this.queryBuilder.findWithIncludes(+areaId, includes);
  }

  /**
   * Create new user area.
   */
  createArea(area: Area): Observable<AreaResponse> {
    if (environment.cordova && !area.uuid) {
      area.uuid = AppHelper.uuid();
    }

    return !environment.cordova
      ? this.http.post<AreaResponse>(`${ApiService.ApiUrl}/${this.resource}`, area)
      : this.queryBuilder.create(area);
  }

  /**
   * Update user area.
   */
  updateArea(area: Area): Observable<AreaResponse> {
    if (environment.cordova) {
      area.updated_at = moment().format('YYYY/MM/DD HH:mm:ss');
    }

    return !environment.cordova
      ? this.http.patch<AreaResponse>(`${ApiService.ApiUrl}/${this.resource}/${area.id}`, area)
      : this.queryBuilder.update(area);
  }

  /**
   * Delete user area.
   */
  deleteArea(area: Area, createAction = false): Observable<any> {
    if (environment.cordova) {
      this.queryBuilder = new AreaQueryBuilder();
      this.cascadeService.area(area);

      if (createAction) {
        const actionQueryBuilder = new ActionQueryBuilder();
        actionQueryBuilder.create({
          type: 'delete',
          uuid: area.uuid,
          resource: 'areas',
        });
      }
    }

    return !environment.cordova
      ? this.http.delete(`${ApiService.ApiUrl}/${this.resource}/${area.id}`)
      : this.queryBuilder.where('id', '=', area.id).delete(area);
  }

  /**
   * Sync area.
   */
  syncArea(payload): Observable<any> {
    return this.http.post<any>(`${ApiService.ApiUrl}/${this.resource}/sync`, payload);
  }
}
