import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-date',
  templateUrl: './input-type-date.component.html',
  styleUrls: ['./input-type-date.component.css']
})
export class InputTypeDateComponent extends InputTypeBaseFieldComponent implements OnInit {
  public dateMask = FormHelpers.dateMask;
  public setCurrentDateToQuestion = FormHelpers.setCurrentDateToQuestion;

  constructor() {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }
}
