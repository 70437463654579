import { BaseModel } from '../classes/query-builder.class';

export class FormTemplateQuestionOption extends BaseModel {
  public table: string = 'form_template_question_options';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_template_question_id: 'VARCHAR',
    option_text: 'VARCHAR',
    option_value: 'INTEGER',
    active: 'BOOLEAN'
  };
}
