import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { JobsResponse } from '../../../jobs/responses/job.response';
import { Job } from '../../../jobs/models/job.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JobsCreateComponent } from '../../../jobs/components/jobs-create/jobs-create.component';
import { SyncService } from '../../../shared/services/sync.service';
import { AssignmentQueryBuilder } from '../../../shared/classes/assignment.query-builder.class';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';
import { CascadeService } from '../../../shared/services/cascade.service';
import { SettingQueryBuilder } from '../../../shared/classes/setting.query-builder.class';

@Component({
  selector: 'app-home-technician',
  templateUrl: './home-technician.component.html',
  styleUrls: ['./home-technician.component.css']
})
export class HomeTechnicianComponent implements OnInit, OnDestroy {
  public gettingAllAuthenticatedUserJobs = false;
  public creatingJob = false;
  public jobsNumberSortingDirection = 1;
  public jobsDateSortingDirection = -1;
  public jobsSortedBy: string;
  public jobs: Job[];
  public job: Job;
  public meta: Meta;
  public links: Links;
  private bsModalRef: BsModalRef;
  private subscriptions = new Subscription();
  public filter = 'open';
  public downloading = false;

  constructor(
    private modalService: BsModalService,
    private snotifyService: SnotifyService,
    private syncService: SyncService,
    private busService: BusService,
    private cascadeService: CascadeService,
  ) {}

  ngOnInit() {
    // Run sql migrations
    this.syncService.initQueryBuilders();
    this.checkDownloads();

    this.getAllAuthenticatedUserJobs();
    this.subscriptions.add(this.busService.listen('refreshJobs').subscribe(() => {
      this.getAllAuthenticatedUserJobs();
      this.downloading = false;
      this.updateSyncDate();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async checkDownloads() {
    const fetchedAt = await this.getSyncDate();
    if (moment(fetchedAt).isBefore(moment().subtract(2, 'h')) || !fetchedAt) {
      await this.downloadJobs();
    }
  }

  async downloadJobs() {
    this.downloading = true;
    await this.syncService.forceSyncAPI();
    await this.updateSyncDate();
  }

  getAllAuthenticatedUserJobs() {
    const assignmentQueryBuilder = new AssignmentQueryBuilder();
    const includes = ['project', 'client'];
    let open = null;

    if (this.filter === 'open') {
      open = 1;
    }

    if (this.filter === 'closed') {
      open = 0;
    }

    const getAllAuthenticatedUserJobs = assignmentQueryBuilder.getWithIncludes(includes, open, 'start_date');
    this.gettingAllAuthenticatedUserJobs = true;

    getAllAuthenticatedUserJobs.subscribe(
      (response: JobsResponse) => {
        this.gettingAllAuthenticatedUserJobs = false;
        this.updateJobsList(response);
      },
      () => {
        this.gettingAllAuthenticatedUserJobs = false;
        this.snotifyService.error('Unable to load jobs. Please try again!', 'Error!');
      }
    );
  }

  updateJobsList(response: JobsResponse) {
    this.jobs = response.data;
    this.jobs.map(job => (job.address = job.site_name ? job.site_name : job.address));
    this.meta = response.meta;
    this.links = response.links;
  }

  sortJobsByNumber() {
    const argument = 'number';
    this.jobsSortedBy = argument;
    this.jobsNumberSortingDirection = -1 * this.jobsNumberSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (a[argument] < b[argument]) {
        return -1 * this.jobsNumberSortingDirection;
      } else if (a[argument] > b[argument]) {
        return this.jobsNumberSortingDirection;
      } else {
        return 0;
      }
    });
  }

  sortJobsByDate() {
    const argument = 'start_date';
    this.jobsSortedBy = argument;
    this.jobsDateSortingDirection = -1 * this.jobsDateSortingDirection;

    this.jobs = this.jobs.sort((a: any, b: any) => {
      if (this.jobsDateSortingDirection === 1) {
        return moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss'));
      }

      return moment.utc(a[argument], 'YYYY-MM-DD HH:mm:ss').diff(moment.utc(b[argument], 'YYYY-MM-DD HH:mm:ss'));
    });
  }

  onNewJob() {
    this.bsModalRef = this.modalService.show(JobsCreateComponent);
  }

  switchFilter(filter) {
    this.filter = filter;
    this.getAllAuthenticatedUserJobs();
  }

  onDelete(job: Job) {
    this.snotifyService.confirm('The selected Job and all related data will be erased from this device.', 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteJob(job),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  async deleteJob(job: Job) {
    await this.cascadeService.job(job);
    this.snotifyService.success(`Job ${job.number} and all related data have been successfully removed.`);
    this.getAllAuthenticatedUserJobs();
  }

  async updateSyncDate() {
    const settingQueryBuilder = new SettingQueryBuilder();
    const settings = await settingQueryBuilder.get().toPromise();

    if (!settings || !settings.data || settings.data.length === 0) {
      settingQueryBuilder.create({
        fetched_at: moment().format('YYYY/MM/DD HH:mm:ss'),
      });
    }
  }

  async getSyncDate() {
    const settingQueryBuilder = new SettingQueryBuilder();
    const settings = await settingQueryBuilder.get().toPromise();

    if (!settings || !settings.data || settings.data.length === 0) {
      return null;
    }

    return settings.data[0].fetched_at;
  }
}
