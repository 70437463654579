import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Job } from '../../../jobs/models/job.model';
import { JobsResponse } from '../../../jobs/responses/job.response';
import { JobsService } from '../../../jobs/services/jobs.service';

@Component({
  selector: 'app-jobs-search',
  templateUrl: './jobs-search.component.html',
  styleUrls: ['./jobs-search.component.css'],
})
export class JobsSearchComponent implements OnInit, OnDestroy {
  @Input() hint: string;
  @Input() jobNumber: string;
  @Output() jobSelected: EventEmitter<Job> = new EventEmitter();
  public searching = false;
  public jobs: Job[] = [];
  public subscriptions = new Subscription();

  constructor(private jobsService: JobsService) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  findJob() {
    this.searching = true;
    const includes = ['users'];
    const filter = null;
    const search = this.jobNumber;

    this.jobsService.getAllJobs(includes, filter, search).subscribe(
      (response: JobsResponse) => {
        this.jobs = response.data;
        this.searching = false;
      },
      () => {
        this.searching = false;
      },
    );
  }

  selectJob(job: Job) {
    this.jobs = [];
    this.jobNumber = job.number;
    this.jobSelected.emit(job);
  }

}
