import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientsIndexComponent } from './components/clients-index/clients-index.component';
import { ClientsCreateComponent } from './components/clients-create/clients-create.component';
import { ClientsUpdateComponent } from './components/clients-update/clients-update.component';
import { UsersIndexComponent } from './components/users-index/users-index.component';
import { UsersCreateComponent } from './components/users-create/users-create.component';
import { UsersUpdateComponent } from './components/users-update/users-update.component';
import { RoleGuard } from '../shared/guards/role.guard';
import { SitesIndexComponent } from './components/sites-index/sites-index.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'index'
  },
  {
    path: 'index',
    component: ClientsIndexComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: 'create',
    component: ClientsCreateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: 'update/:client_id',
    component: ClientsUpdateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: ':client_id/users',
    component: UsersIndexComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'client_admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: ':client_id/users/create',
    component: UsersCreateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'client_admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: ':client_id/users/update/:user_id',
    component: UsersUpdateComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'client_admin', 'view_all', 'admin_assistant']
    }
  },
  {
    path: ':client_id/sites',
    component: SitesIndexComponent,
    canActivate: [RoleGuard],
    data: {
      roles: ['admin', 'client_admin', 'view_all', 'admin_assistant']
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
