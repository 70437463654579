import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '../../../projects/models/project.model';
import { ProjectsResponse } from '../../../projects/responses/project.response';
import { ProjectsService } from '../../../projects/services/projects.service';

@Component({
  selector: 'app-projects-search',
  templateUrl: './projects-search.component.html',
  styleUrls: ['./projects-search.component.css'],
})
export class ProjectsSearchComponent implements OnInit {
  @Output() projectSelected: EventEmitter<Project> = new EventEmitter();
  @Input() hint: string;
  @Input() clientId: string;
  public findingProject = false;
  public projects: Project[] = [];
  public search = null;

  constructor(private projectsService: ProjectsService) { }

  ngOnInit() {}

  findProject() {
    this.findingProject = true;
    const limit = 20;
    const includes = [];
    const appends = [];
    const sorting = 'title';
    const search = this.search;
    const status = null;

    this.projectsService.getAllProjects(limit, includes, sorting, appends, search, status, this.clientId).subscribe(
      (response: ProjectsResponse) => {
        this.projects = response.data;
        this.search = null;
        this.findingProject = false;
      },
      () => {
        this.search = null;
        this.findingProject = false;
      },
    );
  }

  selectProject(project: Project) {
    this.projects = [];
    this.projectSelected.emit(project);
  }

}
