import { Component, OnDestroy, OnInit } from '@angular/core';
import { Audit } from '../../../shared/models/audit.model';
import { ActivatedRoute } from '@angular/router';
import { FormsService } from '../../services/forms.service';
import { SnotifyService } from 'ng-snotify';
import { AuditsResponse } from '../../../shared/responses/audit.response';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-forms-audit-log',
  templateUrl: './forms-audit-log.component.html',
  styleUrls: ['./forms-audit-log.component.css'],
})
export class FormsAuditLogComponent implements OnInit, OnDestroy {
  public audits: Audit[];
  public gettingAllFormAudits = false;
  public meta: Meta;
  public links: Links;
  private subscriptions: Subscription = new Subscription();
  private form_id: string;

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private snotifyService: SnotifyService,
    private busService: BusService,
  ) {
  }

  ngOnInit() {
    this.addSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.form_id = params['form_id'];
        this.getAllFormAudits();
      }),
    );

    this.subscriptions.add(
      this.busService.listen('loading').subscribe(event => {
        this.gettingAllFormAudits = event.data;
      }),
    );

    this.subscriptions.add(
      this.busService.listen('pagination').subscribe(event => {
        this.updateAuditsList(event.data);
      }),
    );
  }

  getAllFormAudits() {
    this.gettingAllFormAudits = true;

    this.formsService.getAllFormAudits(this.form_id).subscribe(
      (response: AuditsResponse) => {
        this.gettingAllFormAudits = false;
        this.updateAuditsList(response);
      },
      () => {
        this.gettingAllFormAudits = false;
        this.snotifyService.error('Unable to load form audit log. Please try again.', 'Error!');
      },
    );
  }

  updateAuditsList(response: AuditsResponse) {
    this.audits = response.data;
    this.meta = response.meta;
    this.links = response.links;
  }
}
