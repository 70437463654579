import { Component, OnDestroy, OnInit } from '@angular/core';
import { Form } from '../../models/form.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from '../../services/forms.service';
import { FormResponse } from '../../responses/form.response';
import { SnotifyService } from 'ng-snotify';
import { DataTypeEnum } from '../../../shared/enums/data-type.enum';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormSection } from '../../models/form-section.model';
import { Subscription } from 'rxjs';
import { Sample } from '../../models/sample.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-forms-view',
  templateUrl: './forms-view.component.html',
  styleUrls: ['./forms-view.component.css']
})
export class FormsViewComponent implements OnInit, OnDestroy {
  public form: Form;
  private form_id: string;
  private paramsSubscription: Subscription;
  public findingFormById = false;
  public data_types = DataTypeEnum;
  public questionHasImage = FormHelpers.questionHasImage;
  public activeSectionId: string;
  public cordova = environment.cordova;

  constructor(
    private route: ActivatedRoute,
    private formsService: FormsService,
    private snotifyService: SnotifyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.form_id = params['form_id'];
      this.findFormById();
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  findFormById() {
    this.findingFormById = true;
    const includes = [
      'site',
      'comments',
      'assignment',
      'form_sections',
      'form_sections.form_template_section',
      'form_sections.form_template_section.form_template_questions',
      'form_sections.form_template_section.form_template_questions.form_template_question_options',
      'form_sections.form_answers',
      'samples.form_sections',
      'samples.form_sections.form_template_section',
      'samples.form_sections.form_template_section.form_template_questions',
      'samples.form_sections.form_template_section.form_template_questions.form_template_question_options',
      'samples.form_sections.form_answers',
    ];

    this.formsService.findFormById(this.form_id, includes).subscribe(
      (response: FormResponse) => {
        this.findingFormById = false;
        this.form = response.data;
        this.passFormAnswersToQuestions();
        this.passFormSamplesAnswersToQuestions();
        this.activeSectionId = this.form.form_sections.data[0].id;
      },
      () => {
        this.findingFormById = false;
        this.snotifyService.error('Unable to load form. Please try again.', 'Error!');
      }
    );
  }

  startForm(form: Form) {
    if (form.completed_at) {
      return this.snotifyService.info('The selected form is already completed. Please try another one.', 'Completed form!');
    }

    return this.router.navigate(['/forms/fill', form.id]);
  }

  passFormAnswersToQuestions() {
    this.form.form_sections.data = this.form.form_sections.data.map((section: FormSection) => {
      section.form_template_section.data.form_template_questions.data = section.form_template_section.data.form_template_questions.data.map(
        (question: FormTemplateQuestion) => {
          return this.passAnswerToQuestion(section, question);
        }
      );

      return section;
    });
  }

  passFormSamplesAnswersToQuestions() {
    this.form.samples.data = this.form.samples.data.map((sample: Sample) => {
      sample.form_sections.data = sample.form_sections.data.map((section: FormSection) => {
        section.form_template_section.data.form_template_questions.data = section.form_template_section.data.form_template_questions.data.map(
          (question: FormTemplateQuestion) => {
            return this.passAnswerToQuestion(section, question);
          }
        );

        return section;
      });

      return sample;
    });
  }

  passAnswerToQuestion(section: FormSection, question: FormTemplateQuestion) {
    const attribute = this.cordova ? 'hashed_id' : 'id';
    const answer = section.form_answers.data.find(element => element.form_template_question_id === question[attribute]);

    if (answer) {
      question.answer = answer;
    }

    if (answer && question.form_template_question_options.data.length > 0 && question.input_type !== DataTypeEnum['CHECKBOXES']) {
      const selectedOption = question.form_template_question_options.data.find(option => option.option_value === +answer.value);

      question.answer.value = selectedOption ? selectedOption.option_text : null;
    }

    if (answer && answer.value && question.form_template_question_options.data.length > 0 && question.input_type === DataTypeEnum['CHECKBOXES']) {
      const answerValues = answer.value.split('|');
      const values = [];
      answerValues.forEach(value => {
        const selectedOption = question.form_template_question_options.data.find(option => option.option_value === +value);
        const text = selectedOption ? selectedOption.option_text : null;
        values.push(text);
      });

      question.answer.value = values.join(', ');
    }

    if (
      answer &&
      answer.value &&
      [DataTypeEnum['DRAWING'], DataTypeEnum['PHOTO'], DataTypeEnum['SIGNATURE']].includes(question.input_type)
    ) {
      question.answer.value = answer.value.split('|');
    }

    if (question.js_id === 'sample_number' && question.answer) {
      section.form_template_section.data.title += ` - Sample Number: ${question.answer.value}`;
    }

    return question;
  }

  onSectionHeaderClick(section) {
    this.activeSectionId = this.activeSectionId === section.id ? null : section.id;

    setTimeout(() => {
      document.getElementById(`${section.id}`).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }, 100);
  }
}
