import { BaseModel } from '../classes/query-builder.class';

export class FormAnswer extends BaseModel {
  public table: string = 'form_answers';
  public schema: Object = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    form_id: 'INTEGER',
    form_hashed_id: 'VARCHAR',
    form_section_id: 'INTEGER',
    form_section_hashed_id: 'VARCHAR',
    form_template_question_id: 'VARCHAR',
    value: 'TEXT',
    comment: 'TEXT',
    input_type: 'INTEGER',
    created_at: 'DATETIME',
  };
}
