import { QueryBuilder } from './query-builder.class';
import { Action } from '../entities/action.entity';

/**
 * Action Entity Query Builder.
 */
export class ActionQueryBuilder extends QueryBuilder {
  constructor(migrate = false) {
    super(new Action(), migrate);
  }
}
