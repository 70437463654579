import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { ComponentTemplatesService } from '../../services/component-templates.service';
import { ComponentTemplateResponse } from '../../responses/component-template.response';
import { Router } from '@angular/router';
import {AppHelper} from "../../../shared/classes/app.helper";

@Component({
  selector: 'app-component-templates-create',
  templateUrl: './component-templates-create.component.html',
  styleUrls: ['./component-templates-create.component.css'],
})
export class ComponentTemplatesCreateComponent implements OnInit {
  public formGroup: FormGroup;
  public postingComponentTemplate = false;

  constructor(
    public bsModalRef: BsModalRef,
    private componentTemplatesService: ComponentTemplatesService,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      name: [null],
    });
  }

  onSubmit() {
    this.postingComponentTemplate = true;
    const payload = this.formGroup.getRawValue();

    this.componentTemplatesService.createComponentTemplate(payload).subscribe(
      (response: ComponentTemplateResponse) => {
        this.bsModalRef.hide();
        this.postingComponentTemplate = false;
        AppHelper.chime();
        return this.router.navigate(['/component-templates/update', response.data.id]);
      },
      () => {
        this.postingComponentTemplate = false;
        this.snotifyService.error('Unable to create Survey Component. Please try again.', 'Error!');
      },
    );
  }

}
