import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormTemplateQuestion } from '../../models/form-template-question.model';
import { FormHelpers } from '../../classes/form-helpers.class';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-signature',
  templateUrl: './input-type-signature.component.html',
  styleUrls: ['./input-type-signature.component.css']
})
export class InputTypeSignatureComponent extends InputTypeBaseFieldComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  drawComplete(question: FormTemplateQuestion) {
    this.fieldValidation(question);
  }
}
