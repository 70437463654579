import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FloorTypeEnum } from '../../../shared/enums/floor-type.enum';
import { AppHelper } from '../../../shared/classes/app.helper';
import { Floor } from '../../models/floor.model';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { FloorsService } from '../../services/floors.service';

@Component({
  selector: 'app-floors-update',
  templateUrl: './floors-update.component.html',
  styleUrls: ['./floors-update.component.css'],
})
export class FloorsUpdateComponent implements OnInit {
  public formGroup: FormGroup;
  public postingFloor = false;
  public onHide = new Subject();
  public floor: Floor;
  public floorTypeEnum = FloorTypeEnum;
  public buttons = AppHelper.floorButtons;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private floorsService: FloorsService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      id: [this.floor.id],
      uuid: [this.floor.uuid],
      building_id: [this.floor.building_id],
      type: [this.floor.type, [Validators.required]],
      number: [this.floor.number],
    });
  }

  onSubmit() {
    this.postingFloor = true;
    const payload = this.formGroup.getRawValue();

    this.floorsService.updateFloor(payload).subscribe(
      () => {
        this.bsModalRef.hide();
        this.onHide.next();
        this.postingFloor = false;
        AppHelper.chime();
      },
      () => {
        this.postingFloor = false;
        this.snotifyService.error('Unable to create Floor. Please try again.', 'Error!');
      },
    );
  }

}
