import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { FloorsService } from '../../services/floors.service';
import { FloorTypeEnum } from '../../../shared/enums/floor-type.enum';
import { AppHelper } from '../../../shared/classes/app.helper';

@Component({
  selector: 'app-floors-create',
  templateUrl: './floors-create.component.html',
  styleUrls: ['./floors-create.component.css'],
})
export class FloorsCreateComponent implements OnInit {
  public formGroup: FormGroup;
  public postingFloor = false;
  public buildingId: string;
  public onHide = new Subject();
  public floorTypeEnum = FloorTypeEnum;
  public buttons = AppHelper.floorButtons;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private floorsService: FloorsService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      building_id: [this.buildingId],
      type: [FloorTypeEnum.External, [Validators.required]],
      number: [],
    });
  }

  onSubmit() {
    this.postingFloor = true;
    const payload = this.formGroup.getRawValue();

    this.floorsService.createFloor(payload).subscribe(
      () => {
        this.bsModalRef.hide();
        this.onHide.next();
        this.postingFloor = false;
        AppHelper.chime();
      },
      () => {
        this.postingFloor = false;
        this.snotifyService.error('Unable to create Floor. Please try again.', 'Error!');
      },
    );
  }

}
