import { Component, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { FormTemplateQuestionOption } from '../../models/form-template-question-option.model';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-checkboxes',
  templateUrl: './input-type-checkboxes.component.html',
  styleUrls: ['./input-type-checkboxes.component.css']
})
export class InputTypeCheckboxesComponent extends InputTypeBaseFieldComponent implements OnInit {
  String = String;
  constructor() {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  checkboxState(option) {
    return this.question.answer.value
      ? this.question.answer.value.toString()
          .split('|')
          .map(e => +e)
          .includes(option.option_value)
      : false;
  }

  onCheckboxChange(option: FormTemplateQuestionOption) {
    const array = this.question.answer.value ? this.question.answer.value.toString().split('|') : [];
    const exists = array.find(item => +item === +option.option_value);
    if (exists === undefined) {
      array.push(option.option_value);
    } else {
      const itemIndex = array.findIndex(item => +item === +option.option_value);
      array.splice(itemIndex, 1);
    }
    this.question.answer.value = array.length > 0 ? array.join('|') : null;
    this.fieldValidation(this.question);
  }
}
