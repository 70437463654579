import { BaseModel } from '../classes/query-builder.class';

export class Assignment extends BaseModel {
  public table = 'assignments';
  public schema = {
    id: 'INTEGER PRIMARY KEY AUTOINCREMENT',
    hashed_id: 'VARCHAR',
    site_id: 'VARCHAR',
    client_id: 'VARCHAR',
    project_id: 'VARCHAR',
    user_id: 'VARCHAR',
    name: 'VARCHAR',
    client_job_number: 'VARCHAR',
    number: 'VARCHAR',
    open: 'INTEGER DEFAULT 1',
    site_name: 'VARCHAR',
    address: 'VARCHAR',
    company_uuid: 'VARCHAR',
    start_date: 'DATETIME'
  };
}
