import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inventory } from '../../models/inventory.model';
import { InventoriesService } from '../../services/inventories.service';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryResponse } from '../../responses/inventory.response';
import { UsersResponse } from '../../../auth/responses/user.response';
import { User } from '../../../auth/models/user.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TechniciansIndexComponent } from '../technicians-index/technicians-index.component';
import { FormsResponse } from '../../../forms/responses/form.response';
import { Form } from '../../../forms/models/form.model';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';
import { Subscription } from 'rxjs';
import { Links, Meta } from '../../../shared/models/pagination.model';

@Component({
  selector: 'app-inventory-view',
  templateUrl: './inventory-view.component.html',
  styleUrls: ['./inventory-view.component.css']
})
export class InventoryViewComponent implements OnInit, OnDestroy {
  private inventory: Inventory;
  public users: User[];
  public forms: Form[];
  public findingInventoryById = false;
  public gettingAllInventoryUsers = false;
  public gettingAllInventoryForms = false;
  public attachingInventoryUser = false;
  private paramsSubscription: Subscription;
  private inventory_id: string;
  public metaUsers: Meta;
  public linksUsers: Links;
  public metaForms: Meta;
  public linksForms: Links;
  private bsModalRef: BsModalRef;
  public inventoryTypeEnum = InventoryTypeEnum;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snotifyService: SnotifyService,
    private inventoriesService: InventoriesService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.inventory_id = params['inventory_id'];
      this.findInventoryById();
      this.getAllInventoryUsers();
      this.getAllInventoryForms();
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  findInventoryById() {
    this.findingInventoryById = true;

    this.inventoriesService.findInventoryById(this.inventory_id).subscribe(
      (response: InventoryResponse) => {
        this.inventory = response.data;
        this.findingInventoryById = false;
      },
      error => {
        this.findingInventoryById = false;
        this.snotifyService.error('Unable to load inventory item. Please try again.', 'Error!');
      }
    );
  }

  getAllInventoryUsers() {
    this.gettingAllInventoryUsers = true;

    this.inventoriesService.getAllInventoryUsers(this.inventory_id).subscribe(
      (response: UsersResponse) => {
        this.gettingAllInventoryUsers = false;
        this.updateUsersList(response);
      },
      error => {
        this.gettingAllInventoryUsers = false;
        this.snotifyService.error('Unable to load inventory users. Please try again.', 'Error!');
      }
    );
  }

  getAllInventoryForms() {
    this.gettingAllInventoryForms = true;

    this.inventoriesService.getAllInventoryForms(this.inventory_id).subscribe(
      (response: FormsResponse) => {
        this.gettingAllInventoryForms = false;
        this.updateFormsList(response);
      },
      error => {
        this.gettingAllInventoryForms = false;
        this.snotifyService.error('Unable to load inventory forms. Please try again.', 'Error!');
      }
    );
  }

  updateUsersList(response: UsersResponse) {
    this.users = response.data;
    this.metaUsers = response.meta;
    this.linksUsers = response.links;
  }

  updateFormsList(response: FormsResponse) {
    this.forms = response.data;
    this.metaForms = response.meta;
    this.linksForms = response.links;
  }

  onUserDetach(user: User) {
    this.snotifyService.confirm(`User "${user.name}" is going to be detached from current inventory item.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.detachInventoryUser(user),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  detachInventoryUser(user: User) {
    user.detaching = true;

    this.inventoriesService.detachInventoryUser(this.inventory_id, user.id, user.pivot.used_from).subscribe(
      response => {
        user.detaching = false;
        this.snotifyService.success('The user has been successfully detached from current inventory item.', 'Success!');
        this.getAllInventoryUsers();
        this.findInventoryById();
      },
      error => {
        user.detaching = false;
        this.snotifyService.error('Unable to detach user from current inventory item.', 'Error!');
      }
    );
  }

  onUserDelete(user: User) {
    this.snotifyService.confirm(`User "${user.name}" is going to be removed from current inventory item.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteInventoryUser(user),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteInventoryUser(user: User) {
    user.deleting = true;

    this.inventoriesService.deleteInventoryUser(this.inventory_id, user.id, user.pivot.used_from).subscribe(
      response => {
        user.deleting = false;
        this.snotifyService.success('The user has been successfully deleted from current inventory item.', 'Success!');
        this.getAllInventoryUsers();
      },
      error => {
        user.deleting = false;
        this.snotifyService.error('Unable to delete user from current inventory item.', 'Error!');
      }
    );
  }

  attachInventoryUser(user: User) {
    this.attachingInventoryUser = true;

    this.inventoriesService.attachInventoryUser(this.inventory_id, user.id).subscribe(
      response => {
        this.attachingInventoryUser = false;
        this.snotifyService.success('The user has been successfully attached to current inventory item.', 'Success!');
        this.getAllInventoryUsers();
        this.findInventoryById();
      },
      error => {
        this.attachingInventoryUser = false;
        this.snotifyService.error('Unable to attach user to current inventory item.', 'Error!');
      }
    );
  }

  onUserAssign() {
    this.bsModalRef = this.modalService.show(TechniciansIndexComponent, {'class': 'modal-lg'});
    this.bsModalRef.content.onUserSelect.subscribe(user => this.attachInventoryUser(user));
  }

  onInventoryDelete() {
    this.snotifyService.confirm(`Inventory item "${this.inventory.name}" is going to be deleted.`, 'Are you sure?', {
      timeout: 5000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      buttons: [
        {
          text: 'Yes',
          action: () => this.deleteInventory(),
          bold: false
        },
        { text: 'No', action: () => {} }
      ]
    });
  }

  deleteInventory() {
    this.inventory.deleting = true;

    this.inventoriesService.deleteInventory(this.inventory).subscribe(
      response => {
        this.inventory.deleting = false;
        this.snotifyService.success(`Inventory item "${this.inventory.name}" has been successfully deleted.`, 'Success!');
        return this.router.navigate(['/inventory']);
      },
      error => {
        this.inventory.deleting = false;
        this.snotifyService.error('Unable to delete inventory item. Please try again.', 'Error!');
      }
    );
  }
}
