import { Component, OnInit } from '@angular/core';
import { Form } from '../../../forms/models/form.model';
import { BsModalRef } from 'ngx-bootstrap';
import { FormsService } from '../../../forms/services/forms.service';
import { FormResponse } from '../../../forms/responses/form.response';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-forms-submit-modal',
  templateUrl: './forms-submit-modal.component.html',
  styleUrls: ['./forms-submit-modal.component.css'],
})
export class FormsSubmitModalComponent implements OnInit {
  public form: Form;
  public email: string;
  public sending = false;

  constructor(
    public bsModalRef: BsModalRef,
    private formsService: FormsService,
    private snotifyService: SnotifyService,
  ) { }

  ngOnInit() {}

  send() {
    this.sending = true;
    const id = environment.cordova ? 'hashed_id' : 'id';

    this.formsService.send(this.form[id], this.email).subscribe(
      (response: FormResponse) => {
        this.sending = false;
        this.bsModalRef.hide();
        this.snotifyService.success('The email has been successfully sent!', 'Success!');
      },
      () => {
        this.sending = false;
        this.snotifyService.error('Unable to send email to the given address! Please try again with a different email.', 'Error!');
      },
    );
  }

}
