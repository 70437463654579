import { Component, Input, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import { Inventory } from '../../../inventory/models/inventory.model';
import { BusService } from '../../../shared/services/bus.service';
import { Form } from '../../models/form.model';
import { Sample } from '../../models/sample.model';
import { InventoryTypeEnum } from '../../../shared/enums/inventory-type.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-input-type-drop-down',
  templateUrl: './input-type-drop-down.component.html',
  styleUrls: ['./input-type-drop-down.component.css']
})
export class InputTypeDropDownComponent extends InputTypeBaseFieldComponent implements OnInit {
  @Input('inventories') inventories: Inventory[];
  @Input('form') form: Form;
  @Input('sample') sample: Sample;
  public numberOfSamples: number;
  public inventoryTypeEnum = InventoryTypeEnum;
  public parseInt = parseInt;

  constructor(private busService: BusService) {
    super();
  }

  ngOnInit() {
    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }

    this.checkApplicableJSId();
  }

  onSelectChange() {
    this.checkApplicableJSId();

    this.fieldValidation(this.question);
  }

  checkApplicableJSId() {
    if (this.question.js_id === 'applicable') {
      const isApplicable = +this.question.answer.value === 1 || this.question.answer.value === null;
      this.busService.dispatch({type: 'applicable', data: isApplicable});

      const sectionValidationEnabled = +this.question.answer.value === 1 || this.question.answer.value === null;
      this.busService.dispatch({type: 'filter_occluded', data: sectionValidationEnabled});
    }
  }
}
