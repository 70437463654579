import { FormGroup, Validators } from '@angular/forms';
import { FloorTypeEnum } from '../enums/floor-type.enum';
import * as moment from 'moment';
import { Floor } from '../../floors/models/floor.model';
import { BuildingTypeEnum } from '../enums/building-type.enum';
import { YesNoEnum } from '../enums/yes-no.enum';
import { Survey } from '../../surveys/models/survey.model';

export class AppHelper {
  public static floorButtons: { name: string; value: number; }[] = [
    {
      name: FloorTypeEnum[FloorTypeEnum.External],
      value: FloorTypeEnum.External,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Roof],
      value: FloorTypeEnum.Roof,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Basement],
      value: FloorTypeEnum.Basement,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Ground_Floor],
      value: FloorTypeEnum.Ground_Floor,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Mezzanine],
      value: FloorTypeEnum.Mezzanine,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Loft],
      value: FloorTypeEnum.Loft,
    },
    {
      name: FloorTypeEnum[FloorTypeEnum.Number],
      value: FloorTypeEnum.Number,
    },
  ];

  public static setReferenceReport(survey: Survey) {
    const thirdPartyNumbers = Array.isArray(survey.corresponding_third_party_numbers)
      ? survey.corresponding_third_party_numbers
      : survey.corresponding_third_party_numbers !== null
      ? survey.corresponding_third_party_numbers.split(',')
      : [];

    const zampaSurvey = survey.corresponding_zampa;
    const thirdPartySurveyNum = !survey.corresponding_third_party ? 0 : thirdPartyNumbers.length;

    const sampleRef = thirdPartySurveyNum > 0;

    const numbers = [];

    if (zampaSurvey) {
      numbers.push(survey.corresponding_survey_number);
    }

    if (thirdPartyNumbers.length > 0) {
      numbers.push(...thirdPartyNumbers);
    }

    const reportRef = numbers.length > 0;

    return [reportRef, sampleRef, numbers] as [boolean, boolean, string[]];
  }

  public static surveyControlsConfig(withPhoto = true) {
    const config = {
      id: [null, [Validators.required]],
      uuid: [null],
      assignment_id: [null, [Validators.required]],
      site_id: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      status: [null, [Validators.required]],
      survey_type: [null, [Validators.required]],
      corresponding_zampa: [0],
      corresponding_survey_id: [null],
      corresponding_survey_number: [null],
      corresponding_third_party: [0],
      corresponding_third_party_numbers: [null],
      access_given: [false],
      archived: [false],
      commissioner: [null],
      date_from: [null],
      date_to: [null],
      site_photo: [null],
      site_plan: [null],
      certificate: [null],
      building_type: [BuildingTypeEnum.Residential_Domestic, [Validators.required]],
      building_usage: [null],
      purpose: [null, [Validators.required, Validators.maxLength(250)]],
      construction_date: [null],
      construction_type: [null],
      scopes_agreed: [YesNoEnum.Yes, [Validators.required]],
      scope: [null],
      areas_description: [null, [Validators.required]],
      exclusions: [null, [Validators.required]],
      deviations: [YesNoEnum.No, [Validators.required]],
      deviations_description: [null],
      reported_to_client: [YesNoEnum.Yes],
    };

    if (!withPhoto) {
      delete config.site_photo;
      delete config.site_plan;
      delete config.certificate;
    }

    return config;
  }

  public static surveyRequiredFields() {
    return [
      'building_type',
      'building_usage',
      'purpose',
      'construction_date',
      'construction_type',
      'scopes_agreed',
      'areas_description',
      'exclusions',
      'deviations',
    ];
  }

  public static surveyRequiredFieldsValidators() {
    return {
      building_type: [Validators.required],
      purpose: [Validators.required, Validators.maxLength(250)],
      scopes_agreed: [Validators.required],
      areas_description: [Validators.required],
      exclusions: [Validators.required],
      deviations: [Validators.required],
    };
  }

  public static surveyControlsConfigHistoric(withPhoto = true) {
    const config = {
      id: [null, [Validators.required]],
      uuid: [null],
      assignment_id: [null, [Validators.required]],
      site_id: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      status: [null, [Validators.required]],
      survey_type: [null, [Validators.required]],
      corresponding_zampa: [0],
      corresponding_survey_id: [null],
      corresponding_survey_number: [null],
      corresponding_third_party: [0],
      corresponding_third_party_numbers: [null],
      access_given: [false],
      archived: [true],
      commissioner: [null],
      date_from: [null],
      date_to: [null],
      site_photo: [null],
      site_plan: [null],
      certificate: [null],
      building_type: [BuildingTypeEnum.Residential_Domestic],
      building_usage: [null],
      purpose: [null],
      construction_date: [null],
      construction_type: [null],
      scopes_agreed: [YesNoEnum.Yes],
      scope: [null],
      areas_description: [null],
      exclusions: [null],
      deviations: [YesNoEnum.No],
      deviations_description: [null],
      reported_to_client: [YesNoEnum.Yes],
    };

    if (!withPhoto) {
      delete config.site_photo;
      delete config.site_plan;
      delete config.certificate;
    }

    return config;
  }

  /**
   * Return an array of plucked field values.
   */
  public static pluck(array: any[], field: string): any[] {
    const result = [];

    array.forEach(item => {
      if (!AppHelper.isNullOrUndefined(item[field])) {
        result.push(item[field]);
      }
    });

    return result;
  }

  /**
   * Check if value is null or undefined.
   */
  public static isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  /**
   * Fills form group with common object key values.
   */
  public static fillFormGroup(formGroup: FormGroup, object: {}) {
    for (const key in object) {
      if (
        object.hasOwnProperty(key) &&
        formGroup.controls.hasOwnProperty(key)
      ) {
        formGroup.controls[key].setValue(object[key]);
      }
    }
  }

  /**
   * Get current time, timestamp.
   */
  public static getTimestamp() {
    return moment().utc().format('YYYY-MM-DD HH:mm:ss');
  }

  public static toNumber(value?: string | number): number {
    if (typeof value === 'undefined') {
      return NaN;
    }

    if (typeof value === 'number') {
      return value;
    }

    return parseInt(value, 10);
  }

  public static isNumber(value: string | number): value is number {
    return !isNaN(AppHelper.toNumber(value));
  }

  /**
   * Generate floor number title.
   */
  public static getFloorName(floor: Floor) {
    if (floor.type !== FloorTypeEnum.Number) {
      return FloorTypeEnum[floor.type].replace('_', ' ');
    }

    if (!AppHelper.isNumber(floor.number)) {
      return `${floor.number} floor`;
    }

    if (+floor.number === 1) {
      return '1st floor';
    }

    if (+floor.number === 2) {
      return '2nd floor';
    }

    if (+floor.number === 3) {
      return '3rd floor';
    }

    if (+floor.number === 21) {
      return '21st floor';
    }

    if (+floor.number === 22) {
      return '22nd floor';
    }

    if (+floor.number === 23) {
      return '23rd floor';
    }

    if (+floor.number === 31) {
      return '31st floor';
    }

    if (+floor.number === 32) {
      return '32nd floor';
    }

    if (+floor.number === 33) {
      return '33rd floor';
    }

    if (+floor.number === 41) {
      return '41st floor';
    }

    if (+floor.number === 42) {
      return '42nd floor';
    }

    if (+floor.number === 43) {
      return '43rd floor';
    }

    return `${floor.number}th floor`;
  }

  public static getFloorCode(floor: Floor) {
    if (floor.type === FloorTypeEnum.External) {
      return 'E';
    }

    if (floor.type === FloorTypeEnum.Roof) {
      return 'R';
    }

    if (floor.type === FloorTypeEnum.Basement) {
      return 'B';
    }

    if (floor.type === FloorTypeEnum.Mezzanine) {
      return 'MZ';
    }

    if (floor.type === FloorTypeEnum.Loft) {
      return 'LO';
    }

    if (floor.type === FloorTypeEnum.Ground_Floor) {
      return 'G';
    }

    return floor.number;
  }

  public static getSubIncludes(includes: string[] = [], startsWith: string) {
    return includes.map(include => {
      if (include.startsWith(startsWith)) {
        return include.replace(startsWith, '');
      }
    }).filter(include => include);
  }

  public static numToThreeDigits(num: any) {
    if (!num) {
      return '';
    }

    num = num.toString().split('.')[0].toString();

    if (num.length === 1) {
      num = `00${num}`;
    } else if (num.length === 2) {
      num = `0${num}`;
    }

    return num;
  }

  public static chime() {
    // const audio = new Audio();
    // audio.src = '../../assets/audio/tada.mp3';
    // audio.load();
    // audio.play();
  }

  public static plink() {
    // const audio = new Audio();
    // audio.src = '../../assets/audio/plink.mp3';
    // audio.load();
    // audio.play();
  }

  public static shouldSync(updatedAt: string, syncedAt: string) {
    if (!syncedAt) {
      return true;
    }

    if (syncedAt && !updatedAt) {
      return false;
    }

    const updatedAtDate = moment(updatedAt, 'YYYY/MM/DD HH:mm:ss');
    const syncedAtDate = moment(syncedAt, 'YYYY/MM/DD HH:mm:ss');

    return updatedAtDate.isAfter(syncedAtDate);
  }

  public static uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      // tslint:disable-next-line:no-bitwise
      const random = Math.random() * 16 | 0;
      const value = char === 'x' ? random : (random % 4 + 8);
      return value.toString(16);
    });
  }
}
