import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTypeBaseFieldComponent } from '../input-type-base-field/input-type-base-field.component';
import { FormHelpers } from '../../classes/form-helpers.class';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BusService } from '../../../shared/services/bus.service';

@Component({
  selector: 'app-input-type-string',
  templateUrl: './input-type-string.component.html',
  styleUrls: ['./input-type-string.component.css']
})
export class InputTypeStringComponent extends InputTypeBaseFieldComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  constructor(private busService: BusService) {
    super();
  }

  ngOnInit() {
    if (this.question.number === 'C.4.10.1') {
      this.subscriptions.add(
        this.busService.listen('no_mask').subscribe(event => {
          if (event.data) {
            this.question.answer.value = 'N/A';
          }
        })
      );
    }

    this.id = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'id');
    this.name = FormHelpers.createQuestionFieldAttributeValue(this.question, 'value', 'name');

    if (!this.question.answer.created_at) {
      this.question.answer.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
