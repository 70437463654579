<form action="">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Copy Area
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div [formGroup]="formGroup" class="modal-body">
    <div class="form-group">
      <label for="source">
        Areas source
      </label>

      <select
          (change)="checkSource()"
          [(ngModel)]="source"
          [ngModelOptions]="{ standalone: true }"
          class="form-control"
          name="source"
          id="source">
        <option value="current">Current Floor</option>
        <option value="all">All Survey Buildings/Floors</option>
      </select>
    </div>

    <ng-container>
      <app-loading *ngIf="findingSurveyById"></app-loading>

      <ng-container *ngIf="survey">
        <div class="form-group">
          <label for="building">
            Building
          </label>

          <select
              (change)="setActiveBuilding()"
              [(ngModel)]="activeBuildingId"
              [ngModelOptions]="{ standalone: true }"
              class="form-control"
              name="building"
              id="building">
            <option *ngFor="let building of survey.buildings.data" [value]="building.id">{{ building.name }}</option>
          </select>
        </div>

        <div
            *ngIf="activeBuilding"
            class="form-group">
          <label for="floor">
            Floor
          </label>

          <select
              (change)="setActiveFloor()"
              [(ngModel)]="activeFloorId"
              [ngModelOptions]="{ standalone: true }"
              class="form-control"
              name="floor"
              id="floor">
            <option *ngFor="let floor of activeBuilding.floors.data" [value]="floor.id">
              {{ floorTypeEnum[floor.type] }} {{ floor.number }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="activeFloor">
        <ng-container *ngTemplateOutlet="areas; context:{ $implicit: activeFloor.areas.data }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="source === 'current'">
      <ng-container *ngTemplateOutlet="areas; context:{ $implicit: floor.areas.data }"></ng-container>
    </ng-container>

    <div
      *ngIf="stateService.surveyType === surveyTypeEnum['Re-inspection']"
      class="form-group">
      <label for="number">
        Area number
      </label>

      <input
        [formControl]="formGroup.controls['number']"
        [ngClass]="{'is-invalid': numberValidation && formGroup.controls['number'].touched}"
        class="form-control"
        id="number"
        name="number"
        type="text">

      <div class="help-block text-danger"
           *ngIf="formGroup.controls['number'].touched && numberValidation">
        * This area number is already in use.
      </div>
    </div>

    <div class="form-group">
      <label for="description">
        Description
      </label>

      <input
          [formControl]="formGroup.controls['description']"
          [ngClass]="{'is-invalid': !formGroup.controls['description'].valid && formGroup.controls['description'].touched}"
          class="form-control"
          id="description"
          name="description"
          type="text">
      <div class="help-block text-danger"
           *ngIf="formGroup.controls['description'].touched && formGroup.controls['description'].hasError('required')">
        * This field is required.
      </div>
    </div>

    <div class="form-group">
      <label>
        Accessible
      </label>

      <div class="btn-group btn-block btn-group-lg flex-wrap" role="group">
        <button
            *ngFor="let button of areaAccessButtons"
            (click)="formGroup.get('accessible').setValue(button.value)"
            [ngClass]="{'active btn-secondary': +formGroup.get('accessible').value === button.value && formGroup.get('accessible').value !== null}"
            type="button"
            class="btn btn-outline-secondary ws-normal">
          {{ button.label | replaceUnderscoreWithSpace }}
        </button>
      </div>
    </div>

    <div class="form-group">
      <label for="deviation_to_scope">
        Deviation to scope
      </label>

      <select
          formControlName="deviation_to_scope"
          name="deviation_to_scope"
          id="deviation_to_scope"
          class="form-control">
        <option [value]="yesNoEnum.Yes">Yes</option>
        <option [value]="yesNoEnum.No">No</option>
      </select>
    </div>

    <div class="form-group">
      <label for="no_access_reason">
        Reason for no access
      </label>

      <input
          [formControl]="formGroup.controls['no_access_reason']"
          class="form-control"
          id="no_access_reason"
          name="no_access_reason"
          type="text">
    </div>

    <div class="form-group">
      <label for="image">
        Photo
      </label>

      <div *ngIf="formGroup.get('image').value" class="row">
        <div class="col-6">
          <div class="card">
            <img
                class="card-img-top"
                [src]="formGroup.get('image').value"
                alt="Survey photo">
            <div class="card-body">
              <button
                  (click)="formGroup.get('image').setValue(null)"
                  class="btn btn-sm btn-danger d-block">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <input
          id="image"
          name="image"
          class="inputfile"
          type="file"
          (change)="onFileChange($event)"
          accept=".jpg, .jpeg, .png">

      <label for="image" class="btn btn-info btn-block w-80">
        Choose Photo
      </label>
    </div>
  </div>

  <div class="modal-footer">
    <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!formGroup.valid || postingArea || numberValidation"
        (click)="onSubmit()">
      <span *ngIf="!postingArea">
        Create
      </span>
      <span *ngIf="postingArea">
        <i class="fa fa-spin fa-spinner mr-1"></i>
        Creating
      </span>
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>

<ng-template #areas let-areas>
  <div
      *ngIf="areas.length === 0"
      class="alert alert-info">
    <i class="fa fa-info mr-1"></i>
    No areas on current floor.
  </div>

  <div
      *ngIf="areas.length > 0"
      class="table-responsive">
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Number</th>
          <th>Description</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let area of areas">
          <td>{{ floorCode }}.{{ area.number }}</td>
          <td>{{ area.description }}</td>
          <td class="text-right">
            <button
                (click)="onCopy(area)"
                class="btn btn-sm btn-primary">
              Copy
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
