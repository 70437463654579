import { Form } from '../models/form.model';
import { FormConfig } from './form-config.class';
import { FormHelpers } from './form-helpers.class';
import { Sample } from '../models/sample.model';
import { FormAnswer } from '../models/form-answer.model';
import * as moment from 'moment';

/**
 * Forms module Sample calculator.
 */
export class SampleCalculator {
  public values: Object = {};
  public form: Form;
  public sample: Sample;

  constructor(form: Form, sample: Sample = null) {
    this.form = form;
    this.sample = sample;
    this.setSampleValues();
    this.setSectionValues();
  }

  /**
   * Initialize Sample Values.
   */
  private setSampleValues() {
    const samplesNumbers = FormConfig.certificates[this.form.form_template_slug]['samples'];

    for (const key in samplesNumbers) {
      if (samplesNumbers.hasOwnProperty(key)) {
        const answer: FormAnswer = FormHelpers.findSampleFormAnswerByQuestionNumber(this.sample, samplesNumbers[key]);
        this.values[key] = answer ? answer.value : 0;
      }
    }
  }

  /**
   * Initialize Form Values.
   */
  private setSectionValues() {
    const sectionsNumbers = FormConfig.certificates[this.form.form_template_slug]['sections'];

    for (const key in sectionsNumbers) {
      if (sectionsNumbers.hasOwnProperty(key)) {
        const answer: FormAnswer = FormHelpers.findSectionFormAnswerByQuestionNumber(this.form, sectionsNumbers[key]);
        this.values[key] = answer ? answer.value : 0;
      }
    }
  }

  /**
   * Actual Flow Rate Calculation.
   */
  public actualFlowRate() {
    const start_flow_rate = +this.values['start_flow_rate'];
    const finish_flow_rate = +this.values['finish_flow_rate'];

    return (+start_flow_rate + +finish_flow_rate) * 0.50;
  }

  /**
   * Result Calculation.
   */
  public result() {
    return this.reportedResult().toFixed(3);
  }

  /**
   * Litreage Calculation.
   */
  public litreage() {
    return this.volume().toFixed(2);
  }

  /**
   * Volume Calculation.
   */
  public volume() {
    const total_time = this.sampleTotalTime();
    const actual_flow_rate = this.actualFlowRate();

    return total_time * actual_flow_rate;
  }

  /**
   * Sample total time Calculation.
   */
  private sampleTotalTime() {
    const start_time = this.values['start_time'];
    const finish_time = this.values['finish_time'];

    return moment(finish_time, 'HH:mm').diff(moment(start_time, 'HH:mm'), 'minutes');
  }

  /**
   * Sample calculated result Calculation.
   */
  public sampleCalculatedResult() {
    if (this.values['occluded'] !== 'No') {
      return 0;
    }

    const volume = this.volume();
    const exposed_filter = +this.values['exposed_filter'];
    const fibers_counted = +this.values['fibers_counted'];
    const graticule_diameter = +this.values['graticule_diameter'];
    const fields = +this.values['fields'];

    const alpha = Math.pow(exposed_filter, 2);
    const beta = 1000 * fibers_counted * alpha;
    const delta = fields * volume * Math.pow(graticule_diameter, 2);

    return delta === 0 ? 0 : beta / delta;
  }

  /**
   * Sample reported result Calculation.
   */
  private reportedResult() {
    if (this.values['occluded'] !== 'No') {
      return 0;
    }

    const calculated_result = this.sampleCalculatedResult();
    const limit_of_detection = this.sampleLimitOfDetection();

    return Math.max(calculated_result, limit_of_detection);
  }

  /**
   * Sample limit of detection Calculation.
   */
  private sampleLimitOfDetection() {
    if (this.values['occluded'] !== 'No') {
      return 0;
    }

    const volume = this.volume();
    const fields = +this.values['fields'];
    const volumeFields = volume * fields;

    return volumeFields === 0 ? 0 : 96000 * 0.01 / volumeFields;
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell M11.
   */
  public sampleTWPAlpha() {
    const fibers_counted = +this.values['fibers_counted'];
    const exposed_filter = +this.values['exposed_filter'];

    return 1000 * fibers_counted * Math.pow(exposed_filter, 2);
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell N11.
   */
  public sampleTWPBeta() {
    const volume = this.volume();
    const fields = +this.values['fields'];
    const graticule_diameter = +this.values['graticule_diameter'];

    return volume * fields * Math.pow(graticule_diameter, 2);
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell P11.
   */
  public sampleTWPGama() {
    const alpha = this.sampleTWPAlpha();
    const beta = this.sampleTWPBeta();
    const actual_flow_rate = this.actualFlowRate();

    return actual_flow_rate === 0 || beta === 0 ? 0 : alpha / beta;
  }

  /**
   * According to "time weighted personal calculator.xlsx" file.
   * Cell Q11.
   */
  public sampleTWPDelta() {
    const gama = this.sampleTWPGama();
    const total_time = this.sampleTotalTime();

    return gama * total_time / 60;
  }

  /**
   * According to new calculation based on reported results
   */
  public newSampleTWPDelta() {
    const reportedResult = Math.ceil(this.reportedResult() * 100) / 100;
    const totalTime = this.sampleTotalTime();
    const totalTimeInHours = totalTime / 60;

    return Number(reportedResult.toFixed(2)) * Number(totalTimeInHours.toFixed(2));
  }
}
